<template>
  <el-dialog
    center
    top="8vh"
    :show-close="false"
    style="border-radius: 12px"
    :close-on-click-modal="false"
    :model-value="isDialogOpenForPrivateKey"
    @open="resetTemp"
  >
    <template #header>
      <div class="key-bg">
        <img src="@/assets/img/key_bg.png" />
        <img
          class="close-icon cursor-pointer"
          src="@/assets/img/bg-close.png"
          @click="
            () => {
              $emit('click')
            }
          "
        />
      </div>
    </template>
    <div class="content sm:mx-9">
      <p class="sm:text-center text-left text-base mb-5 font-bold">Reveal Private Key</p>
      <div class="warning-tips sm:flex sm:justify-start	sm:items-start	">
        <div class="mobile:flex mobile:justify-center">
          <img
            class="w-6"
            src="@/assets/img/warning-flag.png"
          />
        </div>
        <div class="mobile:pt-4">
          <p>Reminder: Do NOT share your Private Key with others.</p>
          <p>These words can be used to steal all your accounts.</p>
        </div>
      </div>
      <!-- Your Private Key -->
      <h4>{{ step == 1 ? 'Enter your password to continue' : 'Your Private Key' }}</h4>
      <!-- enter your password to continue -->
      <el-form
        v-if="step == 1"
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        hide-required-asterisk
        @submit.prevent
      >
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            class="h-10"
            data-cy="key_password"
            type="password"
            autocomplete="new-password"
            @keyup.enter="tonext"
          />
        </el-form-item>
        <p class="text-red">{{ errorMsg }}</p>
      </el-form>
      <div
        v-else
        class="private-key"
      >
        <div
          class="desc"
          data-cy="private_key"
        >
          {{ privateKey }}
        </div>
        <div class="flex justify-between items-center">
          <div
            id="copybtn"
            class="btn"
            :data-clipboard-text="privateKey"
            @click="copy"
          >
            <img
              src="@/assets/img/icon-copy.svg"
              alt=""
            />
            <span>Copy to clipboard</span>
          </div>
          <div
            class="btn"
            @click="saveAsCSV"
          >
            <img
              src="@/assets/img/icon-save.svg"
              alt=""
            />
            <span>Save as CSV file</span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8 flex mobile:flex-col-reverse  justify-center items-center sm:space-x-5">
      <el-button
        class="mobile:mt-5"
        data-cy="cancel_popUp"
        @click="
          () => {
            $emit('click')
          }
        "
      >
        Cancel
      </el-button>
      <el-button
        v-if="step == 1"
        type="primary"
        :loading="loading"
        data-cy="reveal_btn"
        style="background: linear-gradient(90deg, #11ad20 0%, #32dd43 100%)"
        @click="tonext"
      >
        Reveal
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Clipboard from 'clipboard'
import { exportDownload } from '@/utils'
import { moduleTypes } from '@/store/modules/type'
import UserService from '@/service/UserService'
import { LoginActionTypes } from '@/store/modules/common/login/type'
const userService = new UserService()

export default {
  name: 'RevealPrivateKey',
  emits: ['click'],
  data() {
    return {
      step: 1,
      loading: false,
      errorMsg: '',
      ruleForm: {
        password: ''
      },
      rules: {
        password: [{ required: true, message: 'Password is required', trigger: 'blur' }]
      },
      privateKey: ''
    }
  },
  computed: {
    ...mapState(moduleTypes.APP, ['currentUser']),
    ...mapState(moduleTypes.DASHBOARD, ['isDialogOpenForPrivateKey'])
  },
  methods: {
    ...mapActions(moduleTypes.LOGIN, [LoginActionTypes.signIn]),
    tonext() {
      this.$refs['ruleForm'].validate((valid) => {
        this.errorMsg = ''
        if (valid) {
          this.loading = true
          this.signIn({ email: this.currentUser.email, password: this.ruleForm.password }).then(async() => {
            const pk = await userService.getPrivateKey()
            this.step = 2
            this.privateKey = pk.privateKey
          }).catch((err) => {
            console.log(err)
            this.errorMsg = err.message
          })
        } else {
          console.log('error submit!!')
          return false
        }
        this.loading = false
      })
    },
    copy() {
      const clipboard = new Clipboard('#copybtn')
      clipboard.on('success', (e) => {
        this.$message.success('Copied!')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message('Copy Failed!')
        // 释放内存
        clipboard.destroy()
      })
    },
    saveAsCSV() {
      exportDownload(this.privateKey, 'privateKey.csv')
      this.$message.success('Saved!')
    },
    resetTemp() {
      this.step = 1
      this.ruleForm = {
        password: ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.key-bg {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  position: relative;
  .close-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 20px;
    font-style: normal;
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: transparent;
    line-height: 26px;
    color: transparent;
  }
  img {
    width: 100%;
  }
}
.content {
  color: #000;
  h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }
  .warning-tips {
    background: #ffe8ed;
    border: 1px solid #fc1642;
    border-radius: 5px;
    padding: 16px 20px;
    margin-bottom: 20px;
    img {
      margin-right: 10px;
      margin-top: -1px;
    }
    p {
      line-height: 25px;
      color: #fc1642;
      font-weight: 600;
    }
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .private-key {
    .desc {
      padding: 12px 22px;
      border: 1px solid #979797;
      border-radius: 3px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 14px;
      color: #000;
    }
    .btn {
      width: 48%;
      height: 44px;
      background: #ffffff;
      border: 1px solid rgba(130, 130, 130, 0.8);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #3aa845;
      span {
        padding-left: 14px;
      }
      &:hover {
        background-color: rgba(58, 168, 69, 0.5);
        color: #fff;
        border-color: rgba(58, 168, 69, 0.5);
      }
    }
  }
}

/deep/ .el-dialog__footer {
  padding-bottom: 40px;
}
.el-button {
  width: 170px;
  height: 40px;
}
@media (max-width: 576px) {
  .el-button {
  width: 80%;

}
}

</style>

<style>
.el-dialog__header {
  padding: 0;
  margin-right: 0;
}
.el-dialog{
  width: 622px;
}
.el-button + .el-button{
  margin-left: 0;
}

@media (max-width: 576px) {
  .el-dialog{
  width: 90%;
 }
}
</style>
