const DashboardMutationTypes = {
  SET_NFTS: 'SET_NFTS',
  IS_SHOW_PROFILE_DRAWER: 'IS_SHOW_PROFILE_DRAWER',
  IS_SHOW_HEADER_LEFT_ARROW: 'IS_SHOW_HEADER_LEFT_ARROW',
  UPDATE_IS_NFTS_GET_LOADING: 'UPDATE_IS_NFTS_GET_LOADING',
  IS_DIALOG_OPEN_FOR_PRIVATE_KEY: 'IS_DIALOG_OPEN_FOR_PRIVATE_KEY'
}

const DashboardActionTypes = {
  getOwnerNFTs: 'getOwnerNFTs'
}

module.exports = {
  DashboardActionTypes,
  DashboardMutationTypes
}
