const SignUpMutationTypes = {
  UPDATE_FORM: 'UPDATE_FORM',
  SIGNUP_RESET_FORM: 'SIGNUP_RESET_FORM',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_IS_PASSWORD_VISIBLE: 'SET_IS_PASSWORD_VISIBLE',
  SET_IS_CONFIRM_PASSWORD_VISIBLE: 'SET_IS_CONFIRM_PASSWORD_VISIBLE',
  UPDATE_USER_EXISTS: 'UPDATE_USER_EXISTS',
  SET_IS_SIGNUP_LOADING: 'SET_IS_SIGNUP_LOADING',
  SET_CONFIRM_PASSWORD_ERROR_MESSAGE: 'SET_CONFIRM_PASSWORD_ERROR_MESSAGE'
}

const SignUpActionTypes = {
  signIn: 'signIn',
  signUp: 'signUp',
  userExist: 'userExist'
}

module.exports = {
  SignUpActionTypes,
  SignUpMutationTypes
}
