import { fetchNFTs } from '@/utils/fetchNFTs'
import { moduleTypes } from '@/store/modules/type'
import { DashboardActionTypes, DashboardMutationTypes } from './type'
import CollectionService from '@/service/CollectionService'
const collectionService = new CollectionService()
const state = {
  nfts: [],
  isNFTsGetLoading: false,
  isShowProfileDrawer: false,
  isShowHeaderLeftArrow: false,
  isDialogOpenForPrivateKey: false
}

const mutations = {
  [DashboardMutationTypes.SET_NFTS]: (state, nfts) => {
    state.nfts = nfts
  },
  [DashboardMutationTypes.UPDATE_IS_NFTS_GET_LOADING]: (state, isNFTsGetLoading) => {
    state.isNFTsGetLoading = isNFTsGetLoading
  },
  [DashboardMutationTypes.IS_DIALOG_OPEN_FOR_PRIVATE_KEY]: (state, visible) => {
    state.isDialogOpenForPrivateKey = visible
  },
  [DashboardMutationTypes.IS_SHOW_HEADER_LEFT_ARROW]: (state, visible) => {
    state.isShowHeaderLeftArrow = visible
  },
  [DashboardMutationTypes.IS_SHOW_PROFILE_DRAWER]: (state, visible) => {
    state.isShowProfileDrawer = visible
  }
}

const actions = {
  [DashboardActionTypes.getOwnerNFTs]: async ({ dispatch, commit, rootState }) => {
    commit(DashboardMutationTypes.UPDATE_IS_NFTS_GET_LOADING, true)

    // TODO: requires to optimise this getownernfts
    const nfts = await fetchNFTs(rootState[moduleTypes.APP].currentUser.walletAddress)

    const response = await collectionService.getCollection(1, 1000, true)

    let hasError = false

    if (nfts) {
      nfts.forEach(nft => {
        if (nft.error) { hasError = true } else {
          if (nft.metadata.attributes) {
            nft.metadata.attributes.forEach(attribute => {
              if (attribute.trait_type && attribute.trait_type.toLowerCase() === 'source') {
                nft.source = attribute.value
              }
            })
          }
        }
        const collection = response.collections.find(c => c.smartContractAddress.toLowerCase() === nft.contractAddress.toLowerCase())
        nft.collection = collection
      })
    }

    if (hasError) {
      setTimeout(() => {
        if (!rootState[moduleTypes.DASHBOARD].isNFTsGetLoading) {
          console.log('CALL AGAIN')
          dispatch(DashboardActionTypes.getOwnerNFTs)
        }
      }, 5 * 1000) // 5 seconds
    }
    commit(DashboardMutationTypes.SET_NFTS, nfts)
    commit(DashboardMutationTypes.UPDATE_IS_NFTS_GET_LOADING, false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
