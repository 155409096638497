import { ethers } from 'ethers'

export const getFastGas = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  let gas
  if ((await provider.getNetwork()).chainId == 137) {
    const res = await fetch('https://gasstation.polygon.technology/v2')
    gas = (await res.json()).fast
  } else if ((await provider.getNetwork()).chainId == 80001) {
    const res = await fetch('https://gasstation-testnet.polygon.technology/v2')
    gas = (await res.json()).fast
  }
  const maxFeePerGas = Math.ceil(gas.maxFee * 1e9)
  const maxPriorityFeePerGas = Math.ceil(gas.maxPriorityFee * 1e9)
  return { maxFeePerGas, maxPriorityFeePerGas }
}
