const moduleTypes = {
  APP: 'app',
  CORE: 'core',
  LOGIN: 'login',
  SIGNUP: 'signup',
  FORGET_PASSWORD: 'forgetPassword',
  VERIFY_USER: 'verifyUser',

  DASHBOARD: 'dashboard',

  WHITELISTED: 'admin/whitelisted',
  USERS: 'admin/users',
  COLLECTION: 'admin/collection',
  APIKEY: 'admin/apikey',
  COMPANY: 'admin/company'
}

const actionTypes = {
  logout: 'logout',
  getCurrentAuthenticatedUser: 'getCurrentAuthenticatedUser'
}

module.exports = {
  moduleTypes,
  actionTypes
}
