<template>
  <div>
    <label
      class="text-sm"
      :for="label"
    >{{ label }}</label>
    <input
      id="myText"
      :type="type"
      :name="name"
      :value="value"
      autocomplete="off"
      :placeholder="placeholder"
      :disabled="disabled"
      class="
        ml-3
        pl-3
        h-10
        w-52
        rounded
        text-sm
        border
        border-solid
        focus:outline-none
        focus:ring-primary
        focus:border-primary"
      @input="
        (event) => {
          $emit('input', event);
        }
      "
      @focus="
        () => {
          $emit('focus');
        }
      "
      @blur="
        () => {
          $emit('blur');
        }
      "
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ElementInput',
  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true,
      enum: ['text', 'email', 'password', 'number', 'date']
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'focus', 'blur']
})
</script>
