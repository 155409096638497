const CollectionMutationTypes = {
  UPDATE_FORM: 'UPDATE_FORM',
  UPDATE_FORM_NFTS_TITLE: 'UPDATE_FORM_NFTS_TITLE',
  UPDATE_FORM_BENEFIT: 'UPDATE_FORM_BENEFIT',
  UPDATE_FORM_ATTRIBUTE: 'UPDATE_FORM_ATTRIBUTE',
  DELETE_FORM_DYNAMIC_ITEM: 'DELETE_FORM_DYNAMIC_ITEM',
  ADD_FORM_DYNAMIC_ITEM: 'ADD_FORM_DYNAMIC_ITEM',
  UPDATE_DEPLOY_COLLECTION_PROCESS: 'UPDATE_DEPLOY_COLLECTION_PROCESS',
  RESET_FORM: 'RESET_FORM',
  SET_COLLECTIONS: 'SET_COLLECTIONS',

  UPDATE_PAGE_FOR_COLLECTION: 'UPDATE_PAGE_FOR_COLLECTION',
  CLEAR_COLLECTION: 'CLEAR_COLLECTION',
  UPDATE_TOTAL_COLLECTON_DATA: 'UPDATE_TOTAL_COLLECTON_DATA',
  IS_LOADING_FOR_GET_COLLECTION: 'IS_LOADING_FOR_GET_COLLECTION'
}

const CollectionActionTypes = {
  generateSignedUrlForUpload: 'generateSignedUrlForUpload',
  uploadImagesToS3: 'uploadImagesToS3',
  uploadImagesToPinata: 'uploadImagesToPinata',
  uploadMetadataToPinata: 'uploadMetadataToPinata',
  deploySmartContract: 'deploySmartContract',
  createCollectionInDB: 'createCollectionInDB',
  deployCollection: 'deployCollection',
  getCollections: 'getCollections',
  searchCollectionInWhitelisted: 'searchCollectionInWhitelisted',
  openMetaMaskForContractDeployment: 'openMetaMaskForContractDeployment'
}

module.exports = {
  CollectionMutationTypes,
  CollectionActionTypes
}
