import { API } from 'aws-amplify'
import serviceName from './index'

const route = `${process.env.VUE_APP_NODE_ENV !== 'development' ? '/private' : ''}/api-key`
export default class ApiKeyServices {
  async get(page) {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}?page=${page}&size=20`)
    } catch (e) {
      console.log(e)
    }
  }

  async create(name) {
    try {
      return await API.post(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}`, {
        body: {
          name
        }
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  async regenerate(id) {
    try {
      return await API.put(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/regenerate-api-key`, {
        body: {
          id
        }
      })
    } catch (e) {
      console.log('Error:-', e)
    }
  }

  async delete(id) {
    try {
      return await API.del(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}`, {
        body: {
          id
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
}
