import { Auth } from 'aws-amplify'
import router from '@/router/index'
import AuthService from '@/service/AuthService'
const authService = new AuthService()
import { actionTypes } from './type'

// Update this version whenever you update the structure of the app state
export const CURRENT_VERSION = '1.0.2'

const state = {
  isLoggedIn: false,
  /**
    * @typedef User
    * @type {object}
    * @property {string} email - email of user.
    * @property {string} id - id of user.
    * @property {string} walletAddr - wallet address of user.
    */
  companyId: '',
  currentUser: {},
  isAdmin: false,
  isSuperAdmin: false,
  version: '1.0.0'
}

const mutations = {
  SET_IS_LOGGED_IN (state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn
  },
  SET_CURRENT_USER (state, currentUser) {
    state.currentUser = currentUser
  },
  SET_IS_ADMIN: (state, isAdmin) => {
    state.isAdmin = isAdmin
  },
  SET_IS_SUPER_ADMIN: (state, isSuperAdmin) => {
    state.isSuperAdmin = isSuperAdmin
  },
  SET_VERSION: (state, version) => {
    state.version = version
  },
  SET_COMPANY_ID: (state, id) => {
    state.companyId = id
  },
  LOGOUT (state) {
    state.companyId = ''
    state.isAdmin = false
    state.isSuperAdmin = false
    state.currentUser = {}
    state.isLoggedIn = false
  }
}

const actions = {
  async getCurrentAuthenticatedUser () {
    const data = await Auth.currentAuthenticatedUser()
    return data
  },
  async getJWTToken () {
    return (await Auth.currentSession()).getIdToken().getJwtToken()
  },
  async getCurrentUser ({ commit, dispatch, state }) {
    try {
      const data = await dispatch(actionTypes.getCurrentAuthenticatedUser)
      console.log({ data })
      if (data && data.signInUserSession) {
        commit('SET_IS_LOGGED_IN', true)

        // get user information

        const currentUser = await authService.getCurrentUser()
        commit('SET_CURRENT_USER', currentUser.user)

        const companiesOfAdmin = currentUser.companies.filter(company => company.role == 'admin')
        if (companiesOfAdmin.length != 0) {
          commit('SET_IS_ADMIN', true)
          const selectedCompanyExits = companiesOfAdmin.filter((item) => item.company.id == state.companyId)
          if (selectedCompanyExits.length != 0) {
            commit('core/SET_SELECT_COMPANY_NAME', selectedCompanyExits[0].company.name, { root: true })
          } else {
            commit('core/SET_SELECT_COMPANY_NAME', companiesOfAdmin[0].company.name, { root: true })
            commit('SET_COMPANY_ID', companiesOfAdmin[0].company.id)
          }
        } else commit('SET_IS_ADMIN', false)
        commit('core/SET_COMPANY_LIST', companiesOfAdmin, { root: true })

        const isSuperAdmin = currentUser.companies.filter(company => company.role == 'super-admin').length != 0
        if (isSuperAdmin) commit('SET_IS_SUPER_ADMIN', true)
        else commit('SET_IS_SUPER_ADMIN', false)
      }
    } catch (e) {
      if (e.message == 'Error: Request failed with status code 401') dispatch(actionTypes.logout)
    }
  },
  async logout ({ commit }) {
    await Auth.signOut()
    commit('LOGOUT')
    router.push({ path: '/login' })
  }
}

export const app = {
  namespaced: true,
  state,
  mutations,
  actions
}
