import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import { app } from './modules/app'
import { CURRENT_VERSION } from './modules/app'

import { core } from './modules/core'

import dashboard from './modules/common/dashboard'
import login from './modules/common/login'
import signup from './modules/common/signUp'
import forgetPassword from './modules/common/forgetPassword'
import verifyUser from './modules/common/verifyUser'

import whitelisted from './modules/admin/whitelisted'
import users from './modules/admin/users'
import collection from './modules/admin/collection'
import apikey from './modules/admin/apikey'
import company from './modules/admin/company'

import { moduleTypes } from './modules/type'

const vuexLocal = new VuexPersistence({
  key: CURRENT_VERSION,
  storage: window.localStorage,
  reducer: (state) => ({ app: state.app })
})
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    [moduleTypes.APP]: app,
    [moduleTypes.CORE]: core,

    // common - for both admin and user
    [moduleTypes.DASHBOARD]: dashboard,
    [moduleTypes.LOGIN]: login,
    [moduleTypes.SIGNUP]: signup,
    [moduleTypes.FORGET_PASSWORD]: forgetPassword,
    [moduleTypes.VERIFY_USER]: verifyUser,

    // admin
    [moduleTypes.WHITELISTED]: whitelisted,
    [moduleTypes.USERS]: users,
    [moduleTypes.COLLECTION]: collection,
    [moduleTypes.APIKEY]: apikey,
    [moduleTypes.COMPANY]: company
  },
  plugins: [vuexLocal.plugin]
})
