import { MINT_MODE } from '@/constants'
import { payGasFeeForTransfer } from '@/utils/MintNFTs'
import { WhiteListedActionTypes, WhiteListedMutationTypes } from './type'
import WhiteListedService from '@/service/WhitelistedService'
const whiteListedService = new WhiteListedService()

const state = {
  /**
    * @typedef WhitelistedForm
    * @type {object}
    * @property {object} collection - selected collection.
    * @property {string} collection.id - id of collection
    * @property {string} collection.smartContractAddr - smart contract address of collection
    * @property {Array} emails - array of string for email
    */
  form: {
    collection: '',
    mintMode: MINT_MODE.USING_EMAIL,
    emails: [],
    walletAddresses: [],
    collectionWhitelistedList: '',
    searchCollectionName: '',
    searchCollectionNameForWhitelistedList: ''
  },
  isFormSubmitLoading: false,
  /**
    * @typedef MintingProcessStatus
    * @type {object}
    * @property {string} MintingProcessStatus.type - alert type.
    * @property {string} MintingProcessStatus.message - alert message
    * */
  mintingProcessStatus: {
    type: 'info', // info | error
    message: null
  },
  /**
    * @typedef WalletCreationStatus
    * @type {object}- alert type.
    * @property {Array} WalletCreationStatus.hasGetErrorCreatingWhitelisted - alert message
    * */
  walletCreationStatus: [],

  whitelistedUsers: [],

  // collection id - for filter whitelisted user
  whitelistedUsersFilter: '',

  whitelistedPage: 1,
  whitelistedTotalData: -1,
  isLoadingForGetWhiteListedUsers: false
}

const mutations = {
  [WhiteListedMutationTypes.SET_WHITELISTED]: (state, whitelisted) => {
    state.whitelistedUsers.push(...whitelisted)
  },

  [WhiteListedMutationTypes.UPDATE_FORM]: (state, { key, value }) => {
    state.form[key] = value
  },
  [WhiteListedMutationTypes.UPDATE_EMAILS]: (state, emails) => {
    state.form.emails = emails.split(',')
      .map(item => item.trim())
  },
  [WhiteListedMutationTypes.UPDATE_EMAILS_ARRAY]: (state, { index, value }) => {
    state.form.emails[index] = value
  },
  [WhiteListedMutationTypes.UPDATE_WALLET_ADDRESSES]: (state, walletAddresses) => {
    state.form.walletAddresses = walletAddresses.split(',')
      .map(item => item.trim())
  },
  [WhiteListedMutationTypes.UPDATE_WALLET_ADDRESSES_ARRAY]: (state, { index, value }) => {
    state.form.walletAddresses[index] = value
  },
  [WhiteListedMutationTypes.UPDATE_IS_FORM_SUBMIT_LOADING]: (state, isFormSubmitLoading) => {
    state.isFormSubmitLoading = isFormSubmitLoading
  },
  [WhiteListedMutationTypes.UPDATE_MINTING_PROCESS_STATUS]: (state, { type, message }) => {
    state.mintingProcessStatus.type = type
    state.mintingProcessStatus.message = message
  },
  [WhiteListedMutationTypes.UPDATE_WHITELISTED_USERS_FILTER]: (state, whitelistedUsersFilter) => {
    state.whitelistedUsersFilter = whitelistedUsersFilter
  },
  [WhiteListedMutationTypes.RESET_FORM]: (state) => {
    state.form = {
      collection: '',
      emails: [],
      mintMode: MINT_MODE.USING_EMAIL,
      walletAddresses: [],
      searchCollectionName: '',
      searchCollectionNameForWhitelistedList: ''
    }
    state.isFormSubmitLoading = false
    state.mintingProcessStatus = {
      type: 'info', // info | error
      message: null
    }
    state.walletCreationStatus = []
  },
  [WhiteListedMutationTypes.RESET_WALLET_CREATION_STATUS]: (state) => {
    state.walletCreationStatus = []
  },

  [WhiteListedMutationTypes.UPDATE_PAGE_FOR_WHITELISTED]: (state, page) => {
    state.whitelistedPage = page
  },
  [WhiteListedMutationTypes.UPDATE_TOTAL_WHITELISTED_USER_DATA]: (state, totalData) => {
    state.whitelistedTotalData = totalData
  },
  [WhiteListedMutationTypes.CLEAR_WHITELISTED_USERS]: (state) => {
    state.whitelistedPage = 1
    state.whitelistedUsers = []
    state.whitelistedTotalData = -1
  },
  [WhiteListedMutationTypes.IS_LOADING_FOR_GET_WHITELISTED_USERS]: (state, loading) => {
    state.isLoadingForGetWhiteListedUsers = loading
  }
}

const actions = {
  [WhiteListedActionTypes.startMintingProcess]: async ({ state, commit, dispatch }) => {
    commit(WhiteListedMutationTypes.UPDATE_IS_FORM_SUBMIT_LOADING, true)
    let whitelistedUsers

    commit(WhiteListedMutationTypes.UPDATE_MINTING_PROCESS_STATUS, {
      type: 'info',
      message: `${state.form.mintMode === MINT_MODE.USING_EMAIL ? 'Creating wallets' : 'Transferring nft'}`
    })
    commit(WhiteListedMutationTypes.RESET_WALLET_CREATION_STATUS)

    if (state.form.mintMode === MINT_MODE.USING_EMAIL) {
      whitelistedUsers = await dispatch(WhiteListedActionTypes.createWhitelisted)
    } else if (state.form.mintMode === MINT_MODE.USING_WALLET_ADDRESS) {
      whitelistedUsers = await dispatch(WhiteListedActionTypes.createNftTransfer)
    }

    commit(WhiteListedMutationTypes.UPDATE_MINTING_PROCESS_STATUS, {
      type: 'info',
      message: 'Start minting: metamask will open to approve transaction'
    })

    let hasError = false
    for (let index = 0; index < state.walletCreationStatus.length; index++) {
      const status = state.walletCreationStatus[index]
      if (status.hasGetErrorCreatingWhitelisted === true) {
        hasError = true
        break
      }
    }

    if (!hasError) {
      if (state.form.mintMode === MINT_MODE.USING_EMAIL) {
        await dispatch(WhiteListedActionTypes.payGasFeeForTransfer, { whitelistedUsers: whitelistedUsers.walletAddressesOfWhitelistedUsers })
      } else if (state.form.mintMode === MINT_MODE.USING_WALLET_ADDRESS) {
        await dispatch(WhiteListedActionTypes.payGasFeeForTransfer, { whitelistedUsers: whitelistedUsers.whitelistedUsers })
      }
    } else {
      commit(WhiteListedMutationTypes.UPDATE_MINTING_PROCESS_STATUS, {
        type: 'error',
        message: `${state.form.mintMode === MINT_MODE.USING_EMAIL ? 'Failed to creating a whitelisted user. Try again' : 'Failed to transfering nft . Try again'}`
      })
      commit(WhiteListedMutationTypes.UPDATE_IS_FORM_SUBMIT_LOADING, false)
    }
  },
  [WhiteListedActionTypes.createWhitelisted]: async ({ commit, state }) => {
    const walletAddressesOfWhitelistedUsers = []
    let index = 0
    for (const email of state.form.emails) {
      if (state.walletCreationStatus[index] == null) { state.walletCreationStatus[index] = {} }

      commit(WhiteListedMutationTypes.UPDATE_MINTING_PROCESS_STATUS, {
        type: 'info',
        message: `Adding ${email} to whitelist`
      })

      if (JSON.stringify(state.walletCreationStatus[index]) === '{}' || state.walletCreationStatus[index].hasGetErrorCreatingWhitelisted === true) {
        try {
          const user = await whiteListedService.createWhitelistedUser({ email, collectionId: state.form.collection.id })
          state.walletCreationStatus[index].hasGetErrorCreatingWhitelisted = false
          walletAddressesOfWhitelistedUsers.push(user)
        } catch (error) {
          state.walletCreationStatus[index].hasGetErrorCreatingWhitelisted = true
          console.log(error)
        }
      }
      index++
    }
    return { walletAddressesOfWhitelistedUsers }
  },
  [WhiteListedActionTypes.createNftTransfer]: async ({ commit, state }) => {
    const whitelistedUsers = []

    let index = 0
    for (const walletAddress of state.form.walletAddresses) {
      if (state.walletCreationStatus[index] == null) { state.walletCreationStatus[index] = {} }

      commit(WhiteListedMutationTypes.UPDATE_MINTING_PROCESS_STATUS, {
        type: 'info',
        message: `transferring nft to: ${walletAddress}`
      })

      if (JSON.stringify(state.walletCreationStatus[index]) === '{}' || state.walletCreationStatus[index].hasGetErrorCreatingWhitelisted === true) {
        try {
          const user = await whiteListedService.createNftTransfer({ walletAddress, collectionId: state.form.collection.id })
          state.walletCreationStatus[index].hasGetErrorCreatingWhitelisted = false
          whitelistedUsers.push(user)
        } catch (error) {
          state.walletCreationStatus[index].hasGetErrorCreatingWhitelisted = true
          console.log(error)
        }
      }
      index++
    }
    return { whitelistedUsers }
  },
  [WhiteListedActionTypes.getWhitelisted]: async ({ commit, state }) => {
    commit(WhiteListedMutationTypes.IS_LOADING_FOR_GET_WHITELISTED_USERS, true)
    try {
      const whitelisted = await whiteListedService.getWhitelisted(state.whitelistedPage)
      commit(WhiteListedMutationTypes.SET_WHITELISTED, whitelisted.whitelistedUsers)
      commit(WhiteListedMutationTypes.UPDATE_TOTAL_WHITELISTED_USER_DATA, whitelisted.totalData)
    } catch (error) {
      console.log('Error:-', error)
    }
    commit(WhiteListedMutationTypes.IS_LOADING_FOR_GET_WHITELISTED_USERS, false)
  },
  [WhiteListedActionTypes.getWhitelistedUsersFilter]: async ({ commit, state }) => {
    commit(WhiteListedMutationTypes.IS_LOADING_FOR_GET_WHITELISTED_USERS, true)
    try {
      const whitelisted = await whiteListedService.getWhitelistedUsersFilter(state.whitelistedUsersFilter, state.whitelistedPage)
      commit(WhiteListedMutationTypes.SET_WHITELISTED, whitelisted.whitelistedUsers)
      commit(WhiteListedMutationTypes.UPDATE_TOTAL_WHITELISTED_USER_DATA, whitelisted.totalData)
    } catch (error) {
      console.log('Error:-', error)
    }
    commit(WhiteListedMutationTypes.IS_LOADING_FOR_GET_WHITELISTED_USERS, false)
  },
  [WhiteListedActionTypes.payGasFeeForTransfer]: async ({ commit, dispatch }, { whitelistedUsers }) => {
    const nftId = []
    const walletAddresses = []
    for (let index = 0; index < whitelistedUsers.length; index++) {
      const walletAdd = whitelistedUsers[index].walletAddress
      walletAddresses.push(walletAdd)
    }
    for (let index = 0; index < whitelistedUsers.length; index++) {
      const id = whitelistedUsers[index].nft.id
      nftId.push(id)
    }

    try {
      const txHash = await dispatch(WhiteListedActionTypes.payGasFeeForTransaction, walletAddresses)
      console.log(txHash)
      // TODO: add api call for nft transfer confirmation
      await whiteListedService.updateNftTransfer({ txHash: txHash, ids: nftId })

      commit(WhiteListedMutationTypes.RESET_FORM)
      commit(WhiteListedMutationTypes.CLEAR_WHITELISTED_USERS)
      dispatch(WhiteListedActionTypes.getWhitelisted)
    } catch (e) {
      console.log('Payment-Failed :-', e)
      await whiteListedService.deleteNftTransfer({ ids: nftId })
      commit(WhiteListedMutationTypes.UPDATE_MINTING_PROCESS_STATUS, {
        type: 'error',
        message: 'Failed to mint. Try again'
      })
      commit(WhiteListedMutationTypes.UPDATE_IS_FORM_SUBMIT_LOADING, false)
    }
  },
  [WhiteListedActionTypes.payGasFeeForTransaction]: async ({ state }, walletAddresses) => {
    const txHash = await payGasFeeForTransfer({
      walletAddresses: walletAddresses,
      smartContractAddress: state.form.collection.smartContractAddress,
      window
    })
    return txHash.transactionHash
  }
}

const getters = {
  emailsToText: (state) => {
    return state.form.emails.join(',')
  },
  walletAddressesToText: (state) => {
    return state.form.walletAddresses.join(',')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
