<template>
  <div>
    <header-nav class="sm:hidden" />
    <HomePageBackgroundImage />
    <div class="sm:absolute mobile:mt-14 sm:top-8 sm:inset-x-20">
      <HomePageBackground />
      <div
        class="
          py-9
          px-14
          w-full
          absolute
          sm:top-72
          login-wrap
          sm:w-[520px]
          rounded-3xl
          opacity-100
          mobile:h-[700px]
          backdrop-blur-xs
          shadow-[5px 15px 30px rgb(0 0 0 /30%)]
        "
      >
        <h3 class="font-nova tracking-tight text-white font-semibold text-2xl mobile:text-xl mobile:text-black mobile:animate-bounce-name mobile:text-primary">Reset Your Password</h3>
        <p class="pt-2 h-20 text-sm tracking-tight font-nova leading-5 text-primaryTextColor mobile:text-black">Thanks for joining in the event! Please create your account to redeem your NFT.</p>
        <el-form
          v-if="!showActivationCode"
          ref="ruleForm"
          :rules="rules"
          :model="form"
          label-position="top"
          hide-required-asterisk
        >
          <el-form-item
            prop="email"
          >
            <label
              class="py-2 font-extralight text-xs text-white"
              for="Email"
            >Email</label>
            <el-input
              v-model="form.email"
              type="text"
              size="large"
              autocomplete="off"
            />
          </el-form-item>
          <el-button
            round
            size="large"
            type="primary"
            :loading="isForgetPasswordLoading"
            class="w-full mt-5"
            :disabled="!form.email"
            @click="forgotPassword()"
          >
            <span>Continue</span>
          </el-button>
        </el-form>

        <el-form
          v-else
          ref="ruleForm"
          :rules="rules"
          :model="form"
          label-position="top"
          hide-required-asterisk
        >
          <div
            class="border border-green-400 text-primaryTextColor px-4 py-3 mb-3 rounded relative"
            role="alert"
          >
            <span class="block sm:inline"> The confirmation code has been sent to your email, please enter it to redeem your NFT.</span>
          </div>
          <el-form-item
            prop="confirmationCode"
            :error="errorMessage"
          >
            <label
              class="py-2 font-extralight text-xs text-white"
              for="Confirmation code"
            >Confirmation code</label>
            <el-input
              v-model="form.confirmationCode"
              size="large"
              type="text"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item
            prop="password"
          >
            <label
              class="py-2 font-extralight text-xs text-white"
              for="Password"
            >Password</label>
            <el-input
              v-model="form.password"
              size="large"
              show-password
              autocomplete="off"
              :type="isPasswordVisible ? 'text' : 'password'"
            >
              <img
                :src="require(`@/assets/img/${isPasswordVisible ? 'show' : 'hide'}.svg`)"
                alt=""
                class="icon-eyes pointer"
                @click="IS_SHOW_PASSWORD(!isPasswordVisible)"
              />
            </el-input>
          </el-form-item>
          <el-button
            round
            type="primary"
            size="large"
            :loading="isForgetPasswordLoading"
            class="w-full mt-5"
            @click="updateForgotPassword()"
          >
            <img
              :src="require('@/assets/img/icon-view.svg')"
              alt=""
            />
            <span class="ml-2"> Reset password </span>
          </el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { moduleTypes } from '@/store/modules/type'
import headerNav from '@/components/nav/headerNav'
import { mapActions, mapState, mapMutations } from 'vuex'
import { emailValidator, trimValidator } from '@/utils/validator'
import HomePageBackground from '@/components/HomePageBackground.vue'
import HomePageBackgroundImage from '@/components/HomePageBackgroundImage.vue'
import { ForgetPasswordActionTypes } from '@/store/modules/common/forgetPassword/type'
import { ForgetPasswordMutationTypes } from '@/store/modules/common/forgetPassword/type'
export default {
  components: { HomePageBackground, HomePageBackgroundImage, headerNav },
  data() {
    return {
      rules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          { trigger: 'blur', validator: emailValidator }
        ],
        confirmationCode: [
          { required: true, message: 'Confirmation code is required', trigger: 'blur' },
          { trigger: 'blur', validator: trimValidator }
        ],
        password: [
          { required: true, message: 'Password is required', trigger: 'blur' },
          { validator: trimValidator, trigger: 'blur' },
          { min: 8, max: 15, message: '8 to 15 characters', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState(moduleTypes.FORGET_PASSWORD, ['form', 'errorMessage', 'isPasswordVisible', 'showActivationCode', 'isForgetPasswordLoading'])
  },
  mounted() {
    this.FORGET_PASSWORD_RESET_FORM()
  },
  methods: {
    ...mapMutations(moduleTypes.FORGET_PASSWORD, [ForgetPasswordMutationTypes.FORGET_PASSWORD_RESET_FORM]),
    ...mapActions(moduleTypes.FORGET_PASSWORD, [ForgetPasswordActionTypes.forgotPassword, ForgetPasswordActionTypes.updateForgotPassword])
  }
}
</script>

<style lang="less" scoped>
ul.error-tips {
  padding: 10px 0;

  li {
    i {
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #000;
      line-height: 25px;
      margin-bottom: 3px;
      margin-right: 14px;
    }
  }
}

.el-button--large.is-round {
  padding: 26px 19px;
  background: #01D564 0% 0% no-repeat padding-box;
}
.el-button.is-disabled{
  background: #a8bc9f;
}
</style>
