/**
 * Create instant, which represent error object
 * @param {Object} [error] - axios error object
 */
export class ErrorWrapper extends Error {
  constructor(error) {
    super()
    this.statusCode = error.response?.data.statusCode
      ? error.response?.data.statusCode
      : error.response?.data.code
    this.message = error.response?.data.message
  }
}
