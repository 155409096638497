<template>
  <el-dialog
    center
    top="8vh"
    :show-close="false"
    style="border-radius: 12px"
    :close-on-click-modal="false"
    :model-value="isDialogOpenForCreate"
  >
    <template #header>
      <div class="key-bg">
        <img src="@/assets/img/key_bg.png" />
        <img
          class="close-icon cursor-pointer"
          src="@/assets/img/bg-close.png"
          @click="onCancle"
        />
      </div>
    </template>
    <div class="content sm:mx-9">
      <p class="sm:text-center text-left text-base mb-5 font-bold">Create Private Key</p>

      <h4>Enter your project name</h4>
      <el-form
        ref="ruleFormRef"
        :model="form"
        :rules="rules"
        hide-required-asterisk
        class="w-full"
        @submit.prevent
      >
        <el-form-item prop="name">
          <el-input
            size="large"
            :model-value="form.name"
            data-cy="apikey_name"
            placeholder="Please enter your project name"
            @input="(value) => {
              UPDATE_FORM({
                key: 'name',
                value
              })
            }
            "
          />
        </el-form-item>
      </el-form>
    </div>
    <p class="sm:mx-9 text-[#f56c6c]">{{ isErrorMessage }}</p>
    <template #footer>
      <span class="dialog-footer space-x-4">
        <el-button @click="onCancle">Cancel</el-button>
        <el-button
          data-cy="create_api_key_button"
          @click="onCreateApiKey"
        >Create </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapMutations, mapState } from 'vuex'
import { ApiActionTypes, ApiMutationTypes } from '@/store/modules/admin/apikey/type'

export default {
  name: 'DialogApiKey',

  data() {
    return {
      rules: {
        name: [{ required: true, message: 'Please enter name', trigger: 'blur' }]
      }
    }
  },

  computed: {
    ...mapState(moduleTypes.APIKEY, ['form', 'isDialogOpenForCreate', 'isErrorMessage'])
  },

  methods: {
    ...mapActions(moduleTypes.APIKEY, [ApiActionTypes.createApiKey, ApiActionTypes.getApiKey]),
    ...mapMutations(moduleTypes.APIKEY, [ApiMutationTypes.UPDATE_FORM, ApiMutationTypes.IS_DIALOG_OPEN_FOR_CREATE]),
    onCancle() {
      this.IS_DIALOG_OPEN_FOR_CREATE(false)
    },
    onCreateApiKey() {
      this.$refs['ruleFormRef'].validate(async (valid) => {
        if (valid) {
          this.createApiKey()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.key-bg {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  position: relative;

  .close-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 20px;
    font-style: normal;
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: transparent;
    line-height: 26px;
    color: transparent;
  }

  img {
    width: 100%;
  }
}

.content {
  color: #000;

  h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }

  .warning-tips {
    background: #ffe8ed;
    border: 1px solid #fc1642;
    border-radius: 5px;
    padding: 16px 20px;
    margin-bottom: 20px;

    img {
      margin-right: 10px;
      margin-top: -1px;
    }

    p {
      line-height: 25px;
      color: #fc1642;
      font-weight: 600;
    }
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .private-key {
    .desc {
      padding: 12px 22px;
      border: 1px solid #979797;
      border-radius: 3px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 14px;
      color: #000;
    }

    .btn {
      width: 48%;
      height: 44px;
      background: #ffffff;
      border: 1px solid rgba(130, 130, 130, 0.8);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #3aa845;

      span {
        padding-left: 14px;
      }

      &:hover {
        background-color: rgba(58, 168, 69, 0.5);
        color: #fff;
        border-color: rgba(58, 168, 69, 0.5);
      }
    }
  }
}

/deep/ .el-dialog__footer {
  padding-bottom: 40px;
}

.el-button {
  width: 170px;
  height: 40px;
}

@media (max-width: 576px) {
  .el-button {
    width: 80%;

  }
}
</style>

<style>
.el-dialog__header {
  padding: 0;
  margin-right: 0;
}

.el-dialog {
  width: 622px;
}

.el-button+.el-button {
  margin-left: 0;
}

@media (max-width: 576px) {
  .el-dialog {
    width: 90%;
  }
}
</style>
