<template>
  <div>
    <input
      type="file"
      data-cy="collection_replication_image"
      accept="image/png, image/jpg, image/jpeg"
      @change="
        (event) => {
          UPDATE_FORM({
            key: 'nfts',
            value: event.target.files
          })
        }
      "
    />

    <el-card
      v-if="form.nfts[0]"
      :body-style="{ padding: '0px' }"
      style="width: 150px; margin-top: 10px"
    >
      <el-image
        :src="form.nfts[0].image"
        alt="Nft"
        style="width: 100%; height: 150px"
        fit="cover"
      />
      <el-input
        :model-value="form.nfts[0].title"
        placeholder="Enter a title"
        data-cy="collection_image_name"
        @input="
          (value) => {
            UPDATE_FORM_NFTS_TITLE({
              value,
              index: 0
            })
          }
        "
      />
    </el-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { moduleTypes } from '@/store/modules/type'
import { CollectionMutationTypes } from '@/store/modules/admin/collection/type'
export default {
  name: 'ImageUploadReplicationMode',
  computed: {
    ...mapState(moduleTypes.COLLECTION, ['form'])
  },
  methods: {
    ...mapMutations(moduleTypes.COLLECTION, [
      CollectionMutationTypes.UPDATE_FORM,
      CollectionMutationTypes.UPDATE_FORM_NFTS_TITLE
    ])
  }
}
</script>
