<template>
  <div
    role="menu"
    tabindex="-1"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    class="
      z-10
      h-64
      w-72
      top-11
      bg-white
      absolute
      shadow-lg
      rounded-md
      left-[77px]
      ring-1
      ring-black
      ring-opacity-5
      origin-top-right
      overflow-y-scroll
      focus:outline-none
      "
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      class="px-4 py-2 cursor-pointer hover:bg-bgColor"
      @click="() => { $emit('click', item) }"
    >
      <p
        class="text-sm text-secondaryText"
        :data-cy="'context_item_name' + index"
      >
        {{ !isCompanyList ? item.name : item.company.name }}
      </p>
    </div>
    <p
      v-if="!isLoadingForGetItem && items.length === 0"
      class="pt-5 text-center"
    >
      No data found
    </p>
    <p
      v-if="isLoadingForGetItem"
      class="pt-5 text-center"
    >
      Loading...
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ContextMenu',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isLoadingForGetItem: {
      type: Boolean,
      default: false
    },
    isCompanyList: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click']
})
</script>

  <style>
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ecedee;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background:#909399;
  }
  </style>
