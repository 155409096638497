import router from '@/router'
import { Auth } from 'aws-amplify'
import { ForgetPasswordMutationTypes, ForgetPasswordActionTypes } from './type'

const state = {
  form: {
    email: '',
    password: '',
    confirmationCode: ''
  },
  isPasswordVisible: false,
  isForgetPasswordLoading: false,
  showActivationCode: false,
  errorMessage: ''
}

const mutations = {
  [ForgetPasswordMutationTypes.IS_SHOW_PASSWORD]: (state, isPasswordVisible) => {
    state.isPasswordVisible = isPasswordVisible
  },
  [ForgetPasswordMutationTypes.SET_IS_FORGET_PASSWORD_LOADING]: (state, isForgetPasswordLoading) => {
    state.isForgetPasswordLoading = isForgetPasswordLoading
  },
  [ForgetPasswordMutationTypes.SET_ERROR_MESSAGE]: (state, errorMessage) => {
    state.errorMessage = errorMessage
  },
  [ForgetPasswordMutationTypes.FORGET_PASSWORD_RESET_FORM]: (state) => {
    state.form = {
      email: '',
      password: '',
      confirmationCode: ''
    }
    state.errorMessage = ''
    state.showActivationCode = false
  },
  [ForgetPasswordMutationTypes.IS_SHOW_ACTIVATION_CODE]: (state, showActivationCode) => {
    state.showActivationCode = showActivationCode
  }
}

const actions = {
  [ForgetPasswordActionTypes.forgotPassword]: async ({ commit, state }) => {
    commit(ForgetPasswordMutationTypes.SET_IS_FORGET_PASSWORD_LOADING, true)
    try {
      await Auth.forgotPassword(state.form.email)
      commit(ForgetPasswordMutationTypes.IS_SHOW_ACTIVATION_CODE, true)
    } catch (error) {
      console.log('error:', error)
    }
    commit(ForgetPasswordMutationTypes.SET_IS_FORGET_PASSWORD_LOADING, false)
  },
  [ForgetPasswordActionTypes.updateForgotPassword]: async ({ commit, state }) => {
    const { email, confirmationCode, password } = state.form
    commit(ForgetPasswordMutationTypes.SET_IS_FORGET_PASSWORD_LOADING, true)
    try {
      await Auth.forgotPasswordSubmit(email, confirmationCode, password)
      router.push({ path: '/login' })
    } catch (error) {
      console.log('error:', error)
      commit(ForgetPasswordMutationTypes.SET_ERROR_MESSAGE, 'Wrong confirmation code')
    }
    commit(ForgetPasswordMutationTypes.SET_IS_FORGET_PASSWORD_LOADING, false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
