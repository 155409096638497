import _ from 'lodash'
import { CompanyMutationTypes, CompanyActionTypes } from './type'
import CompanyService from '@/service/CompanyService'
import UserServices from '@/service/UserService'
const companyService = new CompanyService()
const userService = new UserServices()
import { ElMessage } from 'element-plus'

const state = {

  page: 1,
  totalData: -1,
  isErrorMessage: '',

  form: {
    name: '',
    email: ''
  },
  companies: [],
  searchCompanyName: '',
  isDialogOpenForCreate: false,
  isLoadingForGetCompany: false,

  // manage admin
  admins: [],
  adminForm: {
    email: ''
  },
  isLoadingForGetAdmin: false,
  isLoadingForCreateAdmin: false
}

const mutations = {
  [CompanyMutationTypes.UPDATE_TOTAL_DATA]: (state, totalData) => {
    state.totalData = totalData
  },
  [CompanyMutationTypes.UPDATE_PAGE]: (state, page) => {
    state.page = page
  },

  [CompanyMutationTypes.UPDATE_FORM_FOR_COMPANY]: async (state, { key, value }) => {
    state.form[key] = value
  },
  [CompanyMutationTypes.SET_COMPANY]: (state, companies) => {
    state.companies.push(...companies)
  },
  [CompanyMutationTypes.SUBMIT_COMPANY]: (state, companies) => {
    state.companies.push(companies)
  },
  [CompanyMutationTypes.UPDATE_COMPANY]: (state, data) => {
    state.companies.splice(state.editDialogIndex, 1, data)
  },
  [CompanyMutationTypes.EDIT_COMPANY]: (state, { index, item }) => {
    state.editDialogIndex = index
    state.form.id = _.cloneDeep(item.id)
    state.form.name = _.cloneDeep(item.name)
    state.form.email = _.cloneDeep(item.profile.user.email)
  },
  [CompanyMutationTypes.CLEAR_COMPANY]: (state) => {
    state.page = 1
    state.companies = []
    state.totalData = -1
  },
  [CompanyMutationTypes.IS_LOADING_FOR_GET_COMPANY]: (state, loading) => {
    state.isLoadingForGetCompany = loading
  },
  [CompanyMutationTypes.IS_SHOW_ERROR_MESSAGE]: (state, message) => {
    state.isErrorMessage = message
  },

  [CompanyMutationTypes.UPDATE_FORM_FOR_ADMIN]: async (state, { key, value }) => {
    state.adminForm[key] = value
  },
  [CompanyMutationTypes.IS_LOADING_FOR_GET_ADMIN]: (state, loading) => {
    state.isLoadingForGetAdmin = loading
  },
  [CompanyMutationTypes.IS_LOADING_FOR_CREATE_ADMIN]: (state, loading) => {
    state.isLoadingForCreateAdmin = loading
  },
  [CompanyMutationTypes.CLEAR_ADMIN]: (state) => {
    state.page = 1
    state.admins = []
    state.totalData = -1
  },
  [CompanyMutationTypes.SET_ADMIN]: (state, admins) => {
    state.admins.push(...admins)
  },
  [CompanyMutationTypes.IS_DIALOG_OPEN_FOR_CREATE]: (state, isDialogOpenForCreate) => {
    state.isDialogOpenForCreate = isDialogOpenForCreate
  }
}

const actions = {
  [CompanyActionTypes.getCompany]: async ({ commit, state }) => {
    commit(CompanyMutationTypes.IS_LOADING_FOR_GET_COMPANY, true)
    try {
      const response = await companyService.get(state.page)
      commit(CompanyMutationTypes.SET_COMPANY, response.companies)
      commit(CompanyMutationTypes.UPDATE_TOTAL_DATA, response.totalData)
    } catch (e) {
      console.log('Error:-', e)
    }
    commit(CompanyMutationTypes.IS_LOADING_FOR_GET_COMPANY, false)
  },
  [CompanyActionTypes.createCompany]: async ({ commit, state }) => {
    commit(CompanyMutationTypes.IS_LOADING_FOR_GET_COMPANY, true)
    try {
      const res = await companyService.create(state.form.name, state.form.email)
      commit(CompanyMutationTypes.SUBMIT_COMPANY, res.data)
      commit(CompanyMutationTypes.UPDATE_TOTAL_DATA, state.totalData + 1)
      commit(CompanyMutationTypes.IS_DIALOG_OPEN_FOR_CREATE, false)
    } catch (e) {
      console.log('Error:-', e)
      commit(CompanyMutationTypes.IS_SHOW_ERROR_MESSAGE, 'Company already exists.')
    }
    commit(CompanyMutationTypes.IS_LOADING_FOR_GET_COMPANY, false)
  },
  [CompanyActionTypes.createAdmin]: async ({ commit, state }, companyId) => {
    commit(CompanyMutationTypes.IS_LOADING_FOR_CREATE_ADMIN, true)
    try {
      const createdAdmin = await userService.createAdmin({ email: state.adminForm.email, companyId })
      commit(CompanyMutationTypes.SET_ADMIN, [{
        id: createdAdmin.data.id,
        role: createdAdmin.data.role,
        user: {
          id: createdAdmin.data.userId,
          email: state.adminForm.email
        }
      }])
      ElMessage.success('Admin created successfully.')
      commit(CompanyMutationTypes.IS_DIALOG_OPEN_FOR_CREATE, false)
    } catch (e) {
      ElMessage.error(e.message)
    }
    commit(CompanyMutationTypes.IS_LOADING_FOR_CREATE_ADMIN, false)
  },
  [CompanyActionTypes.getAdminsByCompany]: async ({ commit, state }, companyId) => {
    commit(CompanyMutationTypes.IS_LOADING_FOR_GET_ADMIN, true)
    try {
      const response = await userService.getAdminsByCompany(state.page, companyId)
      commit(CompanyMutationTypes.SET_ADMIN, response.admins)
      commit(CompanyMutationTypes.UPDATE_TOTAL_DATA, response.totalData)
    } catch (e) {
      console.log('Error:-', e)
    }
    commit(CompanyMutationTypes.IS_LOADING_FOR_GET_ADMIN, false)
  },
  [CompanyActionTypes.removeAdminFromCompany]: async ({ state }, index) => {
    try {
      await userService.removeAdminFromCompany(state.admins[index].id)
      state.admins.splice(index, 1)
      ElMessage.success('Admin deleted successfully.')
    } catch (e) {
      if (e.statusCode == 400) {
        ElMessage.error(e.message)
      } else console.log('Error:-', e)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
