<template>
  <div
    ref="container"
    class="px-[10%] pt-6"
  >
    <h3
      class="mb-4 font-bold"
      data-cy="user_title"
    >
      Users list
    </h3>

    <el-row>
      <el-col :span="12">
        <el-input
          v-model="search"
          type="text"
          size="large"
          data-cy="search_user"
          placeholder="Search users..."
          @keyup="searchUsers"
        />
      </el-col>
    </el-row>

    <body class="flex items-center justify-center">
      <div class="container">
        <table class="w-full flex flex-row flex-no-wrap md:bg-white rounded-lg overflow-hidden my-5">
          <thead class="text-white border-b-2">
            <tr
              v-for="user in users"
              :key="user.id"
              class="text-black flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0"
            >
              <th class="p-3 text-left">id</th>
              <th class="p-3 text-left">email</th>
              <th class="p-3 text-left">walletAddress</th>
              <th class="p-3 text-left">Action</th>
            </tr>
          </thead>
          <tbody class="flex-1 md:flex-none">
            <tr
              v-for="(user, index) in users"
              :key="user.id"
              class="flex flex-col flex-no wrap md:table-row mb-2 md:mb-0 md:border-b"
            >
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">{{ user.id }}</td>
              <td
                :data-cy="'user_email_' + index"
                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
              >
                {{ user.email }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{{ user.walletAddress }}</td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div class="space-x-4">
                  <el-button
                    size="small"
                    type="primary"
                    :data-cy="`airdrop_nft_button${index}`"
                    @click="() => {
                      SET_SELECTED_USER_FOR_MINT_NFT(user)
                      UPDATE_IS_DIALOG_MINT_NFT_VISIBLE(true)
                    }
                    "
                  >
                    Airdrop NFT
                  </el-button>
                  <el-button
                    size="large"
                    type="primary"
                    link
                    :data-cy="`view_user_nft_list_button${index}`"
                    @click="
                      CLEAR_USERS(),
                      $router.push({ path: `user/nft/list/${user.id}` })
                    "
                  >
                    View
                  </el-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </body>
    <p
      v-if="(usersTotalData !== -1) & !allDataIsLoaded || isLoadingForGetUsers"
      class="text-center my-3"
    >
      Loading...
    </p>
    <div
      v-if="usersTotalData === 0 && !isLoadingForGetUsers"
      class="text-center text-xl mt-5"
    >
      No data found
    </div>
    <DialogMintNft />
  </div>
</template>

<script>
import { emitter } from '@/utils/eventBus'
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapMutations, mapState } from 'vuex'
import DialogMintNft from '@/components/AdminCenter/Users/DialogMintNft'
import { UserActionTypes, UserMutationTypes } from '@/store/modules/admin/users/type'

export default {
  name: 'UsersPage',
  components: { DialogMintNft },
  props: {
    loadMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      allDataIsLoaded: false,
      searchUsersTimeout: null
    }
  },
  computed: {
    ...mapState(moduleTypes.USERS, ['users', 'usersPage', 'usersTotalData', 'isLoadingForGetUsers'])
  },
  watch: {
    loadMore: function () {
      if (this.usersTotalData === this.users.length) this.allDataIsLoaded = true
      if (!this.isLoadingForGetUsers && this.usersTotalData > this.users.length && this.search == '') {
        this.UPDATE_PAGE_FOR_USERS(this.usersPage + 1)
        this.getAllUsers()
      } else if (!this.isLoadingForGetUsers && this.usersTotalData > this.users.length && this.search) {
        this.UPDATE_PAGE_FOR_USERS(this.usersPage + 1)
        this.searchUser(this.search)
      }
    }
  },
  mounted() {
    emitter.on('update_company', () => {
      this.getAllUsersData()
    })
    this.getAllUsersData()
  },
  unmounted() {
    emitter.off('update_company')
  },
  methods: {
    ...mapMutations(moduleTypes.USERS, [
      UserMutationTypes.CLEAR_USERS,
      UserMutationTypes.UPDATE_PAGE_FOR_USERS,
      UserMutationTypes.SET_SELECTED_USER_FOR_MINT_NFT,
      UserMutationTypes.UPDATE_IS_DIALOG_MINT_NFT_VISIBLE
    ]),
    ...mapActions(moduleTypes.USERS, [
      UserActionTypes.searchUser,
      UserActionTypes.getAllUsers
    ]),
    searchUsers() {
      clearTimeout(this.searchUsersTimeout)
      this.searchUsersTimeout = setTimeout(() => {
        this.CLEAR_USERS()
        if (this.search) {
          this.searchUser(this.search).then(() => {
            if (this.usersTotalData === this.users.length) this.allDataIsLoaded = true
          })
        } else {
          this.getAllUsers()
        }
      }, 1000)
    },
    getAllUsersData() {
      this.CLEAR_USERS()
      this.getAllUsers().then(() => {
        if (this.usersTotalData === this.users.length) this.allDataIsLoaded = true
      })
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
