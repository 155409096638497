import router from '@/router'
import { Auth } from 'aws-amplify'
import { moduleTypes } from '@/store/modules/type'
import { VerifyUserMutationTypes, VerifyUserActionTypes } from './type'
import AuthService from '@/service/AuthService'
const authService = new AuthService()

const state = {
  verifyUserForm: {
    confirmationCode: ''
  },
  isVerifyUserLoading: false,
  isResendConfirmationCodeLoading: false,
  errorMessage: '',
  resendConfirmationCodeAlert: {
    message: '',
    type: null // success | error | warning | null (hidden)
  }
}

const mutations = {
  [VerifyUserMutationTypes.UPDATE_CONFIRMATION_CODE]: (state, { key, value }) => {
    state.verifyUserForm[key] = value
  },
  [VerifyUserMutationTypes.SET_IS_VERIFY_USER_LOADING]: (state, isVerifyUserLoading) => {
    state.isVerifyUserLoading = isVerifyUserLoading
  },
  [VerifyUserMutationTypes.SET_IS_RESEND_CONFIRMATION_CODE_LOADING]: (state, isResendConfirmationCodeLoading) => {
    state.isResendConfirmationCodeLoading = isResendConfirmationCodeLoading
  },
  [VerifyUserMutationTypes.SET_RESEND_CONFIRMATION_CODE_ALERT]: (state, { type, message }) => {
    state.resendConfirmationCodeAlert = {
      type, message
    }
  },
  [VerifyUserMutationTypes.SET_ERROR_MESSAGE]: (state, errorMessage) => {
    state.errorMessage = errorMessage
  },
  [VerifyUserMutationTypes.RESET_FORM]: (state) => {
    state.verifyUserForm = {
      confirmationCode: ''
    }
    state.errorMessage = ''
    state.resendConfirmationCodeAlert = {
      type: null,
      message: ''
    }
  }
}

const actions = {
  [VerifyUserActionTypes.resendConfirmationCode]: async ({ commit }, { email, isFromLogin }) => {
    if (!isFromLogin) commit(VerifyUserMutationTypes.SET_IS_RESEND_CONFIRMATION_CODE_LOADING, true)
    try {
      await Auth.resendSignUp(email)
      if (!isFromLogin) {
        commit(VerifyUserMutationTypes.SET_RESEND_CONFIRMATION_CODE_ALERT, {
          type: 'success',
          message: 'Successfully send to your email address.'
        })
      }
    } catch (e) {
      commit(VerifyUserMutationTypes.SET_RESEND_CONFIRMATION_CODE_ALERT, {
        type: 'error',
        message: `Something went wrong: ${e.message}`
      })
    }
    commit(VerifyUserMutationTypes.SET_IS_RESEND_CONFIRMATION_CODE_LOADING, false)
  },
  [VerifyUserActionTypes.confirmSignUp]: async ({ state }, { email }) => {
    await Auth.confirmSignUp(email, state.verifyUserForm.confirmationCode)
  },

  [VerifyUserActionTypes.verifyConfirmationCode]: async ({ commit, dispatch }, { email, password }) => {
    commit(VerifyUserMutationTypes.SET_IS_VERIFY_USER_LOADING, true)
    try {
      await dispatch(VerifyUserActionTypes.confirmSignUp, { email })
      try {
        await dispatch(`${moduleTypes.LOGIN}/signIn`, { email, password }, { root: true })
        commit(`${moduleTypes.APP}/SET_IS_LOGGED_IN`, true, { root: true })
        await authService.createUser()

        await dispatch(`${moduleTypes.APP}/getCurrentUser`, null, { root: true })
        router.push({ path: '/' })
      } catch (error) {
        console.error(error)
        commit(VerifyUserMutationTypes.SET_ERROR_MESSAGE, 'Something is wrong. Please try again!')
      }
    } catch (error) {
      console.error(error)
      commit(VerifyUserMutationTypes.SET_ERROR_MESSAGE, 'Wrong confirmation code')
    }
    commit(VerifyUserMutationTypes.SET_IS_VERIFY_USER_LOADING, false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
