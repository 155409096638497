<template>
  <div>
    <div class="px-[10%]">
      <div class="flex justify-between pt-4 pb-8">
        <h2 class="text-3xl font-bold">
          Admin ({{ totalData == -1 ? 0 : totalData }})
        </h2>
        <el-button
          size="large"
          data-cy="add_admin_button"
          @click="onCreate"
        >
          Add admin
        </el-button>
      </div>

      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 sm:px-6 lg:px-8 inline-block min-w-full">
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="border-b">
                  <tr>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Manage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in admins"
                    :key="index"
                    class="border-b"
                  >
                    <td
                      :data-cy="'admin_email_' + index"
                      class="text-sm text-gray-900 font-light px-6 py-4"
                    >
                      {{ item.user.email }}
                    </td>
                    <td class="text-sm text-gray-900 font-light px-6 py-4">
                      <el-button
                        type="danger"
                        :icon="deleteIcon"
                        circle
                        :data-cy="'delete_admin_' + index"
                        @click="() => {
                          onDelete(index)
                        }"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p
                v-if="(totalData !== -1) & !allDataIsLoaded || isLoadingForGetAdmin"
                class="text-center my-3"
              >
                Loading...
              </p>
              <div
                v-if="totalData === 0 && !isLoadingForGetAdmin"
                class="text-center text-xl mt-5"
              >
                No data found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DialogCreateAdmin />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import { emitter } from '@/utils/eventBus'
import { Delete } from '@element-plus/icons-vue'
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapMutations, mapState } from 'vuex'
import DialogCreateAdmin from '@/components/AdminCenter/Company/DialogCreateAdmin.vue'
import { CompanyActionTypes, CompanyMutationTypes } from '@/store/modules/admin/company/type'

export default {
  name: 'ManageAdmin',
  components: { DialogCreateAdmin },
  props: {
    loadMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deleteIcon: shallowRef(Delete),
      allDataIsLoaded: false
    }
  },
  computed: {
    ...mapState(moduleTypes.APP, ['companyId']),
    ...mapState(moduleTypes.COMPANY, ['isLoadingForGetAdmin', 'admins', 'totalData', 'page'])
  },
  watch: {
    loadMore: function () {
      if (this.totalData === this.admins.length) { this.allDataIsLoaded = true }
      if (!this.isLoadingForGetCompany && this.totalData > this.admins.length) {
        this.UPDATE_PAGE(this.page + 1)
        this.getAdminsByCompany(this.$route.name == 'ManageAdmin' ? this.companyId : this.$route.params.companyId)
      }
    }
  },
  mounted() {
    emitter.on('update_company', () => {
      this.onGetAdminData()
    })
    this.onGetAdminData()
  },
  unmounted() {
    emitter.off('update_company')
  },
  methods: {
    ...mapActions(moduleTypes.COMPANY, [CompanyActionTypes.getAdminsByCompany, CompanyActionTypes.removeAdminFromCompany]),
    ...mapMutations(moduleTypes.COMPANY, [
      CompanyMutationTypes.UPDATE_PAGE,
      CompanyMutationTypes.CLEAR_ADMIN,
      CompanyMutationTypes.IS_SHOW_ERROR_MESSAGE,
      CompanyMutationTypes.UPDATE_FORM_FOR_ADMIN,
      CompanyMutationTypes.IS_DIALOG_OPEN_FOR_CREATE
    ]),
    onCreate() {
      this.IS_SHOW_ERROR_MESSAGE('')
      this.UPDATE_FORM_FOR_ADMIN({ key: 'email', value: '' })
      this.IS_DIALOG_OPEN_FOR_CREATE(true)
    },
    onDelete(index) {
      this.$confirm('Are you sure you want to continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.removeAdminFromCompany(index)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },
    onGetAdminData() {
      this.CLEAR_ADMIN()
      this.getAdminsByCompany(this.$route.name == 'ManageAdmin' ? this.companyId : this.$route.params.companyId).then(() => {
        if (this.totalData === this.admins.length) { this.allDataIsLoaded = true }
      })
    }
  }
}
</script>
