const ForgetPasswordMutationTypes = {
  SET_IS_FORGET_PASSWORD_LOADING: 'SET_IS_FORGET_PASSWORD_LOADING',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  FORGET_PASSWORD_RESET_FORM: 'FORGET_PASSWORD_RESET_FORM',
  IS_SHOW_PASSWORD: 'IS_SHOW_PASSWORD',
  IS_SHOW_ACTIVATION_CODE: 'IS_SHOW_ACTIVATION_CODE'
}

const ForgetPasswordActionTypes = {
  forgotPassword: 'forgotPassword',
  updateForgotPassword: 'updateForgotPassword'
}

module.exports = {
  ForgetPasswordActionTypes,
  ForgetPasswordMutationTypes
}
