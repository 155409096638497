<template>
  <div class="flex relative text-left items-center inline-block">
    <ElementInput
      type="text"
      name="Company"
      label="Company"
      class="caret-transparent"
      :value="selecteCompanyName"
      placeholder="Select Company"
      :disabled="companyList.length == 1 ? true : false"
      @input="(event) => {
        SET_SELECT_COMPANY_NAME(event.target.value)
      }"
      @blur="onCloseContextMenu"
      @focus="onOpenContextMenu"
    />
    <Transition name="slide-fade">
      <ContextMenu
        v-if="isShowContextMenu"
        :items="companyList"
        :is-company-list="true"
        @click="onUpdateCompany"
      />
    </Transition>
  </div>
</template>

<script>
import { emitter } from '@/utils/eventBus'
import { mapMutations, mapState } from 'vuex'
import { moduleTypes } from '@/store/modules/type'
import ContextMenu from '@/components/Core/ContextMenu.vue'
import ElementInput from '@/components/Core/ElementInput.vue'
import { CoreMutationTypes } from '@/store/modules/core/type'

export default {
  name: 'CompanyDropDown',
  components: { ElementInput, ContextMenu },
  data() {
    return {
      isShowContextMenu: false
    }
  },
  computed: {
    ...mapState(moduleTypes.CORE, ['companyList', 'selecteCompanyName'])
  },
  methods: {
    ...mapMutations(moduleTypes.CORE, [
      CoreMutationTypes.SET_SELECT_COMPANY_NAME
    ]),
    ...mapMutations(moduleTypes.APP, [
      'SET_COMPANY_ID'
    ]),
    onUpdateCompany(value) {
      this.SET_SELECT_COMPANY_NAME(value.company.name)
      this.SET_COMPANY_ID(value.company.id)
      emitter.emit('update_company')
      this.isShowContextMenu = false
    },
    onCloseContextMenu() {
      setTimeout(() => {
        this.isShowContextMenu = false
      }, 150)
    },
    onOpenContextMenu() {
      this.isShowContextMenu = true
    }
  }
}
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  margin-top: 6px;
  margin-bottom: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ecedee;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #909399;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
