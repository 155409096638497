const WhiteListedMutationTypes = {
  SET_WHITELISTED: 'SET_WHITELISTED',

  UPDATE_WHITELISTED_USERS_FILTER: 'UPDATE_WHITELISTED_USERS_FILTER',

  UPDATE_FORM: 'UPDATE_FORM',
  UPDATE_EMAILS: 'UPDATE_EMAILS',
  UPDATE_EMAILS_ARRAY: 'UPDATE_EMAILS_ARRAY',
  UPDATE_WALLET_ADDRESSES: 'UPDATE_WALLET_ADDRESSES',
  UPDATE_WALLET_ADDRESSES_ARRAY: 'UPDATE_WALLET_ADDRESSES_ARRAY',
  UPDATE_IS_FORM_SUBMIT_LOADING: 'UPDATE_IS_FORM_SUBMIT_LOADING',
  UPDATE_MINTING_PROCESS_STATUS: 'UPDATE_MINTING_PROCESS_STATUS',
  RESET_FORM: 'RESET_FORM',
  RESET_WALLET_CREATION_STATUS: 'RESET_WALLET_CREATION_STATUS',

  CLEAR_WHITELISTED_USERS: 'CLEAR_WHITELISTED_USERS',
  UPDATE_PAGE_FOR_WHITELISTED: 'UPDATE_PAGE_FOR_WHITELISTED',
  UPDATE_TOTAL_WHITELISTED_USER_DATA: 'UPDATE_TOTAL_WHITELISTED_USER_DATA',
  IS_LOADING_FOR_GET_WHITELISTED_USERS: 'IS_LOADING_FOR_GET_WHITELISTED_USERS'
}

const WhiteListedActionTypes = {
  getWhitelisted: 'getWhitelisted',
  createNftTransfer: 'createNftTransfer',
  createWhitelisted: 'createWhitelisted',
  startMintingProcess: 'startMintingProcess',
  payGasFeeForTransfer: 'payGasFeeForTransfer',
  getWhitelistedUsersFilter: 'getWhitelistedUsersFilter',
  payGasFeeForTransaction: 'payGasFeeForTransaction'
}

module.exports = {
  WhiteListedMutationTypes,
  WhiteListedActionTypes
}
