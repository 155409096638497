import { API } from 'aws-amplify'
import serviceName from './index'

const route = `${process.env.VUE_APP_NODE_ENV !== 'development' ? '/private' : ''}/collection`

export default class CollectionServices {
  async generateSignedUrlForUpload(queryStringParameters) {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/generate-signed-url`, queryStringParameters)
    } catch (e) {
      console.log('Error')
    }
  }
  async uploadImagesToPinata({ collectionName, totalNfts, ext }) {
    try {
      return await API.post(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/upload-images-to-pinata`, {
        body: {
          ext,
          totalNfts,
          collectionName
        }
      })
    } catch (e) {
      console.log('Error:-', e)
    }
  }
  async uploadMetadataToPinata({ ext, nftsTitle, attributes, description, collectionName, ipfsHashOfImages }) {
    try {
      return await API.post(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/upload-metadata-to-pinata`, {
        body: {
          ext,
          nftsTitle,
          attributes,
          description,
          collectionName,
          ipfsHashOfImages
        }
      })
    } catch (e) {
      console.log('Error:-', e)
    }
  }
  async deploySmartContract({ name, ipfsHashOfMetadata }) {
    try {
      return await API.post(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/generate-smart-contract`, {
        body: { name, ipfsHashOfMetadata }
      })
    } catch (e) {
      console.log('Error')
    }
  }
  async createCollection({ name, description, benefits, smartContractAddress, ownerWalletAddress, blockchain, totalTokens }) {
    try {
      return await API.post(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/create-collection`, {
        body: {
          name, description, benefits, smartContractAddress, ownerWalletAddress, blockchain, totalTokens
        }
      })
    } catch (e) {
      console.log('Error')
    }
  }
  async getCollection(page, size, isFromDashboard) {
    try {
      return await API.get(isFromDashboard ? serviceName.CONFERENCE_NFTS_RESTAPI_WITHOUT_COMPANY : serviceName.CONFERENCE_NFTS_RESTAPI, `${route}?page=${page}&size=${size}`)
    } catch (e) {
      console.log(e)
    }
  }
  async searchCollectionInWhitelisted(searchCollectionName, page) {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/search?name=${searchCollectionName}&page=${page}&size=20`)
    } catch (e) {
      console.log(e)
    }
  }
}
