<template>
  <div id="app">
    <header-nav v-if="$route.meta.headerShow" />
    <div
      :class="`${$route.name == 'NftDetails' || $route.name == 'Signup' || $route.name == 'Auth' || $route.name == 'Verify' || $route.name == 'ForgetPassword' ? '':'overflow-auto'}`"
      :style="`height: ${containerHeight}px`"
      data-cy="scrollable_div"
      @scroll="onScroll"
    >
      <div
        v-if="(isAdmin || isSuperAdmin) && $route.href !== '/' && $route.name !== 'NftDetails' && $route.name !== 'Company' && $route.name !== 'CompanyAdmin' && !isLoginLoading && !isSignUpLoading"
        class="px-[10%] pt-9 flex items-center justify-between"
      >
        <CompanyDropDown />
        <connect-wallet />
      </div>
      <router-view :load-more="loadMore" />
      <footer-nav
        v-if="$route.meta.footerShow"
        :class="`${$route.fullPath == '/' ? '':'fixed bottom-0 left-0'} mobile:hidden`"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { moduleTypes } from '@/store/modules/type'
import footerNav from '@/components/nav/footerNav'
import headerNav from '@/components/nav/headerNav'
import ConnectWallet from '@/views/adminCenter/components/ConnectWallet.vue'
import CompanyDropDown from '@/components/AdminCenter/Company/CompanyDropDown.vue'
export default {
  components: {
    headerNav,
    footerNav,
    ConnectWallet,
    CompanyDropDown
  },
  data() {
    return {
      containerHeight: 0,
      loadMore: false
    }
  },
  computed: {
    ...mapState(moduleTypes.APP, ['isAdmin', 'isSuperAdmin']),
    ...mapState(moduleTypes.LOGIN, ['isLoginLoading']),
    ...mapState(moduleTypes.SIGNUP, ['isSignUpLoading'])
  },
  mounted() {
    this.$nextTick(() => {
      this.containerHeight = window.innerHeight - 65
    })
  },
  methods: {
    onScroll(event) {
      const el = event.srcElement
      this.$nextTick(() => {
        if (el.scrollTop + el.clientHeight >= el.scrollHeight - 100) {
          this.loadMore = !this.loadMore
        }
      })
    }
  }
}

</script>

<style scoped>
#app{
  font: 14px/1 'Helvetica', 'PingFang SC', 'Hiragino Sans GB', Arial, sans-serif;
}
</style>

