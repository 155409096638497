<template>
  <div>
    <el-button
      v-if="!isConnected"
      style="padding:20px"
      type="primary"
      @click="connect"
    >
      Connect wallet
    </el-button>

    <div v-if="isConnected">
      <h6>
        Admin Wallet address: <el-tag>{{ adminWallet }}</el-tag>
      </h6>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mapState, mapMutations } from 'vuex'
import { moduleTypes } from '@/store/modules/type'
import { CoreMutationTypes } from '@/store/modules/core/type'

export default {
  computed: {
    ...mapState(moduleTypes.CORE, ['isConnected', 'adminWallet'])
  },
  async mounted() {
    window.ethereum.on('accountsChanged', function (accounts) {
      if (accounts.length > 0) {
        alert(`Account change to ${accounts[0]}`)
        store.commit(`${moduleTypes.CORE}/${CoreMutationTypes.SET_IS_CONNECTED}`, true)
        store.commit(`${moduleTypes.CORE}/${CoreMutationTypes.SET_ADMIN_WALLET}`, accounts[0])
      } else {
        alert('Account is disconnected')
        store.commit(`${moduleTypes.CORE}/${CoreMutationTypes.SET_IS_CONNECTED}`, false)
        store.commit(`${moduleTypes.CORE}/${CoreMutationTypes.SET_ADMIN_WALLET}`, '')
      }
    })

    store.commit(`${moduleTypes.CORE}/${CoreMutationTypes.SET_SELECTED_CHAIN_IN_METAMASK}`, Number(await window.ethereum.request({ method: 'net_version' })))
    window.ethereum.on('chainChanged', function (networkId) {
      console.log('chainChanged', Number(networkId))

      store.commit(`${moduleTypes.CORE}/${CoreMutationTypes.SET_SELECTED_CHAIN_IN_METAMASK}`, Number(networkId))
    })
    window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
      if (accounts.length > 0) {
        this.SET_IS_CONNECTED(true)
        this.SET_ADMIN_WALLET(accounts[0])
      } else {
        this.SET_IS_CONNECTED(false)
      }
    })
  },
  methods: {
    ...mapMutations(moduleTypes.CORE, [CoreMutationTypes.SET_IS_CONNECTED, CoreMutationTypes.SET_ADMIN_WALLET]),
    connect: function () {
      if (window.ethereum) {
        window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
          if (accounts.length > 0) {
            this.SET_IS_CONNECTED(true)
            this.SET_ADMIN_WALLET(accounts[0])
          } else {
            this.SET_IS_CONNECTED(false)
            this.SET_ADMIN_WALLET('')
          }
        })
      }
    }
  }
}
</script>
