const CoreMutationTypes = {
  SET_IS_CONNECTED: 'SET_IS_CONNECTED',
  SET_ADMIN_WALLET: 'SET_ADMIN_WALLET',
  SET_COMPANY_LIST: 'SET_COMPANY_LIST',
  SET_SELECT_COMPANY_NAME: 'SET_SELECT_COMPANY_NAME',
  SET_SELECTED_CHAIN_IN_METAMASK: 'SET_SELECTED_CHAIN_IN_METAMASK'
}

module.exports = {
  CoreMutationTypes
}
