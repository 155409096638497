<template>
  <div>
    <el-card>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :inline="true"
        hide-required-asterisk
      >
        <div>
          <el-alert
            v-if="collection?.blockchain"
            type="error"
            style="padding: 0; height: 38px"
          >
            Make sure your MM is set to <b>{{ collection?.blockchain }}</b>
          </el-alert>
          <br />

          <div class="mb-5 flex relative text-left items-center">
            <ElementInput
              type="text"
              label="Collection"
              :value="form.searchCollectionName"
              placeholder="Select Collection"
              data-cy="whitelisted_collection_list"
              @input="onSearchCollection"
              @blur="onCloseContextMenu"
              @focus="onOpenContextMenu"
            />
            <Transition name="slide-fade">
              <ContextMenu
                v-if="isShowContextMenu"
                :items="collections"
                :is-loading-for-get-item="isLoadingForGetCollection"
                data-cy="collection_scrollable_div"
                @scroll="onScroll"
                @click="onUpdateCollection"
              />
            </Transition>
          </div>
          <div class="mb-5 pl-20 text-sm">
            <p v-if="form.collection">
              <b>Owner wallet address:</b> {{ form.collection.ownerWalletAddress }} and <b>Network:</b>
              {{ SUPPORTED_CHAIN_NAMES[form.collection.blockchain] }}
            </p>
            <p
              v-if="form.collection"
              class="pt-5"
            >
              <b>AvailableToken:</b> {{ form.collection.availableTokens }} out of {{ form.collection.totalTokens }} tokens
              left.
            </p>
          </div>
        </div>
        <div>
          <el-form-item
            prop="mintMode"
            label="Mint using"
          >
            <el-radio-group
              :model-value="form.mintMode"
              @change="(value) => {
                UPDATE_FORM({
                  key: 'mintMode',
                  value
                })
              }
              "
            >
              <el-radio :label="MINT_MODE.USING_EMAIL"> Using email </el-radio>
              <el-radio
                data-cy="whitelisted_mint_mode"
                :label="MINT_MODE.USING_WALLET_ADDRESS"
              >
                Using wallet address
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div>
          <el-input
            type="textarea"
            data-cy="add_email_or_wallet_textarea"
            :model-value="form.mintMode === MINT_MODE.USING_EMAIL ? emailsToText : walletAddressesToText"
            class="mb-2"
            :disabled="form.collection == ''"
            :placeholder="form.mintMode === MINT_MODE.USING_EMAIL
              ? 'Please write emails separated by comma (ex: jo@gmail.com, mila@gmail.com)'
              : 'Please write wallet addresses separated by comma (ex: 0x39*********416, 0x4E*********10f)'
            "
            @input="(value) => {
              if (((value.match(new RegExp(',', 'g')) || []).length) != form.collection.availableTokens) {
                if (form.mintMode === MINT_MODE.USING_EMAIL) UPDATE_EMAILS(value.toLowerCase())
                else UPDATE_WALLET_ADDRESSES(value)
              }
            }"
          />
        </div>
        <p class="my-2">
          {{ form.mintMode === MINT_MODE.USING_EMAIL ? 'Or Add new email' : 'Or Add new wallet address' }}
        </p>
        <div>
          <el-form-item
            v-for="(_, index) in form[form.mintMode === MINT_MODE.USING_EMAIL ? 'emails' : 'walletAddresses']"
            :key="index"
          >
            <el-row :gutter="16">
              <el-col :span="18">
                <el-input
                  :model-value="form[form.mintMode === MINT_MODE.USING_EMAIL ? 'emails' : 'walletAddresses'][index]"
                  :data-cy="`add_email_or_walletadd_input${index}`"
                  size="large"
                  type="text"
                  autocomplete="off"
                  :placeholder="form.mintMode === MINT_MODE.USING_EMAIL ? 'Email' : 'Wallet address'"
                  prop="email"
                  @input="(value) => {
                    if (value.substring(value.length - 1) != ',')
                      if (form.mintMode === MINT_MODE.USING_EMAIL) UPDATE_EMAILS_ARRAY({ index, value: value.toLowerCase() })
                      else UPDATE_WALLET_ADDRESSES_ARRAY({ index, value })
                  }"
                />
              </el-col>
              <el-col :span="2">
                <div
                  v-if="walletCreationStatus[index] != null && walletCreationStatus[index].hasGetErrorCreatingWhitelisted
                  "
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Failed: Add email to whitelist"
                    placement="top-start"
                  >
                    <i class="el-icon-warning text-red"></i>
                  </el-tooltip>
                </div>
                <el-button
                  v-else
                  type="warning"
                  :icon="Delete"
                  :data-cy="`delete_email_or_wallet_button${index}`"
                  circle
                  size="small"
                  @click="() => {
                    if (form.mintMode === MINT_MODE.USING_EMAIL) form.emails.splice(index, 1)
                    else form.walletAddresses.splice(index, 1)
                  }
                  "
                />
              </el-col>
            </el-row>
          </el-form-item>
        </div>
      </el-form>
      <el-button
        class="mt-2"
        :icon="Plus"
        size="large"
        data-cy="add_email_or_wallet_button"
        :disabled="form.collection == '' ||
          (form.mintMode === MINT_MODE.USING_EMAIL && form.collection.availableTokens == form.emails.length) ||
          (form.mintMode === MINT_MODE.USING_WALLET_ADDRESS && form.collection.availableTokens == form.walletAddresses.length)
        "
        @click="() => {
          if (form.mintMode === MINT_MODE.USING_EMAIL) form.emails.push('')
          else form.walletAddresses.push('')
        }
        "
      >
        {{ form.mintMode === MINT_MODE.USING_EMAIL ? 'Add new Email' : 'Add new Wallet address' }}
      </el-button>
      <div class="flex justify-between items-baseline">
        <el-alert
          v-if="mintingProcessStatus.message"
          :title="mintingProcessStatus.message"
          :type="mintingProcessStatus.type"
        />

        <el-button
          class="mt-5"
          size="large"
          type="primary"
          :loading="isFormSubmitLoading"
          data-cy="create_whitelisted_button"
          :disabled="(form.collection && adminWallet.toLowerCase() !== form.collection.ownerWalletAddress.toLowerCase()) ||
            !(
              (selectedChainInMetamask === SUPPORTED_CHAINS.POLYGON && form.collection.blockchain === SUPPORTED_CHAINS.POLYGON) ||
              (selectedChainInMetamask === SUPPORTED_CHAINS.POLYGON_MUMBAI && form.collection.blockchain === SUPPORTED_CHAINS.POLYGON_MUMBAI) ||
              (selectedChainInMetamask === SUPPORTED_CHAINS.ETHEREUM && form.collection.blockchain === SUPPORTED_CHAINS.ETHEREUM) ||
              (selectedChainInMetamask === SUPPORTED_CHAINS.ETHEREUM_SEPOLIA && form.collection.blockchain === SUPPORTED_CHAINS.ETHEREUM_SEPOLIA)
            ) || form.collection.availableTokens <= 0
          "
          @click="onFormSubmit"
        >
          {{ mintingProcessStatus.type !== 'error' ? 'Add to whitelisted and Assign NFT' : 'Try again' }}
        </el-button>
      </div>

      <br />
      <div
        v-if="form.collection.availableTokens == 0"
        class="text-red-600"
      >
        No NFT’s left in collection
      </div>
      <div v-if="form.collection.blockchain && form.collection.ownerWalletAddress">
        {{
          (selectedChainInMetamask === SUPPORTED_CHAINS.POLYGON && form.collection.blockchain === SUPPORTED_CHAINS.POLYGON)
            ||
            (selectedChainInMetamask === SUPPORTED_CHAINS.POLYGON_MUMBAI && form.collection.blockchain ===
              SUPPORTED_CHAINS.POLYGON_MUMBAI) ||
            (selectedChainInMetamask === SUPPORTED_CHAINS.ETHEREUM && form.collection.blockchain ===
              SUPPORTED_CHAINS.ETHEREUM) ||
            (selectedChainInMetamask === SUPPORTED_CHAINS.ETHEREUM_SEPOLIA && form.collection.blockchain ===
              SUPPORTED_CHAINS.ETHEREUM_SEPOLIA)
            ? ''
            : 'Please select correct network in metamask'
        }}
        <br />
        {{
          adminWallet.toLowerCase() !== form.collection.ownerWalletAddress.toLowerCase()
            ? 'Please select correct admin wallet in metamask'
            : ''
        }}
      </div>
    </el-card>
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import { emailValidator } from '@/utils/validator'
import { moduleTypes } from '@/store/modules/type'
import { Delete, Plus } from '@element-plus/icons-vue'
import { MINT_MODE, SUPPORTED_CHAINS, SUPPORTED_CHAIN_NAMES } from '@/constants'
import ContextMenu from '@/components/Core/ContextMenu.vue'
import ElementInput from '@/components/Core/ElementInput.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { CollectionActionTypes, CollectionMutationTypes } from '@/store/modules/admin/collection/type'
import { WhiteListedActionTypes, WhiteListedMutationTypes } from '@/store/modules/admin/whitelisted/type'

export default {
  name: 'AddWhitelisted',
  components: { ContextMenu, ElementInput },
  data () {
    return {
      SUPPORTED_CHAINS,
      SUPPORTED_CHAIN_NAMES,
      MINT_MODE,
      Delete: shallowRef(Delete),
      Plus: shallowRef(Plus),
      rules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          { trigger: 'blur', validator: emailValidator }
        ],
        collection: [{ required: true, message: 'Collection is required', trigger: 'blur' }]
      },
      collection: {},
      isShowContextMenu: false,
      searchCollectionTimeout: null
    }
  },
  computed: {
    ...mapState(moduleTypes.WHITELISTED, [
      'form',
      'isFormSubmitLoading',
      'mintingProcessStatus',
      'walletCreationStatus'
    ]),
    ...mapState(moduleTypes.CORE, ['adminWallet', 'selectedChainInMetamask']),
    ...mapGetters(moduleTypes.WHITELISTED, ['emailsToText', 'walletAddressesToText']),
    ...mapState(moduleTypes.COLLECTION, [
      'collections',
      'collectionPage',
      'collectionTotalData',
      'isLoadingForGetCollection'
    ])
  },
  methods: {
    ...mapMutations(moduleTypes.WHITELISTED, [
      WhiteListedMutationTypes.UPDATE_FORM,
      WhiteListedMutationTypes.UPDATE_EMAILS,
      WhiteListedMutationTypes.UPDATE_EMAILS_ARRAY,
      WhiteListedMutationTypes.UPDATE_WALLET_ADDRESSES,
      WhiteListedMutationTypes.UPDATE_WALLET_ADDRESSES_ARRAY
    ]),
    ...mapMutations(moduleTypes.COLLECTION, [
      CollectionMutationTypes.CLEAR_COLLECTION,
      CollectionMutationTypes.UPDATE_PAGE_FOR_COLLECTION,
      CollectionMutationTypes.IS_LOADING_FOR_GET_COLLECTION
    ]),
    ...mapActions(moduleTypes.WHITELISTED, [WhiteListedActionTypes.startMintingProcess]),
    ...mapActions(moduleTypes.COLLECTION, [
      CollectionActionTypes.getCollections,
      CollectionActionTypes.searchCollectionInWhitelisted
    ]),
    async onFormSubmit () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.startMintingProcess()
        } else {
          return false
        }
      })
    },
    onSearchCollection (event) {
      this.CLEAR_COLLECTION()
      this.IS_LOADING_FOR_GET_COLLECTION(true)
      this.UPDATE_FORM({
        key: 'searchCollectionName',
        value: event.target.value
      })
      clearTimeout(this.searchCollectionTimeout)
      this.searchCollectionTimeout = setTimeout(() => {
        if (this.form.searchCollectionName) {
          this.searchCollectionInWhitelisted(this.form.searchCollectionName)
        } else {
          this.getCollections()
          this.UPDATE_FORM({
            key: 'collection',
            value: ''
          })
        }
      }, 1000)
    },
    onScroll (event) {
      const el = event.srcElement
      this.$nextTick(() => {
        if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
          if (!this.isLoadingForGetCollection && this.collectionTotalData > this.collections.length) {
            if (this.form.searchCollectionName) {
              this.UPDATE_PAGE_FOR_COLLECTION(this.collectionPage + 1)
              this.searchCollectionInWhitelisted(this.form.searchCollectionName)
            } else {
              this.UPDATE_PAGE_FOR_COLLECTION(this.collectionPage + 1)
              this.getCollections()
            }
          }
        }
      })
    },
    onUpdateCollection (value) {
      this.UPDATE_FORM({
        key: 'collection',
        value: value
      })
      this.UPDATE_FORM({
        key: 'searchCollectionName',
        value: this.form.collection.name
      })
      this.isShowContextMenu = false
    },
    onCloseContextMenu () {
      setTimeout(() => {
        this.isShowContextMenu = false
      }, 150)
    },
    onOpenContextMenu () {
      this.isShowContextMenu = true
      this.getCollections()
    }
  }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
