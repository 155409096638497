// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../node_modules/element-plus/dist/index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --el-color-primary: #3aa845;\n  --el-color-primary-light-3: #95d475;\n  --el-color-primary-dark-2:#529b2e;\n  --el-color-primary-light-5:#e3f0dd;\n  --el-text-color-placeholder: #a8abb2;\n  --el-border-radius-round:30px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
