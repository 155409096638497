export function emailValidator(rule, value, callback) {
  // eslint-disable-next-line
  const reg = /^[\w.!#$%&'*+\/=?^`{|}~-]+@\w+(-\w+)*\.\w+(-\w+)*(\.[a-zA-Z]+)*$/
  if (!value) {
    callback()
    // return callback(new Error('Email is required'))
  } else {
    if (!reg.test(value)) {
      callback(new Error('Please enter the correct email'))
    } else {
      callback()
    }
  }
}

export function trimValidator(rule, value, callback) {
  // eslint-disable-next-line no-irregular-whitespace
  const reg = /^[\s　]|[ ]$/gi
  if (!value) {
    callback()
  } else {
    if (reg.test(value)) {
      callback(new Error('No spaces in password'))
    } else {
      callback()
    }
  }
}
