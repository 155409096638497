<template>
  <div class="pt-5 px-[10%]">
    <AddWhitelisted />
    <el-divider />
    <div>
      <h3 class="font-bold">Whitelisted list</h3>

      <div class="mb-5 my-4 flex items-center relative inline-block text-left">
        <ElementInput
          type="text"
          label="Collection"
          placeholder="Select Collection"
          data-cy="whitelisted_user_collection_list"
          :value="form.searchCollectionNameForWhitelistedList"
          @blur="onCloseContextMenu"
          @focus="onOpenContextMenu"
          @input="onSearchCollection"
        />
        <Transition name="slide-fade">
          <ContextMenu
            v-if="isShowContextMenu"
            :items="collections"
            :is-loading-for-get-item="isLoadingForGetCollection"
            @scroll="onScroll"
            @click="onUpdateCollection"
          />
        </Transition>
      </div>

      <div class="flex flex-col my-5">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="border-b">
                  <tr>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Wallet Addres
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Collection
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Signup Status
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Token
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(whitelistedUser, index) in whitelistedUsers"
                    :key="whitelistedUser.id"
                    class="border-b"
                  >
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                      :data-cy="'whitelisted_name_' + index"
                    >
                      {{ whitelistedUser.user != null ? whitelistedUser.user.email : '-' }}
                    </td>
                    <td class="text-sm text-gray-900 font-light px-6 py-4">
                      {{
                        whitelistedUser.user != null
                          ? whitelistedUser.user.walletAddress
                          : whitelistedUser.walletAddress
                      }}
                    </td>
                    <td class="text-sm text-gray-900 font-light px-6 py-4">
                      <p>{{ whitelistedUser.collection.name }}</p>
                      <p>{{ whitelistedUser.collection.smartContractAddress.slice(0, 8).concat('...') }}</p>
                    </td>
                    <td class="flex justify-center px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div v-if="whitelistedUser.user != null">
                        <img
                          v-if="whitelistedUser.user.cognitoId == null"
                          src="@/assets/img/close-red.png"
                        />
                        <img
                          v-else
                          src="@/assets/img/signup-verify.png"
                        />
                      </div>
                      <p v-else>-</p>
                    </td>
                    <td class="text-center text-sm text-gray-900 font-light px-6 py-4 break-words">
                      {{ whitelistedUser.tokenId }}
                    </td>
                    <td class="flex text-sm text-gray-900 space-x-4 font-light px-6 py-4 break-words">
                      <el-button
                        type="primary"
                        :icon="View"
                        @click="
                          viewOpneSea(
                            whitelistedUser.collection.blockchain,
                            whitelistedUser.collection.smartContractAddress,
                            whitelistedUser.tokenId
                          )
                        "
                      >
                        OpenSea
                      </el-button>
                      <el-button
                        v-if="whitelistedUser.txHash != null"
                        type="primary"
                        :icon="View"
                        @click="
                          viewTxScan(
                            whitelistedUser.collection.blockchain,
                            whitelistedUser.txHash,
                          )
                        "
                      >
                        PolyScan
                      </el-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-if="(whitelistedTotalData !== -1) & !allDataIsLoaded || isLoadingForGetWhiteListedUsers"
                class="text-center my-3"
              >
                Loading...
              </div>
              <div
                v-if="whitelistedTotalData === 0 && !isLoadingForGetWhiteListedUsers"
                class="text-center text-xl mt-5"
              >
                No data found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import { emitter } from '@/utils/eventBus'
import { SUPPORTED_CHAINS } from '@/constants'
import { View } from '@element-plus/icons-vue'
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapMutations, mapState } from 'vuex'
import ContextMenu from '@/components/Core/ContextMenu.vue'
import ElementInput from '@/components/Core/ElementInput.vue'
import AddWhitelisted from '@/components/AdminCenter/Whitelisted/AddWhitelisted'
import { CollectionActionTypes, CollectionMutationTypes } from '@/store/modules/admin/collection/type'
import { WhiteListedActionTypes, WhiteListedMutationTypes } from '@/store/modules/admin/whitelisted/type'

export default {
  name: 'WhitelistedPage',
  components: { AddWhitelisted, ContextMenu, ElementInput },
  props: {
    loadMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      SUPPORTED_CHAINS,
      View: shallowRef(View),
      allDataIsLoaded: false,
      isShowContextMenu: false,
      whitelistedUsersFilter: false,
      searchCollectionTimeout: null
    }
  },
  computed: {
    ...mapState(moduleTypes.WHITELISTED, [
      'form',
      'whitelistedPage',
      'whitelistedUsers',
      'whitelistedTotalData',
      'isLoadingForGetWhiteListedUsers'
    ]),
    ...mapState(moduleTypes.COLLECTION, [
      'collections',
      'collectionPage',
      'collectionTotalData',
      'isLoadingForGetCollection'
    ])
  },
  watch: {
    loadMore: function () {
      if (this.whitelistedTotalData === this.whitelistedUsers.length) this.allDataIsLoaded = true
      if (
        !this.whitelistedUsersFilter &&
        !this.isLoadingForGetWhiteListedUsers &&
        this.whitelistedTotalData > this.whitelistedUsers.length
      ) {
        this.UPDATE_PAGE_FOR_WHITELISTED(this.whitelistedPage + 1)
        this.getWhitelisted()
      } else if (
        this.whitelistedUsersFilter &&
        !this.isLoadingForGetWhiteListedUsers &&
        this.whitelistedTotalData > this.whitelistedUsers.length
      ) {
        this.UPDATE_PAGE_FOR_WHITELISTED(this.whitelistedPage + 1)
        this.getWhitelistedUsersFilter()
      }
    }
  },
  mounted() {
    emitter.on('update_company', async () => {
      this.getWhitelistedData()
    })
    this.getWhitelistedData()
  },
  unmounted() {
    emitter.off('update_company')
  },
  methods: {
    ...mapMutations(moduleTypes.WHITELISTED, [
      WhiteListedMutationTypes.UPDATE_FORM,
      WhiteListedMutationTypes.RESET_FORM,
      WhiteListedMutationTypes.CLEAR_WHITELISTED_USERS,
      WhiteListedMutationTypes.UPDATE_PAGE_FOR_WHITELISTED,
      WhiteListedMutationTypes.UPDATE_WHITELISTED_USERS_FILTER
    ]),
    ...mapMutations(moduleTypes.COLLECTION, [
      CollectionMutationTypes.CLEAR_COLLECTION,
      CollectionMutationTypes.UPDATE_PAGE_FOR_COLLECTION,
      CollectionMutationTypes.IS_LOADING_FOR_GET_COLLECTION
    ]),
    ...mapActions(moduleTypes.WHITELISTED, [
      WhiteListedActionTypes.getWhitelisted,
      WhiteListedActionTypes.getWhitelistedUsersFilter
    ]),
    ...mapActions(moduleTypes.COLLECTION, [
      CollectionActionTypes.getCollections,
      CollectionActionTypes.searchCollectionInWhitelisted
    ]),
    viewOpneSea(blockchain, smartContractAddr, tokenId) {
      if (blockchain === SUPPORTED_CHAINS.POLYGON) {
        window.open('https://opensea.io/assets/matic' + '/' + smartContractAddr + '/' + tokenId)
      } else if (blockchain === SUPPORTED_CHAINS.POLYGON_MUMBAI) {
        window.open('https://testnets.opensea.io/assets/mumbai/' + smartContractAddr + '/' + tokenId)
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM_SEPOLIA) {
        window.open('https://testnets.opensea.io/assets/sepolia/' + smartContractAddr + '/' + tokenId)
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM) {
        window.open('https://opensea.io/assets/ethereum/' + smartContractAddr + '/' + tokenId)
      }
    },
    viewTxScan(blockchain, txHash) {
      if (blockchain === SUPPORTED_CHAINS.POLYGON) {
        window.open('https://polygonscan.com/tx/' + txHash)
      } else if (blockchain === SUPPORTED_CHAINS.POLYGON_MUMBAI) {
        window.open('https://mumbai.polygonscan.com/tx/' + txHash)
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM_SEPOLIA) {
        window.open('https://sepolia.etherscan.io/tx/' + txHash)
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM) {
        window.open('https://etherscan.io/tx/' + txHash)
      }
    },
    onSearchCollection(event) {
      this.CLEAR_COLLECTION()
      this.IS_LOADING_FOR_GET_COLLECTION(true)
      this.UPDATE_FORM({
        key: 'searchCollectionNameForWhitelistedList',
        value: event.target.value
      })
      clearTimeout(this.searchCollectionTimeout)
      this.searchCollectionTimeout = setTimeout(() => {
        if (this.form.searchCollectionNameForWhitelistedList) {
          this.searchCollectionInWhitelisted(this.form.searchCollectionNameForWhitelistedList)
        } else {
          this.CLEAR_WHITELISTED_USERS()
          this.getCollections()
          this.getWhitelisted()
          this.whitelistedUsersFilter = false
        }
      }, 1000)
    },
    onScroll(event) {
      const el = event.srcElement
      this.$nextTick(() => {
        if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
          if (!this.isLoadingForGetCollection && this.collectionTotalData > this.collections.length) {
            if (this.form.searchCollectionNameForWhitelistedList) {
              this.UPDATE_PAGE_FOR_COLLECTION(this.collectionPage + 1)
              this.searchCollectionInWhitelisted(this.form.searchCollectionNameForWhitelistedList)
            } else {
              this.UPDATE_PAGE_FOR_COLLECTION(this.collectionPage + 1)
              this.getCollections()
            }
          }
        }
      })
    },
    onUpdateCollection(value) {
      this.UPDATE_FORM({
        key: 'collectionWhitelistedList',
        value: value
      })
      this.UPDATE_FORM({
        key: 'searchCollectionNameForWhitelistedList',
        value: this.form.collectionWhitelistedList.name
      })
      this.isShowContextMenu = false
      this.whitelistedUsersFilter = true
      this.CLEAR_WHITELISTED_USERS()
      this.UPDATE_WHITELISTED_USERS_FILTER(this.form.collectionWhitelistedList.id)
      this.getWhitelistedUsersFilter().then(() => {
        if (this.whitelistedTotalData === this.whitelistedUsers.length) this.allDataIsLoaded = true
      })
    },
    onCloseContextMenu() {
      setTimeout(() => {
        this.isShowContextMenu = false
      }, 150)
    },
    onOpenContextMenu() {
      this.isShowContextMenu = true
      this.getCollections()
    },
    async getWhitelistedData() {
      this.RESET_FORM()
      this.CLEAR_WHITELISTED_USERS()
      this.getWhitelisted().then(() => {
        if (this.whitelistedTotalData === this.whitelistedUsers.length) this.allDataIsLoaded = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-button--small {
  padding: 15px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
