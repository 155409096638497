<template>
  <div class="px-[10%]">
    <div class="flex justify-between pt-4 pb-8">
      <h2
        data-cy="title_name"
        class="text-3xl font-bold"
      >
        Collection ({{ collectionTotalData == -1 ? 0 : collectionTotalData }})
      </h2>
      <router-link to="collections/new">
        <el-button
          size="large"
          data-cy="add_new_collection_button"
        >
          Add new collection
        </el-button>
      </router-link>
    </div>

    <div class="flex flex-col">
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="min-w-full">
              <thead class="border-b">
                <tr>
                  <th
                    scope="col"
                    class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Blockchain
                  </th>
                  <th
                    scope="col"
                    class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Smart contract address
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(collection, index) in collections"
                  :key="collection.id"
                  class="border-b"
                >
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ collection.id }}</td>
                  <td
                    class="text-sm text-gray-900 font-light px-6 py-4"
                    :data-cy="'collection_name_' + index"
                  >
                    {{ collection.name }}
                  </td>
                  <td class="text-sm text-gray-900 font-light px-6 py-4">
                    {{
                      SUPPORTED_CHAIN_NAMES[collection.blockchain]
                    }}
                  </td>
                  <td class="text-sm text-gray-900 font-light px-6 py-4 break-words">
                    {{ collection.smartContractAddress }}
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              v-if="(collectionTotalData !== -1) & !allDataIsLoaded || isLoadingForGetCollection"
              class="text-center my-3"
            >
              Loading...
            </p>
            <div
              v-if="collectionTotalData === 0 && !isLoadingForGetCollection"
              class="text-center text-xl mt-5"
            >
              No data found
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { emitter } from '@/utils/eventBus'
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapMutations, mapState } from 'vuex'
import { CollectionActionTypes, CollectionMutationTypes } from '@/store/modules/admin/collection/type'
import { SUPPORTED_CHAIN_NAMES } from '@/constants'

export default {
  name: 'CollectionPage',
  props: {
    loadMore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      allDataIsLoaded: false,
      SUPPORTED_CHAIN_NAMES
    }
  },
  computed: {
    ...mapState(moduleTypes.APP, ['companyId']),
    ...mapState(moduleTypes.COLLECTION, ['collectionPage', 'collectionTotalData', 'collections', 'isLoadingForGetCollection'])
  },
  watch: {
    loadMore: function () {
      if (this.collectionTotalData === this.collections.length) this.allDataIsLoaded = true
      if (!this.isLoadingForGetCollection && this.collectionTotalData > this.collections.length) {
        this.UPDATE_PAGE_FOR_COLLECTION(this.collectionPage + 1)
        this.getCollections()
      }
    }
  },
  mounted () {
    emitter.on('update_company', () => {
      this.getCollectionsData()
    })
    this.getCollectionsData()
  },
  unmounted () {
    emitter.off('update_company')
  },
  methods: {
    ...mapActions(moduleTypes.COLLECTION, [CollectionActionTypes.getCollections]),
    ...mapMutations(moduleTypes.COLLECTION, [
      CollectionMutationTypes.UPDATE_PAGE_FOR_COLLECTION
    ]),
    getCollectionsData() {
      this.getCollections().then(() => {
        if (this.collectionTotalData === this.collections.length) this.allDataIsLoaded = true
      })
    }
  }
}
</script>
