<template>
  <div class="flex flex-col my-5 px-[10%]">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full">
            <thead class="border-b">
              <tr>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Id
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Collection
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Token
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Transfer At
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="userNfts in usersNftList"
                :key="userNfts.id"
                class="border-b"
              >
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  data-cy="user_nft_id"
                >
                  {{ userNfts.id }}
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4">
                  <p data-cy="user_nft_name">{{ userNfts.collection.name }}</p>
                  <p>{{ userNfts.collection.smartContractAddress.slice(0, 10).concat('...') }}</p>
                </td>

                <td
                  class="text-sm text-gray-900 font-light px-6 py-4 break-words"
                  data-cy="user_nft_token"
                >
                  {{ userNfts.tokenId }}
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 break-words">
                  {{ moment(userNfts.createdAt).format('Do MMMM YYYY, h:mm:ss a') }}
                </td>
                <td class="flex text-sm space-x-4 text-gray-900 font-light px-6 py-4 break-words">
                  <el-button
                    type="primary"
                    :icon="View"
                    @click="
                      viewOpneSea(
                        userNfts.collection.blockchain,
                        userNfts.collection.smartContractAddress,
                        userNfts.tokenId
                      )
                    "
                  >
                    OpenSea
                  </el-button>
                  <el-button
                    v-if="userNfts.txHash != null"
                    type="primary"
                    :icon="View"
                    @click="
                      viewTxScan(
                        userNfts.collection.blockchain,
                        userNfts.txHash,
                      )
                    "
                  >
                    PolyScan
                  </el-button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="(usersTotalData !== -1) & !allDataIsLoaded || isLoadingForGetUsers"
            class="text-center my-3"
          >
            Loading...
          </div>
          <div
            v-if="usersNftList.length == 0 && !isLoadingForGetUsers"
            class="text-center text-xl mt-5"
          >
            No data found
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { shallowRef } from 'vue'
import { emitter } from '@/utils/eventBus'
import { View } from '@element-plus/icons-vue'
import { SUPPORTED_CHAINS } from '@/constants'
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapMutations, mapState } from 'vuex'
import { UserMutationTypes, UserActionTypes } from '@/store/modules/admin/users/type'

export default {
  name: 'UserNftList',
  props: {
    loadMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      SUPPORTED_CHAINS,
      View: shallowRef(View),
      allDataIsLoaded: false
    }
  },
  computed: {
    ...mapState(moduleTypes.USERS, ['usersNftList', 'usersPage', 'usersTotalData', 'isLoadingForGetUsers'])
  },
  watch: {
    loadMore: function () {
      if (this.usersTotalData === this.usersNftList.length) this.allDataIsLoaded = true
      if (!this.isLoadingForGetUsers && this.usersTotalData > this.usersNftList.length) {
        this.UPDATE_PAGE_FOR_USERS(this.usersPage + 1)
        this.getUserNftList(this.$route.params.id)
      }
    }
  },
  mounted() {
    emitter.on('update_company', () => {
      if (this.$route.name != 'AdminUsers') this.getUserNftListData()
    })
    this.getUserNftListData()
  },
  unmounted() {
    emitter.off('update_company')
  },
  methods: {
    ...mapMutations(moduleTypes.USERS, [UserMutationTypes.UPDATE_PAGE_FOR_USERS]),
    ...mapActions(moduleTypes.USERS, [UserActionTypes.getUserNftList]),
    moment(value) {
      return moment(value)
    },
    viewOpneSea(blockchain, smartContractAddr, tokenId) {
      if (blockchain === SUPPORTED_CHAINS.POLYGON) {
        window.open('https://opensea.io/assets/matic' + '/' + smartContractAddr + '/' + tokenId)
      } else if (blockchain === SUPPORTED_CHAINS.POLYGON_MUMBAI) {
        window.open('https://testnets.opensea.io/assets/mumbai/' + smartContractAddr + '/' + tokenId)
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM_SEPOLIA) {
        window.open('https://testnets.opensea.io/assets/sepolia/' + smartContractAddr + '/' + tokenId)
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM) {
        window.open('https://opensea.io/assets/ethereum/' + smartContractAddr + '/' + tokenId)
      }
    },
    viewTxScan(blockchain, txHash) {
      if (blockchain === SUPPORTED_CHAINS.POLYGON) {
        window.open('https://polygonscan.com/tx/' + txHash)
      } else if (blockchain === SUPPORTED_CHAINS.POLYGON_MUMBAI) {
        window.open('https://mumbai.polygonscan.com/tx/' + txHash)
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM_SEPOLIA) {
        window.open('https://sepolia.etherscan.io/tx/' + txHash)
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM) {
        window.open('https://etherscan.io/tx/' + txHash)
      }
    },
    getUserNftListData() {
      this.getUserNftList(this.$route.params.id).then(() => {
        if (this.usersTotalData === this.usersNftList.length) this.allDataIsLoaded = true
      })
    }
  }
}
</script>
