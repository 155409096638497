<template>
  <el-dialog
    center
    top="8vh"
    :show-close="false"
    style="border-radius: 12px"
    :close-on-click-modal="false"
    :model-value="isDialogOpenForCreate"
  >
    <template #header>
      <div class="key-bg">
        <img src="@/assets/img/key_bg.png" />
        <img
          class="close-icon cursor-pointer"
          src="@/assets/img/bg-close.png"
          @click="() => {
            IS_DIALOG_OPEN_FOR_CREATE(false)
          }
          "
        />
      </div>
    </template>
    <div class="content sm:mx-9">
      <p class="sm:text-center text-left text-base mb-5 font-bold">Create Admin</p>
      <h4>Email</h4>
      <el-form
        ref="ruleForm"
        :model="adminForm"
        :rules="rules"
        hide-required-asterisk
        @submit.prevent
      >
        <el-form-item prop="name">
          <el-input
            :model-value="adminForm.email"
            class="h-10"
            :disable="isLoadingForCreateAdmin"
            type="email"
            data-cy="email"
            @input="(value) => {
              UPDATE_FORM_FOR_ADMIN({
                key: 'email',
                value
              })
            }
            "
            @keyup.enter="tonext"
          />
        </el-form-item>
      </el-form>
    </div>
    <p class="sm:mx-9 text-[#f56c6c]">{{ isErrorMessage }}</p>
    <div class="mt-8 flex mobile:flex-col-reverse justify-center items-center sm:space-x-5">
      <el-button
        class="mobile:mt-5"
        @click="() => {
          IS_DIALOG_OPEN_FOR_CREATE(false)
        }
        "
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        data-cy="create_admin_button"
        :loading="isLoadingForCreateAdmin"
        style="background: linear-gradient(90deg, #11ad20 0%, #32dd43 100%)"
        @click="tonext"
      >
        Create
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapMutations, mapState } from 'vuex'
import { CompanyMutationTypes, CompanyActionTypes } from '@/store/modules/admin/company/type'

export default {
  name: 'DialogCreateCompany',
  data () {
    return {
      rules: {
        email: [{ required: true, message: 'Email is required', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState(moduleTypes.APP, ['companyId']),
    ...mapState(moduleTypes.COMPANY, ['adminForm', 'isErrorMessage', 'isDialogOpenForCreate', 'isLoadingForCreateAdmin'])
  },
  methods: {
    ...mapMutations(moduleTypes.COMPANY, [
      CompanyMutationTypes.UPDATE_FORM_FOR_ADMIN,
      CompanyMutationTypes.IS_DIALOG_OPEN_FOR_CREATE
    ]),
    ...mapActions(moduleTypes.COMPANY, [CompanyActionTypes.createAdmin]),
    tonext () {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          this.createAdmin(this.$route.name == 'ManageAdmin' ? this.companyId : this.$route.params.companyId)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.key-bg {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  position: relative;

  .close-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 20px;
    font-style: normal;
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: transparent;
    line-height: 26px;
    color: transparent;
  }

  img {
    width: 100%;
  }
}

.content {
  color: #000;

  h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }
}

/deep/ .el-dialog__footer {
  padding-bottom: 40px;
}

.el-button {
  width: 170px;
  height: 40px;
}

@media (max-width: 576px) {
  .el-button {
    width: 80%;

  }
}
</style>

<style>
.el-dialog__header {
  padding: 0;
  margin-right: 0;
}

.el-dialog {
  width: 622px;
}

.el-button+.el-button {
  margin-left: 0;
}

@media (max-width: 576px) {
  .el-dialog {
    width: 90%;
  }
}
</style>
