import { CoreMutationTypes } from './type'

const state = {
  isConnected: false,
  adminWallet: '',
  selectedChainInMetamask: null,
  companyList: [],
  selecteCompanyName: ''
}

const mutations = {
  [CoreMutationTypes.SET_IS_CONNECTED]: (state, isConnected) => {
    state.isConnected = isConnected
  },
  [CoreMutationTypes.SET_ADMIN_WALLET]: (state, adminWallet) => {
    state.adminWallet = adminWallet
  },
  [CoreMutationTypes.SET_SELECTED_CHAIN_IN_METAMASK]: (state, selectedChainInMetamask) => {
    state.selectedChainInMetamask = selectedChainInMetamask
  },
  [CoreMutationTypes.SET_COMPANY_LIST]: (state, companies) => {
    state.companyList = []
    state.companyList.push(...companies)
  },
  [CoreMutationTypes.SET_SELECT_COMPANY_NAME]: async (state, companyName) => {
    state.selecteCompanyName = companyName
  }
}

export const core = {
  namespaced: true,
  state,
  mutations
}
