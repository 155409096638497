<template>
  <div>
    <div class="px-[10%]">
      <div class="flex justify-between pt-4 pb-8">
        <h2 class="text-3xl font-bold">
          Company ({{ totalData == -1 ? 0 : totalData }})
        </h2>
        <el-button
          size="large"
          data-cy="create_company_button"
          @click="onCreateCompany"
        >
          Create Company
        </el-button>
      </div>

      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 sm:px-6 lg:px-8 inline-block min-w-full">
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="border-b">
                  <tr>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Manage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in companies"
                    :key="index"
                    class="border-b"
                  >
                    <td
                      :data-cy="'company_name_' + index"
                      class="text-sm text-gray-900 font-light px-6 py-4"
                    >
                      {{ item.name }}
                    </td>
                    <td class="text-sm text-gray-900 font-light px-6 py-4">
                      <el-button
                        type="primary"
                        :icon="iconSetUp"
                        circle
                        :data-cy="'manage_admin_' + index"
                        @click="() => {
                          $router.push({ path: `company/${item.id}/admin` })
                        }"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p
                v-if="(totalData !== -1) & !allDataIsLoaded || isLoadingForGetCompany"
                class="text-center my-3"
              >
                Loading...
              </p>
              <div
                v-if="totalData === 0 && !isLoadingForGetCompany"
                class="text-center text-xl mt-5"
              >
                No data found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DialogCreateCompany />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import { emitter } from '@/utils/eventBus'
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapMutations, mapState } from 'vuex'
import DialogCreateCompany from '@/components/AdminCenter/Company/DialogCreateCompany'
import { CompanyActionTypes, CompanyMutationTypes } from '@/store/modules/admin/company/type'
import { SetUp } from '@element-plus/icons-vue'

export default {
  name: 'CompanyPage',
  components: {
    DialogCreateCompany
  },
  props: {
    loadMore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      allDataIsLoaded: false,
      iconSetUp: shallowRef(SetUp)
    }
  },
  computed: {
    ...mapState(moduleTypes.COMPANY, ['page', 'totalData', 'companies', 'isLoadingForGetCompany'])
  },
  watch: {
    loadMore: function () {
      if (this.totalData === this.companies.length) this.allDataIsLoaded = true
      if (!this.isLoadingForGetCompany && this.totalData > this.companies.length) {
        this.UPDATE_PAGE(this.page + 1)
        this.getCompany()
      }
    }
  },
  mounted () {
    emitter.on('update_company', () => {
      this.getCompanyData()
    })
    this.getCompanyData()
  },
  unmounted () {
    emitter.off('update_company')
  },
  methods: {
    ...mapActions(moduleTypes.COMPANY, [
      CompanyActionTypes.getCompany
    ]),
    ...mapMutations(moduleTypes.COMPANY, [
      CompanyMutationTypes.EDIT_COMPANY,
      CompanyMutationTypes.CLEAR_COMPANY,
      CompanyMutationTypes.IS_SHOW_ERROR_MESSAGE,
      CompanyMutationTypes.UPDATE_FORM_FOR_COMPANY,
      CompanyMutationTypes.UPDATE_PAGE,
      CompanyMutationTypes.IS_DIALOG_OPEN_FOR_CREATE
    ]),
    onCreateCompany () {
      this.IS_SHOW_ERROR_MESSAGE('')
      this.UPDATE_FORM_FOR_COMPANY({ key: 'name', value: '' })
      this.UPDATE_FORM_FOR_COMPANY({ key: 'email', value: '' })
      this.IS_DIALOG_OPEN_FOR_CREATE(true)
    },
    onUpdateCompany (index, item) {
      this.EDIT_COMPANY({ index, item })
      this.IS_DIALOG_OPEN_FOR_CREATE(false)
    },
    getCompanyData () {
      this.CLEAR_COMPANY()
      this.getCompany().then(() => {
        if (this.totalData === this.companies.length) this.allDataIsLoaded = true
      })
    }
  }
}
</script>
