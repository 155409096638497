<template>
  <div
    v-if="!isNFTsGetLoading"
    :class="`${!nfts?.length || nfts?.length == 1 ? 'fixed bottom-0':''} footer`"
  >
    <div class="footer-content">
      <!-- <h4 class="text-bold">Terms & Conditions</h4> -->
      <p>Powered by © Carbonbase 2022. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { moduleTypes } from '@/store/modules/type'

export default {
  name: 'FooterNav',
  computed: {
    ...mapState(moduleTypes.DASHBOARD, ['nfts', 'isNFTsGetLoading'])
  }
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  color: #fff;
  overflow: hidden;
  background: #4f4f4f;
  .footer-content {
    width: 80%;
    margin: 30px auto;
    h4 {
      line-height: 18px;
      font-size: 15px;
      margin-bottom: 10px;
    }
    p {
      font-size: 13px;
      line-height: 16px;
    }
  }
}
</style>
