const LoginMutationTypes = {
  UPDATE_FORM: 'UPDATE_FORM',
  LOGIN_RESET_FORM: 'LOGIN_RESET_FORM',
  SET_IS_SHOW_PASSWORD: 'SET_IS_SHOW_PASSWORD',
  SET_IS_FROM_LOGIN: 'SET_IS_FROM_LOGIN',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_IS_LOGIN_LOADING: 'SET_IS_LOGIN_LOADING'
}

const LoginActionTypes = {
  signIn: 'signIn'
}

module.exports = {
  LoginActionTypes,
  LoginMutationTypes
}
