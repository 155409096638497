<template>
  <el-dialog
    width="40%"
    :show-close="false"
    close-on-click-modal
    :model-value="isDialogMintNftVisible"
    :title="`Airdrop nft to ${selectedUserForMintNft?.email}`"
    class="pt-6 pl-6"
    @closed="onCancle"
  >
    <h4 class="my-4">{{ selectedUserForMintNft?.email }}</h4>
    <el-tag class="mb-4">{{ selectedUserForMintNft?.walletAddress }}</el-tag>

    <div class="mb-5 flex relative text-left items-center inline-block">
      <ElementInput
        type="text"
        :value="searchCollectionName"
        label="Collection"
        placeholder="Select Collection"
        data-cy="user_collection_list"
        @input="onSearchCollection"
        @blur="onCloseContextMenu"
        @focus="onOpenContextMenu"
      />
      <Transition name="slide-fade">
        <ContextMenu
          v-if="isShowContextMenu"
          :items="collections"
          :is-loading-for-get-item="isLoadingForGetCollection"
          @scroll="onScroll"
          @click="onUpdateCollection"
        />
      </Transition>
    </div>

    <!-- <el-alert
      v-if="selectedCollection?.blockchain"
      type="error"
      style="padding: 0; height: 38px"
      class="margin-md-bottom"
    >
      Make sure your MM is set to <b>{{ selectedCollection?.blockchain }}</b>
    </el-alert> -->
    <div v-if="selectedCollection?.id">
      Airdrop NFT from
      <b>{{ selectedCollection.name }}</b> to <b>{{ selectedUserForMintNft?.email }}</b>
    </div>
    <br />
    <el-alert
      v-if="mintingProcessStatus.message"
      :title="mintingProcessStatus.message"
      :type="mintingProcessStatus.type"
    />
    <div
      v-if="selectedCollection.availableTokens === 0"
      class="text-red-600"
    >
      No NFT’s left in collection
    </div>
    <template #footer>
      <span class="dialog-footer space-x-4">
        <el-button
          data-cy="cancle_button"
          @click="onCancle"
        >Cancel</el-button>
        <el-button
          data-cy="mint_button"
          :disabled="0 >= selectedCollection.availableTokens"
          :loading="isMintingLoading"
          @click="startMintingProcess"
        >Mint </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapMutations, mapState } from 'vuex'
import ContextMenu from '@/components/Core/ContextMenu.vue'
import ElementInput from '@/components/Core/ElementInput.vue'
import { UserMutationTypes, UserActionTypes } from '@/store/modules/admin/users/type'
import { CollectionActionTypes, CollectionMutationTypes } from '@/store/modules/admin/collection/type'

export default {
  name: 'DialogMintNft',
  components: { ContextMenu, ElementInput },
  data() {
    return {
      isShowContextMenu: false,
      searchCollectionTimeout: null
    }
  },
  computed: {
    ...mapState(moduleTypes.USERS, [
      'isMintingLoading',
      'selectedCollection',
      'searchCollectionName',
      'mintingProcessStatus',
      'selectedUserForMintNft',
      'isDialogMintNftVisible'
    ]),
    ...mapState(moduleTypes.COLLECTION, [
      'collections',
      'collectionPage',
      'collectionTotalData',
      'isLoadingForGetCollection'
    ])
  },

  methods: {
    ...mapMutations(moduleTypes.USERS, [
      UserMutationTypes.UPDATE_SELECTED_COLLECTION,
      UserMutationTypes.UPDATE_SEARCH_COLLECTION_NAME,
      UserMutationTypes.UPDATE_IS_DIALOG_MINT_NFT_VISIBLE
    ]),
    ...mapMutations(moduleTypes.COLLECTION, [
      CollectionMutationTypes.CLEAR_COLLECTION,
      CollectionMutationTypes.UPDATE_PAGE_FOR_COLLECTION,
      CollectionMutationTypes.IS_LOADING_FOR_GET_COLLECTION
    ]),
    ...mapActions(moduleTypes.COLLECTION, [
      CollectionActionTypes.getCollections,
      CollectionActionTypes.searchCollectionInWhitelisted
    ]),
    ...mapActions(moduleTypes.USERS, [UserActionTypes.startMintingProcess]),
    onCancle() {
      this.UPDATE_IS_DIALOG_MINT_NFT_VISIBLE(false)
      this.UPDATE_SELECTED_COLLECTION('')
      this.UPDATE_SEARCH_COLLECTION_NAME('')
    },
    onSearchCollection(event) {
      this.CLEAR_COLLECTION()
      this.IS_LOADING_FOR_GET_COLLECTION(true)
      this.UPDATE_SEARCH_COLLECTION_NAME(event.target.value)
      clearTimeout(this.searchCollectionTimeout)
      this.searchCollectionTimeout = setTimeout(() => {
        if (this.searchCollectionName) {
          this.searchCollectionInWhitelisted(this.searchCollectionName)
        } else {
          this.getCollections()
          this.UPDATE_SELECTED_COLLECTION('')
        }
      }, 1000)
    },
    onScroll(event) {
      const el = event.srcElement
      this.$nextTick(() => {
        if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
          if (!this.isLoadingForGetCollection && this.collectionTotalData > this.collections.length) {
            if (this.searchCollectionName) {
              this.UPDATE_PAGE_FOR_COLLECTION(this.collectionPage + 1)
              this.searchCollectionInWhitelisted(this.searchCollectionName)
            } else {
              this.UPDATE_PAGE_FOR_COLLECTION(this.collectionPage + 1)
              this.getCollections()
            }
          }
        }
      })
    },
    onUpdateCollection(value) {
      this.UPDATE_SELECTED_COLLECTION(value)
      this.UPDATE_SEARCH_COLLECTION_NAME(this.selectedCollection.name)
      this.isShowContextMenu = false
    },
    onCloseContextMenu() {
      setTimeout(() => {
        this.isShowContextMenu = false
      }, 150)
    },
    onOpenContextMenu() {
      this.isShowContextMenu = true
      this.getCollections()
    }
  }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.el-dialog__body{
   padding: 0;
}
</style>
