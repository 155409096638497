<template>
  <el-dialog
    center
    top="8vh"
    :show-close="false"
    style="border-radius: 12px"
    :close-on-click-modal="false"
    :model-value="isDialogOpenForRevealApiKey"
  >
    <template #header>
      <div class="key-bg">
        <img src="@/assets/img/key_bg.png" />
        <img
          class="close-icon cursor-pointer"
          src="@/assets/img/bg-close.png"
          @click="
            IS_DIALOG_OPEN_FOR_API_KEY(false)
          "
        />
      </div>
    </template>
    <div class="content sm:mx-9">
      <p
        class="sm:text-center text-left text-base mb-5 font-bold"
        data-cy="reveal_api_key_title"
      >
        Reveal Api Key
      </p>
      <div class="warning-tips sm:flex sm:justify-start	sm:items-start	">
        <div class="mobile:flex mobile:justify-center">
          <img
            class="w-6"
            src="@/assets/img/warning-flag.png"
          />
        </div>
        <div class="mobile:pt-4">
          <p>Reminder: Do NOT share your Api Key with others.</p>
        </div>
      </div>
      <!-- Your Private Key -->
      <h4>Your Api Key</h4>
      <div class="private-key">
        <div
          class="desc"
          data-cy="reveal_api_key"
        >
          {{ isLoadingForGetapiKey ? 'Loading' : form.apiKey }}
        </div>
        <div class="flex justify-center items-center">
          <div
            id="copybtn"
            class="btn"
            :data-clipboard-text="form.apiKey"
            @click="copy"
          >
            <img
              src="@/assets/img/icon-copy.svg"
              alt=""
            />
            <span>Copy to clipboard</span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8 flex justify-center items-center">
      <el-button
        data-cy="close_reveal_dialog_button"
        @click="IS_DIALOG_OPEN_FOR_API_KEY(false)"
      >
        Cancel
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import Clipboard from 'clipboard'
import { mapMutations, mapState } from 'vuex'
import { moduleTypes } from '@/store/modules/type'
import { ApiMutationTypes } from '@/store/modules/admin/apikey/type'

export default {
  name: 'RevealApiKey',

  computed: {
    ...mapState(moduleTypes.APIKEY, ['form', 'isDialogOpenForRevealApiKey', 'isLoadingForGetapiKey'])
  },
  methods: {
    ...mapMutations(moduleTypes.APIKEY, [ApiMutationTypes.IS_DIALOG_OPEN_FOR_API_KEY]),
    copy() {
      const clipboard = new Clipboard('#copybtn')
      clipboard.on('success', (e) => {
        this.$message.success('Copied!')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message('Copy Failed!')
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>
<style lang="less" scoped>
.key-bg {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  position: relative;

  .close-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 20px;
    font-style: normal;
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: transparent;
    line-height: 26px;
    color: transparent;
  }

  img {
    width: 100%;
  }
}

.content {
  color: #000;

  h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }

  .warning-tips {
    background: #ffe8ed;
    border: 1px solid #fc1642;
    border-radius: 5px;
    padding: 16px 20px;
    margin-bottom: 20px;

    img {
      margin-right: 10px;
      margin-top: -1px;
    }

    p {
      line-height: 25px;
      color: #fc1642;
      font-weight: 600;
    }
  }

  .private-key {
    .desc {
      padding: 12px 22px;
      border: 1px solid #979797;
      border-radius: 3px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 14px;
      color: #000;
    }

    .btn {
      width: 100%;
      height: 44px;
      background: #ffffff;
      border: 1px solid rgba(130, 130, 130, 0.8);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #3aa845;

      span {
        padding-left: 14px;
      }

      &:hover {
        background-color: rgba(58, 168, 69, 0.5);
        color: #fff;
        border-color: rgba(58, 168, 69, 0.5);
      }
    }
  }
}

/deep/ .el-dialog__footer {
  padding-bottom: 40px;
}

.el-button {
  width: 100%;
  height: 40px;
  margin: 0px 2.25rem;
}
</style>

<style>
.el-dialog__header {
  padding: 0;
  margin-right: 0;
}

.el-dialog {
  width: 622px;
}

@media (max-width: 576px) {
  .el-dialog {
    width: 90%;
  }
}
</style>
