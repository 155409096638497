<template>
  <div>
    <header-nav class="sm:hidden" />
    <HomePageBackgroundImage />
    <div class="sm:absolute mobile:mt-14 sm:top-8 sm:inset-x-20">
      <HomePageBackground />
      <div
        class="
              py-9
              px-14
              w-full
              absolute
              sm:top-60
              login-wrap
              rounded-3xl
              opacity-100
              sm:w-[520px]
              mobile:h-[700px]
              backdrop-blur-xs
              shadow-[5px 15px 30px rgb(0 0 0 /30%)]
            "
      >
        <h3 class="font-nova tracking-tight text-white font-semibold text-2xl mobile:text-xl mobile:text-black mobile:animate-bounce-name mobile:text-primary">
          Hey welcome here!
        </h3>
        <p
          class="pt-2 sm:h-20 text-sm tracking-tight font-nova font-light leading-5 text-primaryTextColor mobile:text-black"
        >
          Thanks for joining in the event! Please create your account to
          redeem your NFT.
        </p>
        <el-form
          ref="ruleForm"
          :rules="rules"
          :model="signUpForm"
          label-position="top"
          hide-required-asterisk
        >
          <el-form-item
            prop="email"
          >
            <label
              class="py-2 font-extralight text-xs text-white"
              for="Email"
            >Email</label>
            <div class="flex w-full items-center">
              <el-input
                v-model="signUpForm.email"
                data-cy="signup_email"
                type="text"
                size="large"
                autocomplete="off"
                @focus="focusEmail"
                @input="() => {
                  SET_ERROR_MESSAGE({ showEmail: false, showEmailError: '' })
                }
                "
                @blur="checkExistEmail"
              />
              <Transition name="slide-fade">
                <div
                  v-if="errorMessage.showEmail"
                  class="box arrow-left"
                >
                  <p>Email</p>
                  <p class="text-sm text-[#606266]">{{ errorMessage.showEmailError }}</p>
                </div>
              </Transition>
            </div>
          </el-form-item>
          <el-form-item
            prop="password"
          >
            <label
              class="py-2 font-extralight text-xs text-white"
              for="Create your password"
            >Create your password</label>
            <el-input
              v-model="signUpForm.password"
              data-cy="signup_password"
              size="large"
              show-password
              autocomplete="new-password"
              :type="isPasswordVisible ? 'text' : 'password'"
            >
              <img
                :src="require(`@/assets/img/${isPasswordVisible ? 'show' : 'hide'}.svg`)"
                class="icon-eyes pointer"
                @click="SET_IS_PASSWORD_VISIBLE(!isPasswordVisible)"
              />
            </el-input>
          </el-form-item>
          <el-form-item
            prop="confirmPassword"
          >
            <label
              class="py-2 font-extralight text-xs text-white"
              for="Confirm your password"
            >Confirm your password</label>
            <el-input
              v-model="signUpForm.confirmPassword"
              data-cy="confirm_password"
              size="large"
              show-password
              autocomplete="new-password"
              :type="isConfirmPasswordVisible ? 'text' : 'password'"
            >
              <img
                :src="require(`@/assets/img/${isConfirmPasswordVisible ? 'show' : 'hide'}.svg`)"
                class="icon-eyes pointer "
                @click="SET_IS_CONFIRM_PASSWORD_VISIBLE(!isConfirmPasswordVisible)"
              />
            </el-input>
          </el-form-item>
          <el-button
            round
            size="large"
            type="primary"
            data-cy="signup_button"
            :loading="isSignUpLoading"
            class="mt-5 w-full"
            :disabled="!signUpForm.email || !signUpForm.password || !signUpForm.confirmPassword || confirmPasswordError || errorMessage.showEmail
            "
            @click="onSubmit"
          >
            <span> Sign up </span>
          </el-button>
          <p
            v-if="userExists"
            class="pt-2"
            data-cy="user_exists"
          >
            The user already exists. Please go to log in.
          </p>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { moduleTypes } from '@/store/modules/type'
import headerNav from '@/components/nav/headerNav'
import { mapActions, mapMutations, mapState } from 'vuex'
import { emailValidator, trimValidator } from '@/utils/validator'
import HomePageBackground from '@/components/HomePageBackground.vue'
import HomePageBackgroundImage from '@/components/HomePageBackgroundImage.vue'
import { SignUpMutationTypes, SignUpActionTypes } from '@/store/modules/common/signUp/type'
export default {
  components: { HomePageBackground, HomePageBackgroundImage, headerNav },

  data() {
    var pass2Validator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the password again'))
      } else if (value !== this.signUpForm.password) {
        this.SET_CONFIRM_PASSWORD_ERROR_MESSAGE(true)
        callback(new Error('Please match the password with the field above'))
      } else {
        this.SET_CONFIRM_PASSWORD_ERROR_MESSAGE(false)
        callback()
      }
    }
    return {
      rules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          { trigger: 'blur', validator: emailValidator }
        ],
        password: [
          { required: true, message: 'Password is required', trigger: 'blur' },
          { validator: trimValidator, trigger: 'blur' },
          { min: 8, max: 15, message: '8 to 15 characters', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: 'Confirm Password is required', trigger: 'blur' },
          { validator: trimValidator, trigger: 'blur' },
          { min: 8, max: 15, message: '8 to 15 characters', trigger: 'blur' },
          { validator: pass2Validator, trigger: 'blur' }
        ]
      }
    }
  },

  computed: {
    ...mapState(moduleTypes.SIGNUP, ['signUpForm', 'isPasswordVisible', 'isConfirmPasswordVisible', 'confirmPasswordError', 'errorMessage', 'userExists', 'isSignUpLoading'])
  },
  mounted() {
    this.SIGNUP_RESET_FORM()
  },
  methods: {
    ...mapMutations(moduleTypes.SIGNUP, [
      SignUpMutationTypes.SET_ERROR_MESSAGE,
      SignUpMutationTypes.SIGNUP_RESET_FORM,
      SignUpMutationTypes.UPDATE_USER_EXISTS,
      SignUpMutationTypes.SET_IS_PASSWORD_VISIBLE,
      SignUpMutationTypes.SET_CONFIRM_PASSWORD_ERROR_MESSAGE,
      SignUpMutationTypes.SET_IS_CONFIRM_PASSWORD_VISIBLE
    ]),
    ...mapActions(moduleTypes.SIGNUP, [SignUpActionTypes.signUp, SignUpActionTypes.userExist]),
    onSubmit() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          if (
            this.errorMessage.showEmailError !=
            'Unidentified email address.<br />Using the Email address that you used for registering the event.' &&
            this.confirmPasswordError == false
          ) {
            const { email, password } = this.signUpForm
            this.signUp({ email, password })
          }
          // this.$emit('onsubmit', this.signUpForm)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    focusEmail() {
      if (!this.signUpForm.email) {
        this.SET_ERROR_MESSAGE({ showEmail: true, showEmailError: 'Using the Email address that you used for registering the event.' })
      }
      this.UPDATE_USER_EXISTS(false)
    },
    async checkExistEmail() {
      this.SET_ERROR_MESSAGE({ showEmail: false, showEmailError: '' })
      if (this.signUpForm.email) this.userExist()
    }
  }
}
</script>

<style lang="less" scoped>
ul.error-tips {
  padding: 10px 0;

  li {
    i {
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #000;
      line-height: 25px;
      margin-bottom: 3px;
      margin-right: 14px;
    }
  }
}

.el-button--large.is-round {
  padding: 26px 19px;
  background: #01D564 0% 0% no-repeat padding-box;
}

.el-button.is-disabled {
  background: #a8bc9f;
}

.box {
  width: 300px;
  height: 85px;
  background-color: white;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  position: absolute;
  z-index: 10;
  left: 418px;
  border-radius: 4px;
  border:1px solid #e4e7ed;
}

.box.arrow-left:after {
  content: " ";
  position: absolute;
  left: -5px;
  top: 35px;
  border-top: 7px solid transparent;
  border-right: 6px solid white;
  border-left: none;
  border-bottom: 7px solid transparent;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
