import router from '@/router'
import { Auth } from 'aws-amplify'
import AuthService from '@/service/AuthService'
import { SignUpMutationTypes, SignUpActionTypes } from './type'
const authService = new AuthService()

const state = {
  signUpForm: {
    email: '',
    password: '',
    confirmPassword: ''
  },
  userExists: false,
  isSignUpLoading: false,
  isPasswordVisible: false,
  isConfirmPasswordVisible: false,
  errorMessage: {
    showEmail: false,
    showEmailError: ''
  },
  confirmPasswordError: false
}

const mutations = {
  [SignUpMutationTypes.UPDATE_FORM]: (state, { key, value }) => {
    state.signUpForm[key] = value
  },
  [SignUpMutationTypes.SET_IS_SIGNUP_LOADING]: (state, isSignUpLoading) => {
    state.isSignUpLoading = isSignUpLoading
  },
  [SignUpMutationTypes.SET_ERROR_MESSAGE]: (state, { showEmail, showEmailError }) => {
    state.errorMessage = {
      showEmail, showEmailError
    }
  },
  [SignUpMutationTypes.SET_CONFIRM_PASSWORD_ERROR_MESSAGE]: (state, confirmPasswordError) => {
    state.confirmPasswordError = confirmPasswordError
  },
  [SignUpMutationTypes.UPDATE_USER_EXISTS]: (state, userExists) => {
    state.userExists = userExists
  },
  [SignUpMutationTypes.SET_IS_PASSWORD_VISIBLE]: (state, isPasswordVisible) => {
    state.isPasswordVisible = isPasswordVisible
  },
  [SignUpMutationTypes.SET_IS_CONFIRM_PASSWORD_VISIBLE]: (state, isConfirmPasswordVisible) => {
    state.isConfirmPasswordVisible = isConfirmPasswordVisible
  },
  [SignUpMutationTypes.SIGNUP_RESET_FORM]: (state) => {
    state.signUpForm = {
      email: '',
      password: '',
      confirmPassword: ''
    }
    state.confirmPasswordError = false
    state.errorMessage = {
      showEmail: false,
      showEmailError: ''
    }
  }

}

const actions = {
  [SignUpActionTypes.signUp]: async ({ commit }, { email, password }) => {
    commit(SignUpMutationTypes.SET_IS_SIGNUP_LOADING, true)
    try {
      await Auth.signUp(email, password)
      commit(SignUpMutationTypes.UPDATE_FORM, { key: 'email', value: email })
      commit(SignUpMutationTypes.UPDATE_FORM, { key: 'password', value: password })
      router.push({ name: 'Verify' })
    } catch (error) {
      if (error['code'] === 'UsernameExistsException') {
        commit(SignUpMutationTypes.UPDATE_USER_EXISTS, true)
      }
      console.log('error signing up:', error)
    }
    commit(SignUpMutationTypes.SET_IS_SIGNUP_LOADING, false)
  },

  [SignUpActionTypes.userExist]: async ({ commit, state }) => {
    try {
      const response = await authService.userExist({
        queryStringParameters: {
          email: state.signUpForm.email
        }
      })
      if (!response.isUserExist) {
        commit(SignUpMutationTypes.SET_ERROR_MESSAGE, { showEmail: true, showEmailError: 'Unidentified email address.\nUsing the Email address that you used for registering the event.' })
      }
    } catch (error) {
      console.log('error userExists:', error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
