<template>
  <div>
    <header-nav class="sm:hidden" />
    <HomePageBackgroundImage />
    <div class="sm:absolute mobile:mt-14 sm:top-8 sm:inset-x-20">
      <HomePageBackground />
      <div
        class="
          py-9
          px-14
          absolute
          sm:top-72
          login-wrap
          rounded-3xl
          opacity-100
          sm:w-[520px]
          mobile:h-[700px]
          backdrop-blur-xs
          shadow-[5px 15px 30px rgb(0 0 0 /30%)]
        "
      >
        <h3 class="font-nova text-white font-semibold text-2xl mobile:text-xl mobile:text-black mobile:animate-bounce-name mobile:text-primary">Hey welcome here!</h3>
        <p class="pt-2 h-20 text-base	font-nova leading-5 text-primaryTextColor mobile:text-black">The confirmation code has been sent to your email, please enter it to redeem your NFT.</p>
        <el-alert
          v-if="resendConfirmationCodeAlert.type"
          :title="resendConfirmationCodeAlert.message"
          :type="resendConfirmationCodeAlert.type"
        />
        <el-form
          ref="ruleForm"
          class="pt-2"
          :rules="rules"
          :model="verifyUserForm"
          label-position="top"
          hide-required-asterisk
        >
          <el-form-item
            prop="confirmationCode"
            :error="errorMessage"
          >
            <label
              class="py-2 font-extralight text-xs text-white"
              for="Confirmation code"
            >Confirmation code</label>
            <el-input
              :model-value="verifyUserForm.confirmationCode"
              type="text"
              data-cy="confirm_code"
              autocomplete="off"
              size="large"
              @input="(value) => {
                UPDATE_CONFIRMATION_CODE({
                  key: 'confirmationCode',
                  value: value
                })
              }
              "
            />
          </el-form-item>
          <br />

          <div class="flex items-center space-x-4">
            <el-button
              size="large"
              :loading="isResendConfirmationCodeLoading"
              @click="() => {
                resendConfirmationCode({ email: (isFromLogin ? loginForm.email : signUpForm.email), isFromLogin })
              }
              "
            >
              Resend
            </el-button>
            <el-button
              :loading="isVerifyUserLoading"
              class="button-login"
              type="primary"
              size="large"
              data-cy="confirm_button"
              @click.prevent="onVerify"
            >
              <img
                :src="require('@/assets/img/icon-view.svg')"
                alt=""
              />
              <span class="ml-2"> Redeem my NFT </span>
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { trimValidator } from '@/utils/validator'
import headerNav from '@/components/nav/headerNav'
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapMutations, mapState } from 'vuex'
import HomePageBackground from '@/components/HomePageBackground.vue'
import HomePageBackgroundImage from '@/components/HomePageBackgroundImage.vue'
import { VerifyUserMutationTypes, VerifyUserActionTypes } from '@/store/modules/common/verifyUser/type'

export default defineComponent({
  name: 'VerifyUser',
  components: { HomePageBackground, HomePageBackgroundImage, headerNav },
  data() {
    return {
      rules: {
        confirmationCode: [
          { required: true, message: 'Confirmation code is required', trigger: 'blur' },
          { trigger: 'blur', validator: trimValidator }
        ]
      }
    }
  },
  computed: {
    ...mapState(moduleTypes.VERIFY_USER, [
      'errorMessage',
      'verifyUserForm',
      'isVerifyUserLoading',
      'resendConfirmationCodeAlert',
      'isResendConfirmationCodeLoading'
    ]),
    ...mapState(moduleTypes.LOGIN, ['loginForm', 'isFromLogin']),
    ...mapState(moduleTypes.SIGNUP, ['signUpForm'])
  },
  mounted() {
    if (
      (!this.loginForm.email || !this.loginForm.password) &&
      (!this.signUpForm.email || !this.signUpForm.password)
    ) { this.$router.push('/login') }
    this.RESET_FORM()
    if (this.isFromLogin) {
      this.resendConfirmationCode({
        email: this.loginForm.email,
        isFromLogin: this.isFromLogin
      })
    } else {
      this.resendConfirmationCode({
        email: this.signUpForm.email,
        isFromLogin: this.isFromLogin
      })
    }
  },
  methods: {
    ...mapMutations(moduleTypes.VERIFY_USER, [VerifyUserMutationTypes.UPDATE_CONFIRMATION_CODE, VerifyUserMutationTypes.RESET_FORM]),
    ...mapActions(moduleTypes.VERIFY_USER, [VerifyUserActionTypes.resendConfirmationCode, VerifyUserActionTypes.verifyConfirmationCode]),
    onVerify() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          if (this.isFromLogin) {
            this.verifyConfirmationCode({
              email: this.loginForm.email,
              password: this.loginForm.password
            })
          } else {
            this.verifyConfirmationCode({
              email: this.signUpForm.email,
              password: this.signUpForm.password
            })
          }
        }
      })
    }
  }
})
</script>

<style lang="less" scoped>
.tips {
  line-height: 17px;
  padding-top: 6px;
  height: 75px;
}

ul.error-tips {
  padding: 10px 0;

  li {
    i {
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #000;
      line-height: 25px;
      margin-bottom: 3px;
      margin-right: 14px;
    }
  }
}
.el-button--primary{
  background: #01D564 0% 0% no-repeat padding-box;
}
</style>
