<template>
  <div>
    <header-nav class="sm:hidden" />
    <HomePageBackgroundImage />
    <div class="sm:absolute mobile:mt-14 sm:top-8 sm:inset-x-20 z-20">
      <HomePageBackground />
      <div
        class="
            py-9
            px-14
            w-full
            absolute
            sm:top-72
            login-wrap
            rounded-3xl
            opacity-100
            sm:w-[520px]
            mobile:h-[600px]
            backdrop-blur-xs
            shadow-[5px 15px 30px rgb(0 0 0 /30%)]
          "
      >
        <h3
          class="font-nova tracking-tight text-white font-semibold text-2xl mobile:text-xl mobile:text-black mobile:animate-bounce-name mobile:text-primary"
        >
          Log in to your
          account
        </h3>
        <p class="pt-3 leading-5 sm:hidden">
          Please create your new password and enter the confirmation code to reset your password.
        </p>
        <el-form
          ref="ruleForm"
          class="mt-10"
          :rules="rules"
          :model="loginForm"
          label-position="top"
          hide-required-asterisk
        >
          <el-form-item prop="email">
            <label
              class="py-2 font-extralight text-xs text-white"
              for="Email"
            >Email</label>
            <el-input
              v-model="loginForm.email"
              size="large"
              data-cy="login_email"
              type="text"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item prop="password">
            <label
              class="py-2 font-extralight text-xs text-white"
              for="Password"
            >Password</label>
            <el-input
              v-model="loginForm.password"
              size="large"
              show-password
              class="relative"
              autocomplete="off"
              data-cy="login_password"
              :type="isPasswordVisible ? 'text' : 'password'"
            >
              <img
                :src="require(`@/assets/img/${isPasswordVisible ? 'show' : 'hide'}.svg`)"
                class="icon-eyes pointer absolute top-0 right-0"
                @click="SET_IS_SHOW_PASSWORD(!isPasswordVisible)"
              />
            </el-input>
          </el-form-item>
          <div class="text-right text-gray-300 mobile:text-[15px]">
            <router-link
              :underline="false"
              class="forget-link"
              to="/recover"
            >
              forgot password
            </router-link>
          </div>
          <el-button
            round
            size="large"
            :loading="isLoginLoading"
            data-cy="login_button"
            class="w-full mt-5"
            @click.prevent="onSubmit"
          >
            <img :src="require('@/assets/img/icon-view.svg')" />
            <span class="ml-2">View my NFT</span>
          </el-button>
          <div class="pt-2 text-center text-red-500">
            <p>
              {{ errorMessage }}
            </p>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { emailValidator } from '@/utils/validator'
import headerNav from '@/components/nav/headerNav'
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapState, mapMutations } from 'vuex'
import HomePageBackground from '@/components/HomePageBackground.vue'
import HomePageBackgroundImage from '@/components/HomePageBackgroundImage.vue'
import { LoginActionTypes, LoginMutationTypes } from '@/store/modules/common/login/type'

export default {
  name: 'LoginPage',
  components: { HomePageBackground, HomePageBackgroundImage, headerNav },
  data() {
    return {
      rules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          { trigger: 'blur', validator: emailValidator }
        ],
        password: [{ required: true, message: 'Password is required', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState(moduleTypes.LOGIN, ['loginForm', 'isPasswordVisible', 'errorMessage', 'isLoginLoading'])
  },
  mounted() {
    this.LOGIN_RESET_FORM()
  },
  methods: {
    ...mapMutations(moduleTypes.LOGIN, [LoginMutationTypes.SET_IS_SHOW_PASSWORD, LoginMutationTypes.LOGIN_RESET_FORM]),
    ...mapActions(moduleTypes.LOGIN, [LoginActionTypes.signIn]),
    onSubmit() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          this.signIn({ email: this.loginForm.email, password: this.loginForm.password })
        }
      })
    }
  }
}
</script>

<style scoped>
.el-button--large.is-round {
  padding: 26px 19px;
  background: #01D564 0% 0% no-repeat padding-box;
  color: #ffffff;
}
.el-input__inner {
  color: #828282;
}
</style>
