import { payGasFeeForTransfer } from '@/utils/MintNFTs'
import { UserActionTypes, UserMutationTypes } from './type'
import UserService from '@/service/UserService'
const userService = new UserService()
import WhiteListedService from '@/service/WhitelistedService'
const whiteListedService = new WhiteListedService()

const state = {
  users: [],
  usersNftList: [],

  // for dialog mint nft
  isDialogMintNftVisible: false,
  /**
    * @typedef User
    * @type {object}
    * @property {string} User.email - email of user.
    * @property {string} User.walletAddr - wallet address of user.
    */
  selectedUserForMintNft: {},
  /**
   * @typedef Collection
   * @type {object}
   * @property {string} Collection.id - id of collection
   * @property {string} Collection.smartContractAddr - smart contract address of collection
   */
  selectedCollection: '',
  /**
   * @typedef MintingProcessStatus
   * @type {object}
   * @property {string} MintingProcessStatus.type - alert type.
   * @property {string} MintingProcessStatus.message - alert message
   * */
  mintingProcessStatus: {
    type: 'info', // info | error
    message: null
  },
  isMintingLoading: false,
  usersPage: 1,
  usersTotalData: -1,
  isLoadingForGetUsers: false,
  searchCollectionName: ''
}

const mutations = {
  [UserMutationTypes.SET_USER_LIST]: (state, user) => {
    state.users.push(...user)
  },
  [UserMutationTypes.SET_USER_NFT_LIST]: (state, user) => {
    state.usersNftList.push(...user)
  },

  [UserMutationTypes.UPDATE_SEARCH_COLLECTION_NAME]: (state, searchCollectionName) => {
    state.searchCollectionName = searchCollectionName
  },
  [UserMutationTypes.UPDATE_PAGE_FOR_USERS]: (state, page) => {
    state.usersPage = page
  },
  [UserMutationTypes.UPDATE_TOTAL_USER_DATA]: (state, totalData) => {
    state.usersTotalData = totalData
  },
  [UserMutationTypes.CLEAR_USERS]: (state) => {
    state.usersPage = 1
    state.users = []
    state.usersNftList = []
    state.usersTotalData = -1
  },
  [UserMutationTypes.IS_LOADING_FOR_GET_USERS]: (state, loading) => {
    state.isLoadingForGetUsers = loading
  },

  [UserMutationTypes.UPDATE_IS_DIALOG_MINT_NFT_VISIBLE]: (state, isDialogMintNftVisible) => {
    if (!isDialogMintNftVisible) { state.selectedUserForMintNft = {} }
    state.isDialogMintNftVisible = isDialogMintNftVisible
  },
  [UserMutationTypes.SET_SELECTED_USER_FOR_MINT_NFT]: (state, user) => {
    state.selectedUserForMintNft = user
  },
  [UserMutationTypes.UPDATE_SELECTED_COLLECTION]: (state, selectedCollection) => {
    state.selectedCollection = selectedCollection
  },
  [UserMutationTypes.UPDATE_MINTING_PROCESS_STATUS]: (state, { type, message }) => {
    state.mintingProcessStatus.type = type
    state.mintingProcessStatus.message = message
  },
  [UserMutationTypes.UPDATE_IS_MINTING_LOADING]: (state, isMintingLoading) => {
    state.isMintingLoading = isMintingLoading
  }
}

const actions = {
  [UserActionTypes.startMintingProcess]: async ({ state, commit, dispatch }) => {
    const nftId = []
    commit(UserMutationTypes.UPDATE_IS_MINTING_LOADING, true)
    commit(UserMutationTypes.UPDATE_MINTING_PROCESS_STATUS, {
      type: 'info',
      message: 'Start minting: metamask will open to approve transaction'
    })

    const user = await whiteListedService.createNftTransfer({ walletAddress: state.selectedUserForMintNft.walletAddress, collectionId: state.selectedCollection.id })
    nftId.push(user.nft.id)

    try {
      const txHash = await dispatch(UserActionTypes.payGasFeeForTransaction, user)
      console.log(txHash)
      await whiteListedService.updateNftTransfer({ txHash: txHash, ids: nftId })

      commit(UserMutationTypes.UPDATE_MINTING_PROCESS_STATUS, {
        type: 'info',
        message: null
      })
      commit(UserMutationTypes.UPDATE_IS_DIALOG_MINT_NFT_VISIBLE, false)
    } catch (e) {
      console.log(e)
      await whiteListedService.deleteNftTransfer({ ids: nftId })
      commit(UserMutationTypes.UPDATE_MINTING_PROCESS_STATUS, {
        type: 'error',
        message: 'Failed to mint. Try again'
      })
    }

    commit(UserMutationTypes.UPDATE_IS_MINTING_LOADING, false)
  },
  [UserActionTypes.getAllUsers]: async ({ commit, state }) => {
    commit(UserMutationTypes.IS_LOADING_FOR_GET_USERS, true)
    try {
      const response = await userService.getAllUsers(state.usersPage)
      commit(UserMutationTypes.SET_USER_LIST, response.users)
      commit(UserMutationTypes.UPDATE_TOTAL_USER_DATA, response.totalData)
    } catch (e) {
      console.log('Error:-', e)
    }
    commit(UserMutationTypes.IS_LOADING_FOR_GET_USERS, false)
  },
  [UserActionTypes.searchUser]: async ({ commit, state }, search) => {
    commit(UserMutationTypes.IS_LOADING_FOR_GET_USERS, true)
    try {
      const response = await userService.searchUser(search, state.usersPage)
      commit(UserMutationTypes.SET_USER_LIST, response.users)
      commit(UserMutationTypes.UPDATE_TOTAL_USER_DATA, response.totalData)
    } catch (e) {
      console.log('Error:-', e)
    }
    commit(UserMutationTypes.IS_LOADING_FOR_GET_USERS, false)
  },
  [UserActionTypes.getUserNftList]: async ({ commit, state }, userId) => {
    commit(UserMutationTypes.IS_LOADING_FOR_GET_USERS, true)
    try {
      const response = await userService.getUserNftList(state.usersPage, userId)
      commit(UserMutationTypes.SET_USER_NFT_LIST, response.nfts)
      commit(UserMutationTypes.UPDATE_TOTAL_USER_DATA, response.totalData)
    } catch (e) {
      console.log('Error:-', e)
    }
    commit(UserMutationTypes.IS_LOADING_FOR_GET_USERS, false)
  },
  [UserActionTypes.payGasFeeForTransaction]: async ({ state }, user) => {
    const txHash = await payGasFeeForTransfer({
      walletAddresses: [user.walletAddress],
      smartContractAddress: state.selectedCollection.smartContractAddress,
      window
    })
    return txHash.transactionHash
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
