import { API } from 'aws-amplify'
import serviceName from './index'
import { ErrorWrapper } from './util'

const route = `${process.env.VUE_APP_NODE_ENV !== 'development' ? '/private' : ''}/user`

export default class UserServices {
  async getAllUsers (page) {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}?page=${page}&size=20`)
    } catch (e) {
      throw new ErrorWrapper(e)
    }
  }

  async getPrivateKey () {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/private-key`)
    } catch (e) {
      throw new ErrorWrapper(e)
    }
  }

  async searchUser (searchName, page) {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/search?email=${searchName}&page=${page}&size=20`)
    } catch (e) {
      throw new ErrorWrapper(e)
    }
  }

  async getUserNftList (page, id) {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${process.env.VUE_APP_NODE_ENV !== 'development' ? '/private' : ''}/nft/by-user?page=${page}&size=20&userId=${id}`)
    } catch (e) {
      throw new ErrorWrapper(e)
    }
  }

  async createAdmin (data) {
    try {
      return await API.post(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/admin`, {
        body: data
      })
    } catch (e) {
      throw new ErrorWrapper(e)
    }
  }

  async getAdminsByCompany (page, companyId) {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/admin?page=${page}&size=20&companyId=${companyId}`)
    } catch (e) {
      throw new ErrorWrapper(e)
    }
  }

  async removeAdminFromCompany (profileId) {
    try {
      return await API.del(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}/admin?id=${profileId}`)
    } catch (e) {
      throw new ErrorWrapper(e)
    }
  }
}
