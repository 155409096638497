<template>
  <div class="px-[10%] pt-6 ">
    <el-form
      ref="ruleFormRef"
      :model="form"
      :rules="rules"
      label-width="120px"
      label-position="top"
    >
      <el-form-item
        label="Name"
        prop="name"
      >
        <el-input
          size="large"
          data-cy="collection_name"
          :model-value="form.name"
          @input="(value) => {
            UPDATE_FORM({
              key: 'name',
              value
            })
          }
          "
        />
      </el-form-item>

      <el-form-item
        label="Description"
        prop="description"
      >
        <el-input
          :model-value="form.description"
          type="textarea"
          data-cy="collection_description"
          :autosize="{ minRows: 2, maxRows: 4 }"
          @input="(value) => {
            UPDATE_FORM({
              key: 'description',
              value
            })
          }
          "
        />
      </el-form-item>

      <b>Benefits</b>
      <el-form-item prop="benefits">
        <div
          v-for="(benefit, index) in form.benefits"
          :key="`benefit${index}`"
          class="flex w-full"
          :style="index === 0 ? 'margin-top: 15px' : ''"
        >
          <el-input
            size="large"
            :model-value="benefit"
            data-cy="collection_benefits"
            @input="(value) => {
              UPDATE_FORM_BENEFIT({
                value,
                index
              })
            }
            "
          />
          <el-button
            size="large"
            :data-cy="`delete_benefits_button${index}`"
            @click.prevent="DELETE_FORM_DYNAMIC_ITEM({ key: 'benefits', index })"
          >
            Delete
          </el-button>
        </div>
      </el-form-item>
      <el-button
        size="default"
        data-cy="add_benefits_button"
        @click.prevent="ADD_FORM_DYNAMIC_ITEM({ key: 'benefits' })"
      >
        Add benefit
      </el-button>

      <br />
      <br /><br />
      <b>Attributes</b>
      <el-form-item prop="attributes">
        <div
          v-for="(attribute, index) in form.attributes"
          :key="`attribute${index}`"
          class="flex w-full"
          :style="index === 0 ? 'margin-top: 15px' : ''"
        >
          <el-input
            size="large"
            class="mr-2.5"
            data-cy="collection_attributes_source"
            :model-value="index === 0 ? (attribute.key = 'source') : attribute.key"
            :disabled="attribute.key == 'source'"
            @input="(value) => {
              UPDATE_FORM_ATTRIBUTE({
                key: 'key',
                index,
                value
              })
            }
            "
          />
          <el-input
            size="large"
            :model-value="attribute.value"
            data-cy="collection_attributes_value"
            @input="(value) => {
              UPDATE_FORM_ATTRIBUTE({
                key: 'value',
                index,
                value
              })
            }
            "
          />
          <el-button
            :disabled="attribute.key == 'source'"
            :data-cy="`delete_attributes_button${index}`"
            size="large"
            @click.prevent="DELETE_FORM_DYNAMIC_ITEM({ key: 'attributes', index })"
          >
            Delete
          </el-button>
        </div>
      </el-form-item>
      <el-button
        data-cy="add_attributes_button"
        @click.prevent="ADD_FORM_DYNAMIC_ITEM({ key: 'attributes' })"
      >
        Add attribute
      </el-button>

      <br /><br /><br />
      <el-form-item label="Upload">
        <el-radio-group
          :model-value="form.mode"
          @change="(value) => {
            UPDATE_FORM({
              key: 'mode',
              value
            })
          }
          "
        >
          <el-radio :label="COLLECTION_DEPLOYMENT_MODE.REPLICATION"> Replication mode </el-radio>
          <el-radio
            data-cy="collection_image_mode"
            :label="COLLECTION_DEPLOYMENT_MODE.SIMPLE"
          >
            Simple mode
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="mb-8">
        <ImageUploadReplicationMode v-if="form.mode === COLLECTION_DEPLOYMENT_MODE.REPLICATION" />
        <ImageUploadSimpleMode v-if="form.mode === COLLECTION_DEPLOYMENT_MODE.SIMPLE" />
      </div>

      <el-form-item
        v-if="form.mode === COLLECTION_DEPLOYMENT_MODE.REPLICATION"
        label="Number of total nfts you want to generate"
        prop="totalNfts"
      >
        <el-input-number
          :model-value="form.totalNfts"
          data-cy="collection_totalNfts"
          :min="1"
          :max="200"
          @change="(value) => {
            UPDATE_FORM({ key: 'totalNfts', value })
          }
          "
        />
      </el-form-item>

      <el-alert
        v-if="deployCollectionProcess.message"
        :title="deployCollectionProcess.message"
        :type="deployCollectionProcess.type"
        :closable="false"
      />

      <br />
      <div class="space-x-3">
        <el-button
          size="large"
          type="primary"
          :loading="isDeployCollectionLoading"
          data-cy="create_collection_button"
          @click="onSubmit"
        >
          Create
        </el-button>
        <el-button
          size="large"
          :disabled="isDeployCollectionLoading"
          @click="RESET_FORM"
        >
          Reset
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { moduleTypes } from '@/store/modules/type'
import { COLLECTION_DEPLOYMENT_MODE } from '@/constants'
import { mapActions, mapMutations, mapState } from 'vuex'
import ImageUploadSimpleMode from '@/components/AdminCenter/Collection/ImageUploadSimpleMode'
import { CollectionMutationTypes, CollectionActionTypes } from '@/store/modules/admin/collection/type'
import ImageUploadReplicationMode from '@/components/AdminCenter/Collection/ImageUploadReplicationMode'

export default {
  name: 'AddCollection',
  components: { ImageUploadReplicationMode, ImageUploadSimpleMode },
  data () {
    return {
      COLLECTION_DEPLOYMENT_MODE,
      rules: {
        name: [{ required: true, message: 'Please enter name', trigger: 'blur' }],
        description: [{ required: true, message: 'Please enter description', trigger: 'blur' }],
        benefits: [{ type: 'array', required: true, message: 'Please enter at least one benefit', trigger: 'change' }],
        attributes: [
          { type: 'array', required: true, message: 'Please enter at least one attribute', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState(moduleTypes.COLLECTION, ['form', 'deployCollectionProcess', 'isDeployCollectionLoading'])
  },
  mounted () {
    this.RESET_FORM()
  },
  methods: {
    ...mapMutations(moduleTypes.COLLECTION, [
      CollectionMutationTypes.UPDATE_FORM,
      CollectionMutationTypes.RESET_FORM,
      CollectionMutationTypes.UPDATE_FORM_BENEFIT,
      CollectionMutationTypes.UPDATE_FORM_ATTRIBUTE,
      CollectionMutationTypes.ADD_FORM_DYNAMIC_ITEM,
      CollectionMutationTypes.DELETE_FORM_DYNAMIC_ITEM
    ]),
    ...mapActions(moduleTypes.COLLECTION, [CollectionActionTypes.deployCollection]),
    onSubmit () {
      this.$refs['ruleFormRef'].validate(async (valid) => {
        console.log('valid', valid)
        if (valid) {
          this.deployCollection()
        }
      })
    }
  }
}
</script>
