import { API } from 'aws-amplify'
import serviceName from './index'

const route = 'auth/user'

export default class AuthServices {
  async getCurrentUser() {
    try {
      const res = await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${process.env.VUE_APP_NODE_ENV !== 'development' ? '/private' : ''}/${route}/current`)
      return res
    } catch (e) {
      throw new Error(e)
    }
  }
  async createUser() {
    try {
      const res = await API.post(serviceName.CONFERENCE_NFTS_RESTAPI, `${process.env.VUE_APP_NODE_ENV !== 'development' ? '/private' : ''}/${route}/create`)
      return res
    } catch (e) {
      console.log('Error :-', e)
    }
  }
  async userExist(queryStringParameters) {
    try {
      const res = await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${process.env.VUE_APP_NODE_ENV !== 'development' ? '/public' : ''}/${route}/exist`, queryStringParameters)
      return res
    } catch (e) {
      console.log('Error :-', e)
    }
  }
}
