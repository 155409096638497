<template>
  <div class="px-[10%] mt-9 mb-24">
    <!-- adress -->
    <div class="flex justify-between items-center mobile:hidden">
      <div>
        <h3>Your wallet address</h3>
        <p
          class="mt-2.5 text-sm"
          data-cy="walletAddress"
        >
          {{ currentUser.walletAddress }}
        </p>
      </div>
      <div
        class="relative"
        :loading="isNFTsGetLoading"
      >
        <el-button
          class="btn"
          data-cy="private_key_btn"
          @click="IS_DIALOG_OPEN_FOR_PRIVATE_KEY(true)"
        >
          Reveal Private Key
        </el-button>
        <span class="question">?</span>
        <div class="my-popup">
          <div class="triangle"></div>
          A private key is a large, randomly-generated number with hundreds of digits to access to your wallet. For
          simplicity, they are usually represented as strings of alphanumeric characters. Click on the button to view
          your private key.
        </div>
      </div>
    </div>
    <!-- gallery -->
    <h3 class="mt-16">Your NFT gallery</h3>

    <div class="nft-gallery">
      <p>All({{ nfts.length || 0 }})</p>
    </div>

    <!-- list -->
    <div
      v-loading="isNFTsGetLoading && nfts.length == 0"
      class="text-sm content-wrap"
    >
      <div
        v-if="!nfts?.length && !isNFTsGetLoading"
        class="flex justify-center"
      >
        <el-empty description="You don't have NFT's yet" />
      </div>
      <div
        v-for="(item, index) in nfts"
        :key="index"
        class="flex list mobile:justify-center"
      >
        <div
          v-loading="item.error"
          class="
          flex
          g-card
          w-[266px]
          items-center
          sm:mr-[60px]
          sm:h-[346px]
          justify-center
          mobile:h-[-webkit-fill-available]
          mobile:w-[-webkit-fill-available]
          "
          @click="onClickImage(index)"
        >
          <!-- <div class="flex flex-center items-middle"> -->
          <div v-if="!item.error">
            <el-image
              style="height: 310px"
              fit="contain"
              :src="item.image"
            />
            <p class="p-5 text-base font-bold	sm:hidden">{{ item.metadata.name }}</p>
          </div>
        </div>
        <div class="mobile:hidden mt-3.5">
          <div
            class="grid grid-cols-4 gap-4 mb-6"
            data-cy="collection_name"
          >
            <div class="col-span-1 flex items-center">
              <div>
                <img
                  :src="require('@/assets/img/gallery-icon/ic_outline-collections.svg')"
                  alt=""
                />
              </div>
              <span class="pl-2 text-primaryText"> Collection Name</span>
            </div>
            <span
              v-if="item.collection != null"
              class="col-span-3 flex items-center"
            >{{
              item.collection.name
            }}</span>
          </div>

          <div
            class="grid grid-cols-4 gap-4 mb-6"
            data-cy="description"
          >
            <div class="col-span-1 flex items-center">
              <div>
                <img
                  :src="require('@/assets/img/gallery-icon/ic_outline-description.svg')"
                  alt=""
                />
              </div>
              <span class="pl-2 text-primaryText"> Description</span>
            </div>
            <el-tooltip
              v-if="item.metadata != null"
              :disabled="item.description.length < 300"
              effect="customized"
            >
              <template #content>
                <span>{{ item.description }}</span>
              </template>
              <span class="col-span-3  text-ellipsis overflow-hidden flex">{{ item.description.length > 300 ?
                item.description.slice(0, 300).concat('...') : item.description }}</span>
            </el-tooltip>
          </div>

          <div
            class="grid grid-cols-4 gap-4 mb-6"
            data-cy="title"
          >
            <div class="col-span-1 flex items-center">
              <div>
                <img
                  :src="require('@/assets/img/nft-title.svg')"
                  alt=""
                />
              </div>
              <span class="pl-2 text-primaryText"> Title</span>
            </div>
            <span class="col-span-3 flex items-center"> {{ item.metadata.name }}</span>
          </div>

          <div
            class="grid grid-cols-4 gap-4 mb-6"
            data-cy="token_id"
          >
            <div class="col-span-1 flex items-center">
              <div>
                <img
                  :src="require('@/assets/img/gallery-icon/ic_outline-token.svg')"
                  alt=""
                />
              </div>
              <span class="pl-2 text-primaryText"> Token ID</span>
            </div>
            <span class="col-span-3 flex items-center"> {{ item.tokenId }}</span>
          </div>

          <div
            class="grid grid-cols-4 gap-4 mb-6"
            data-cy="blockchain"
          >
            <div class="col-span-1 flex items-center">
              <div>
                <img
                  :src="require('@/assets/img/gallery-icon/simple-icons_hive-blockchain.svg')"
                  alt=""
                />
              </div>
              <span class="pl-2 text-primaryText"> Blockchain</span>
            </div>
            <span
              v-if="item.collection != null"
              class="col-span-3 flex items-center"
            >{{
              SUPPORTED_CHAIN_NAMES[item.collection.blockchain]
            }}</span>
          </div>

          <div
            class="grid grid-cols-4 gap-4 mb-6"
            data-cy="source"
          >
            <div class="col-span-1 flex items-center">
              <div>
                <img
                  :src="require('@/assets/img/gallery-icon/Union.svg')"
                  alt=""
                />
              </div>
              <span class="pl-2 text-primaryText"> Source</span>
            </div>
            <span class="col-span-3 flex items-center">{{ item.source }}</span>
          </div>

          <div class="g-bottom flex">
            <el-button
              class="btn btn2"
              style="width: 351px; background: #75c43c"
              :data-cy="'opensea_button_' + index"
              @click="viewOpneSea(item.collection.blockchain, item.collection.smartContractAddress, item.tokenId)"
            >
              <img :src="require('@/assets/img/gallery-icon/opensea.svg')" />
              <span class="pl-3.5">View it on OpenSea</span>
            </el-button>

            <el-popover
              placement="bottom-end"
              width="634"
              trigger="hover"
            >
              <div class="benefits-content">
                <!-- <i class="el-icon-close" @click="item.showBenefits = false"></i> -->
                <div class="mb-3 text-base">
                  Benefits
                </div>
                <div v-if="item.collection">
                  <div
                    v-for="(benefit, benefitIndex) in item.collection.benefits"
                    :key="benefitIndex"
                    v-html="`${benefitIndex + 1}. ${benefit}`"
                  >
                  </div>
                </div>
                <div v-else>
                  No benefits available.
                </div>
              </div>
              <template #reference>
                <el-button
                  plain
                  style="width: 225px; height: 42px"
                  class="font-medium"
                  @click="item.showBenefits = !item.showBenefits"
                >
                  Benefits
                </el-button>
              </template>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <!-- Reveal Private Key -->
    <RevealPrivateKey @click="closeDialog" />
  </div>
</template>

<script>
import router from '@/router'
import { SUPPORTED_CHAINS } from '@/constants'
import { moduleTypes } from '@/store/modules/type'
import { SUPPORTED_CHAIN_NAMES } from '@/constants'
import { mapActions, mapMutations, mapState } from 'vuex'
import RevealPrivateKey from '@/components/RevealPrivateKey'
import { DashboardActionTypes, DashboardMutationTypes } from '@/store/modules/common/dashboard/type'

export default {
  name: 'NftDashboard',
  components: { RevealPrivateKey },
  data() {
    return {
      SUPPORTED_CHAINS,
      windowWidth: window.innerWidth,
      SUPPORTED_CHAIN_NAMES
    }
  },
  computed: {
    ...mapState(moduleTypes.APP, ['currentUser']),
    ...mapState(moduleTypes.DASHBOARD, ['nfts', 'isNFTsGetLoading'])
  },
  mounted() {
    this.IS_SHOW_HEADER_LEFT_ARROW(false)
    this.getOwnerNFTs()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapMutations(moduleTypes.DASHBOARD,
      [
        DashboardMutationTypes.IS_SHOW_HEADER_LEFT_ARROW,
        DashboardMutationTypes.IS_DIALOG_OPEN_FOR_PRIVATE_KEY
      ]),
    ...mapActions(moduleTypes.DASHBOARD, [DashboardActionTypes.getOwnerNFTs]),
    onResize() {
      this.windowWidth = window.innerWidth
    },
    viewOpneSea(blockchain, smartContractAddr, tokenId) {
      if (blockchain === SUPPORTED_CHAINS.POLYGON) {
        window.open('https://opensea.io/assets/matic' + '/' + smartContractAddr + '/' + tokenId, '_blank')
      } else if (blockchain === SUPPORTED_CHAINS.POLYGON_MUMBAI) {
        window.open('https://testnets.opensea.io/assets/mumbai/' + smartContractAddr + '/' + tokenId, '_blank')
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM_SEPOLIA) {
        window.open('https://testnets.opensea.io/assets/sepolia/' + smartContractAddr + '/' + tokenId, '_blank')
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM) {
        window.open('https://opensea.io/assets/ethereum/' + smartContractAddr + '/' + tokenId, '_blank')
      }
    },
    closeDialog() {
      this.IS_DIALOG_OPEN_FOR_PRIVATE_KEY(false)
    },
    onClickImage(index) {
      if (this.windowWidth < 578) { router.push({ path: `/nft/details/${index}` }) }
    }
  }
}
</script>

<style lang="less">
.grid-content {
  min-height: 36px;
}

.square {
  width: 25px;
  text-align: text;
  display: inline-block;
  padding-right: 8px;
}

.color-light {
  color: #828282;
  display: inline-block;
  width: 122px;
  flex-shrink: 0;
}

.color-dark {
  line-height: 22px;
}

.label-flex {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.benefits-content {
  margin: 8px 15px;
  position: relative;
  // white-space: pre-line;
  font-size: 15px;
  line-height: 150%;
  color: #4a4a4a;
  word-break: break-word;
  text-align: left;

  a {
    color: #3aa845;

    &:hover {
      text-decoration: underline;
    }
  }

  .el-icon-close {
    position: absolute;
    right: -10px;
    top: -5px;
    font-size: 22px;
    color: #000;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
<style lang="less" scoped>
h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #3aa845;
}

.btn {
  width: 286px;
  height: 42px;
  font-weight: 700;
  background: linear-gradient(90deg, #11ad20 0%, #32dd43 100%);
  border-radius: 5px;
  color: #fff;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &.btn2 {
    font-weight: 400;
    padding: 0;
    line-height: 42px;
    margin-right: 48px;
  }
}

.question {
  width: 44px;
  height: 44px;
  background: #bdbdbd;
  border-radius: 50%;
  margin-left: 13px;
  color: #fff;
  text-align: center;
  line-height: 44px;
  display: inline-block;
  font-size: 19px;

  &:hover {
    opacity: 0.8;

    &+div {
      display: block;
    }
  }
}

.my-popup {
  display: none;
  position: absolute;
  right: 0;
  top: 57px;
  width: 412px;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 18px;
  background: #e7fbd1;
  border: 1px solid #7bb551;
  box-shadow: 0 0 2px #eee;
  border-radius: 5px;

  .triangle {
    left: 95%;
    top: -10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #7bb551;

    &::before {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 9px solid #e7fbd1;
    }
  }
}

/deep/ .el-tabs--card>.el-tabs__header {
  border: none;

  .el-tabs__nav {
    border: none;
  }

  .el-tabs__item {
    border: 1px solid #828282;

    &.is-active {
      background: #e7fbd1;
      border: 1px solid #7bb551;
    }

    &:hover {
      border: 1px solid #7bb551;
    }
  }
}

/deep/ .el-tabs__item {
  border-radius: 30px;
  margin-right: 30px;
  min-width: 110px;
  text-align: center;
  font-size: 17px;
  // color: #000;
}

.content-wrap {
  margin-top: 35px;

  .list {
    margin-bottom: 30px;

    .g-card {
      border: 1px solid #ddd;
      border-radius: 15px;
      flex-shrink: 0;
      box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15);

      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        box-shadow: 0 2px 4px #bdbdbd;
        border-color: #bdbdbd;

        .el-image {
          transform: scale(1.03);
        }
      }
    }
  }
}

.nft-gallery {
  width: 110px;
  font-size: 17px;
  font-weight: 500;
  padding: 12px;
  margin-top: 15px;
  text-align: center;
  border-radius: 30px;
  background: #E7FBD1;
  border: 1px solid #7BB551;

  p {
    color: #7BB551;
  }
}
</style>
<style >
.el-popper.is-customized {
  background: white;
  width: 750px;
  border: 1px solid black;
}

.el-popper.is-customized .el-popper__arrow::before {
  background: white;
  right: 0;
  border: 1px solid black;
}
</style>
