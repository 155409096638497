const ApiMutationTypes = {
  UPDATE_FORM: 'UPDATE_FORM',
  SET_API_KEYS: 'SET_API_KEYS',
  CLEAR_API_KEY: 'CLEAR_API_KEY',
  DELETE_API_KEY: 'DELETE_API_KEY',
  SUBMIT_API_KEYS: 'SUBMIT_API_KEYS',
  IS_SHOW_ERROR_MESSAGE: 'IS_SHOW_ERROR_MESSAGE',
  UPDATE_PAGE_FOR_API_KEY: 'UPDATE_PAGE_FOR_API_KEY',
  UPDATE_TOTAL_API_KEY_DATA: 'UPDATE_TOTAL_API_KEY_DATA',
  IS_DIALOG_OPEN_FOR_CREATE: 'IS_DIALOG_OPEN_FOR_CREATE',
  IS_LOADING_FOR_GET_API_KEY: 'IS_LOADING_FOR_GET_API_KEY',
  IS_DIALOG_OPEN_FOR_API_KEY: 'IS_DIALOG_OPEN_FOR_API_KEY'
}

const ApiActionTypes = {
  getApiKey: 'getApiKey',
  createApiKey: 'createApiKey',
  deleteApiKey: 'deleteApiKey',
  reGenerateApiKey: 'reGenerateApiKey'
}

module.exports = {
  ApiActionTypes,
  ApiMutationTypes
}
