import _ from 'lodash'
import Web3 from 'web3'
import axios from 'axios'
import router from '@/router'
import { moduleTypes } from '@/store/modules/type'
import { CollectionActionTypes, CollectionMutationTypes } from './type'
import { COLLECTION_DEPLOYMENT_MODE, SUPPORTED_CHAINS } from '@/constants'
import CollectionService from '@/service/CollectionService'
import { ethers } from 'ethers'
const collectionService = new CollectionService()

const state = {
  form: {
    mode: COLLECTION_DEPLOYMENT_MODE.REPLICATION,
    name: '',
    description: '',
    attributes: [
      { key: '', value: '' }
    ],
    benefits: [''],
    nfts: '',
    totalNfts: 1
  },
  deployCollectionProcess: {
    message: null,
    type: 'success'
  },
  isDeployCollectionLoading: false,
  collections: [],
  collectionPage: 1,
  collectionTotalData: -1,
  isLoadingForGetCollection: false
}

const mutations = {
  [CollectionMutationTypes.UPDATE_FORM]: async (state, { key, value }) => {
    if (key === 'nfts') {
      const nfts = []

      const files = Object.values(value)

      if (files.length > 200) {
        alert('Maximum file size exceeded: Upto 200 files are allowed.')
        return
      }

      const fetchImage = (file) => {
        return new Promise((resolve, _) => {
          const reader = new FileReader()
          reader.onload = (e) => {
            nfts.push({ file: file, image: e.target.result, title: '' })
            resolve()
          }
          reader.readAsDataURL(file)
        })
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        await fetchImage(file)
      }

      state.form[key] = nfts
      state.form.totalNfts = nfts.length
    } else {
      state.form[key] = value
    }
  },
  [CollectionMutationTypes.UPDATE_FORM_NFTS_TITLE]: (state, { index, value }) => {
    const nft = {
      title: value,
      image: state.form.nfts[index].image,
      file: state.form.nfts[index].file
    }
    state.form.nfts.splice(index, 1, nft)
  },
  [CollectionMutationTypes.SET_COLLECTIONS]: (state, collections) => {
    state.collections.push(...collections)
  },
  [CollectionMutationTypes.UPDATE_FORM_BENEFIT]: (state, { value, index }) => {
    state.form.benefits.splice(index, 1, value)
  },
  [CollectionMutationTypes.UPDATE_FORM_ATTRIBUTE]: (state, { key, value, index }) => {
    const attribute = _.cloneDeep(state.form.attributes[index])
    attribute[key] = value
    state.form.attributes.splice(index, 1, attribute)
  },
  [CollectionMutationTypes.ADD_FORM_DYNAMIC_ITEM]: (state, { key }) => {
    if (key === 'benefits') state.form[key].push('')
    if (key === 'attributes') state.form[key].push({ key: '', value: '' })
  },
  [CollectionMutationTypes.DELETE_FORM_DYNAMIC_ITEM]: (state, { key, index }) => {
    state.form[key].splice(index, 1)
  },
  [CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS]: (state, { message, type }) => {
    state.deployCollectionProcess = { message, type }
  },
  [CollectionMutationTypes.RESET_FORM]: (state) => {
    state.form = {
      name: '',
      description: '',
      attributes: [
        { key: '', value: '' }
      ],
      benefits: [''],
      nfts: '',
      totalNfts: 1,
      mode: COLLECTION_DEPLOYMENT_MODE.REPLICATION
    }
    state.deployCollectionProcess = {
      message: null,
      type: 'success'
    }
    state.errorMessage = ''
  },

  [CollectionMutationTypes.UPDATE_PAGE_FOR_COLLECTION]: (state, page) => {
    state.collectionPage = page
  },
  [CollectionMutationTypes.UPDATE_TOTAL_COLLECTON_DATA]: (state, totalData) => {
    state.collectionTotalData = totalData
  },
  [CollectionMutationTypes.CLEAR_COLLECTION]: (state) => {
    state.collectionPage = 1
    state.collections = []
    state.collectionTotalData = -1
  },
  [CollectionMutationTypes.IS_LOADING_FOR_GET_COLLECTION]: (state, loading) => {
    state.isLoadingForGetCollection = loading
  }
}

const actions = {
  [CollectionActionTypes.deployCollection]: async ({ state, commit, dispatch }) => {
    if (state.form.benefits.includes('')) {
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, { message: 'Please ensure that benefit is not empty', type: 'error' })
      return
    } else if (state.form.attributes.findIndex((attribute) => attribute.key.toLowerCase() === 'source' && attribute.value != '') === -1) {
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, { message: 'Please add source in attributes', type: 'error' })
      return
    } else if (state.form.attributes.find(attribute => attribute.key === '' && attribute.value == '')) {
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, { message: 'Please ensure that attribute is not empty', type: 'error' })
      return
    } else if (state.form.nfts.length === 0 || state.form.nfts === '') {
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, { message: 'Please select nft image', type: 'error' })
      return
    } else {
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, { message: '', type: '' })
      const signedUrls = await dispatch(CollectionActionTypes.generateSignedUrlForUpload)
      await dispatch(CollectionActionTypes.uploadImagesToS3, signedUrls)
      const ipfsHashOfImages = await dispatch(CollectionActionTypes.uploadImagesToPinata)
      console.log('ipfsHashOfImages', ipfsHashOfImages)
      const ipfsHashOfMetadata = await dispatch(CollectionActionTypes.uploadMetadataToPinata, ipfsHashOfImages)
      console.log('ipfsHashOfMetadata', ipfsHashOfMetadata)
      const smartContractAddress = await dispatch(CollectionActionTypes.deploySmartContract, ipfsHashOfMetadata)
      console.log('smartContractAddress', smartContractAddress)
      await dispatch(CollectionActionTypes.createCollectionInDB, smartContractAddress)
    }
  },
  [CollectionActionTypes.generateSignedUrlForUpload]: async ({ commit, state }) => {
    try {
      // TODO: update collection name to name
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
        message: 'Preparing nfts for upload to IPFS...',
        type: 'info'
      })

      return await collectionService.generateSignedUrlForUpload({
        queryStringParameters: {
          totalNfts: state.form.totalNfts,
          collectionName: state.form.name,
          ext: state.form.nfts[0].file.name.split('.').pop()
        }
      })
    } catch (e) {
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
        message: `Preparing nfts for upload to IPFS failed: ${e.message}`,
        type: 'error'
      })
    }
  },
  [CollectionActionTypes.uploadImagesToS3]: async ({ commit, state }, signedUrls) => {
    try {
      for (let i = 0; i < signedUrls.length; i++) {
        commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
          message: `Uploading images to IPFS... ${i}/${signedUrls.length}`,
          type: 'info'
        })
        await axios.put(signedUrls[i].signedUrl, state.form.nfts[state.form.mode === COLLECTION_DEPLOYMENT_MODE.REPLICATION ? 0 : i].file)
      }
    } catch (error) {
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
        message: `Uploading images to IPFS is failed: ${error.message}`,
        type: 'error'
      })
    }
  },
  [CollectionActionTypes.uploadImagesToPinata]: async ({ commit, state }) => {
    try {
      // TODO: update collection name to name
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
        message: 'Uploading images to IPFS... Generating IPFS hash',
        type: 'info'
      })
      const response = await collectionService.uploadImagesToPinata({
        collectionName: state.form.name,
        totalNfts: state.form.totalNfts,
        ext: state.form.nfts[0].file.name.split('.').pop()
      }
      )
      return response.body.IpfsHash
    } catch (e) {
      console.log('error-images', e)
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
        message: `Uploading images to IPFS... Generating IPFS hash is failed: ${e.message}`,
        type: 'error'
      })
    }
  },
  [CollectionActionTypes.uploadMetadataToPinata]: async ({ commit, state }, ipfsHashOfImages) => {
    // TODO: update collection name to name
    try {
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
        message: 'Uploading metadata to IPFS... Generating IPFS hash',
        type: 'info'
      })
      const attributes = []
      state.form.attributes.forEach(attribute => {
        attributes.push({ 'trait_type': attribute.key, 'value': attribute.value })
      })

      const nfts = []
      if (state.form.mode === COLLECTION_DEPLOYMENT_MODE.SIMPLE) {
        state.form.nfts.forEach(nft => {
          nfts.push(nft.title)
        })
      } else if (state.form.mode === COLLECTION_DEPLOYMENT_MODE.REPLICATION) {
        for (let i = 0; i < state.form.totalNfts; i++) {
          nfts.push(`${state.form.nfts[0].title} - #${i}`)
        }
      }

      const response = await collectionService.uploadMetadataToPinata({
        collectionName: state.form.name,
        ipfsHashOfImages,
        description: state.form.description,
        ext: state.form.nfts[0].file.name.split('.').pop(),
        nftsTitle: nfts,
        attributes
      }
      )
      return response.body.IpfsHash
    } catch (e) {
      console.error(e)
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
        message: `Uploading metadata to IPFS... Generating IPFS hash is failed: ${e.message}`,
        type: 'error'
      })
    }
  },
  [CollectionActionTypes.deploySmartContract]: async ({ commit, state, dispatch }, ipfsHashOfMetadata) => {
    // eslint-disable-next-line
    return new Promise(async (resolve) => {
      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
        message: 'Generating smart contract...',
        type: 'info'
      })

      const response = await collectionService.deploySmartContract({
        name: state.form.name.replace(/ /gi, ''),
        ipfsHashOfMetadata
      }
      )

      const ABI = response.body.ABI
      const bytecode = response.body.bytecode

      commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
        message: 'Start deploying: metamask will open to approve transaction',
        type: 'info'
      })

      const smartContractAddress = await dispatch(CollectionActionTypes.openMetaMaskForContractDeployment, { ABI, bytecode })
      resolve(smartContractAddress)
    })
  },
  [CollectionActionTypes.createCollectionInDB]: async ({ commit, state, rootState }, smartContractAddress) => {
    commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
      message: 'Creating collection in database...',
      type: 'info'
    })
    const selectedChainInMetamask = rootState[moduleTypes.CORE].selectedChainInMetamask
    if (
      selectedChainInMetamask === SUPPORTED_CHAINS.POLYGON ||
      selectedChainInMetamask === SUPPORTED_CHAINS.POLYGON_MUMBAI ||
      selectedChainInMetamask === SUPPORTED_CHAINS.ETHEREUM ||
      selectedChainInMetamask === SUPPORTED_CHAINS.ETHEREUM_SEPOLIA
    ) {
      await collectionService.createCollection({
        name: state.form.name,
        description: state.form.description,
        benefits: state.form.benefits,
        smartContractAddress: smartContractAddress,
        ownerWalletAddress: rootState[moduleTypes.CORE].adminWallet,
        blockchain: selectedChainInMetamask,
        totalTokens: state.form.totalNfts
      })
      router.push({ path: '/admin/collections' })
    }
  },
  [CollectionActionTypes.getCollections]: async ({ commit, state }) => {
    if (state.collectionPage === 1) commit(CollectionMutationTypes.CLEAR_COLLECTION)
    commit(CollectionMutationTypes.IS_LOADING_FOR_GET_COLLECTION, true)
    try {
      const response = await collectionService.getCollection(state.collectionPage, 20, false)
      commit(CollectionMutationTypes.SET_COLLECTIONS, response.collections)
      commit(CollectionMutationTypes.UPDATE_TOTAL_COLLECTON_DATA, response.totalData)
    } catch (error) {
      console.log('Error:-', error)
    }
    commit(CollectionMutationTypes.IS_LOADING_FOR_GET_COLLECTION, false)
  },
  [CollectionActionTypes.searchCollectionInWhitelisted]: async ({ commit, state }, searchCollectionName) => {
    commit(CollectionMutationTypes.IS_LOADING_FOR_GET_COLLECTION, true)
    try {
      const response = await collectionService.searchCollectionInWhitelisted(searchCollectionName, state.collectionPage)
      commit(CollectionMutationTypes.SET_COLLECTIONS, response.collections)
      commit(CollectionMutationTypes.UPDATE_TOTAL_COLLECTON_DATA, response.totalData)
    } catch (error) {
      console.log('Error:-', error)
    }
    commit(CollectionMutationTypes.IS_LOADING_FOR_GET_COLLECTION, false)
  },
  [CollectionActionTypes.openMetaMaskForContractDeployment]: async ({ commit, rootState }, { ABI, bytecode }) => {
    return new Promise((resolve) => {
      const web3 = new Web3(window.ethereum)
      const contract = new web3.eth.Contract(ABI)

      web3.eth.getAccounts().then(async (accounts) => {
        if (accounts.length > 0) {
          const account = accounts[0]
          commit(CollectionMutationTypes.UPDATE_DEPLOY_COLLECTION_PROCESS, {
            message: 'Start deploying: waiting for contract to be deployed',
            type: 'info'
          })

          const selectedChainInMetamask = rootState[moduleTypes.CORE].selectedChainInMetamask
          if (selectedChainInMetamask === SUPPORTED_CHAINS.POLYGON || selectedChainInMetamask === SUPPORTED_CHAINS.POLYGON_MUMBAI) {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const gasPrice = ethers.BigNumber.from(Math.floor(await provider.getGasPrice() * 1.5))

            contract
              .deploy({ data: bytecode })
              .send({ from: account, gasPrice: gasPrice.toHexString() })
              .on('receipt', (receipt) => {
                resolve(receipt.contractAddress)
              })
          } else {
            contract
              .deploy({ data: bytecode })
              .send({
                from: account,
                gas: '5000000'
              })
              .on('receipt', (receipt) => {
                resolve(receipt.contractAddress)
              })
          }
        }
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
