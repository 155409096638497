<template>
  <el-drawer
    size="auto"
    direction="btt"
    title="My profile"
    :before-close="handleClose"
    class="mx-6 rounded-t-2xl"
    :model-value="isShowProfileDrawer"
  >
    <p class="pb-5 px-5"> {{ currentUser?.email }}</p>
    <div class="p-5 space-y-3 border-y border-solid border-borderColor">
      <p class="font-bold">Your wallet address</p>
      <p class="break-words">{{ currentUser.walletAddress }}</p>
    </div>
    <div class="p-5 flex justify-between items-center border-b border-solid border-borderColor">
      <p
        class="font-bold cursor-pointer"
        @click="
          () => {
            $emit('revealPrivateKey');
          }
        "
      >
        Reveal Private Key
      </p>
      <div>
        <el-popover
          width="auto"
          title="Title"
          trigger="click"
          placement="top"
          popper-class="break-normal"
          content="A private key is a large, randomly-generated number with hundreds of digits to access to   your wallet. For simplicity, they are usually represented as strings of alphanumeric characters.Click on the button to view your private key."
        >
          <template #reference>
            <img src="@/assets/img/questions_mark.png" />
          </template>
        </el-popover>
      </div>
    </div>
    <p
      class="p-5 font-bold"
      @click="
        () => {
          $emit('signOut');
        }
      "
    >
      Log out
    </p>
  </el-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { moduleTypes } from '@/store/modules/type'
import { DashboardMutationTypes } from '@/store/modules/common/dashboard/type'
export default {
  name: 'ElementDrawer',
  emits: ['revealPrivateKey', 'signOut'],
  computed: {
    ...mapState(moduleTypes.APP, ['currentUser']),
    ...mapState(moduleTypes.DASHBOARD, ['isShowProfileDrawer'])
  },
  methods: {
    ...mapMutations(moduleTypes.DASHBOARD, [
      DashboardMutationTypes.IS_SHOW_PROFILE_DRAWER
    ]),
    handleClose() {
      this.IS_SHOW_PROFILE_DRAWER(false)
    }
  }
}
</script>

<style>
.el-drawer__body {
  padding: 0 !important;
}
.el-drawer__title{
  color: #000000;
}
.el-drawer.btt{
  width: 89% !important;
}
.el-popper {
  word-break: normal !important;
}
</style>
