const UserMutationTypes = {
  SET_USER_LIST: 'SET_USER_LIST',
  SET_USER_NFT_LIST: 'SET_USER_NFT_LIST',

  UPDATE_IS_DIALOG_MINT_NFT_VISIBLE: 'UPDATE_IS_DIALOG_MINT_NFT_VISIBLE',
  SET_SELECTED_USER_FOR_MINT_NFT: 'SET_SELECTED_USER_FOR_MINT_NFT',
  UPDATE_SELECTED_COLLECTION: 'UPDATE_SELECTED_COLLECTION',
  UPDATE_MINTING_PROCESS_STATUS: 'UPDATE_MINTING_PROCESS_STATUS',
  UPDATE_IS_MINTING_LOADING: 'UPDATE_IS_MINTING_LOADING',

  UPDATE_PAGE_FOR_USERS: 'UPDATE_PAGE_FOR_USERS',
  CLEAR_USERS: 'CLEAR_USERS',
  UPDATE_TOTAL_USER_DATA: 'UPDATE_TOTAL_USER_DATA',
  IS_LOADING_FOR_GET_USERS: 'IS_LOADING_FOR_GET_USERS',
  UPDATE_SEARCH_COLLECTION_NAME: 'UPDATE_SEARCH_COLLECTION_NAME'
}

const UserActionTypes = {
  searchUser: 'searchUser',
  getAllUsers: 'getAllUsers',
  getUserNftList: 'getUserNftList',
  startMintingProcess: 'startMintingProcess',
  payGasFeeForTransaction: 'payGasFeeForTransaction'
}

module.exports = {
  UserMutationTypes,
  UserActionTypes
}
