import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'

import './index.css'

import './index.scss'

import ElementPlus from 'element-plus'

import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import { CURRENT_VERSION } from './store/modules/app'

Amplify.configure({
  ...awsconfig,
  aws_cloud_logic_custom: [
    {
      'name': 'conferenceNftsRestApi',
      'endpoint': process.env.VUE_APP_BASE_URL,
      'region': 'ap-southeast-1',
      custom_header: async () => {
        if (!store.state.app.isLoggedIn) return
        return { 'Authorization': `${await store.dispatch('app/getJWTToken')}`, 'x-company-id': `${store.state.app.companyId}` }
      }
    },
    {
      'name': 'conferenceNftsRestApiWithoutCompany',
      'endpoint': process.env.VUE_APP_BASE_URL,
      'region': 'ap-southeast-1',
      custom_header: async () => {
        if (!store.state.app.isLoggedIn) return
        return { 'Authorization': `${await store.dispatch('app/getJWTToken')}` }
      }
    }]
})
// Amplify.Logger.LOG_LEVEL = 'DEBUG'

// register global utility filters
// Object.keys(filters).forEach((key) => {
//   Vue.filter(key, filters[key])
// })

// Manage Store(App module) Version
const app = store.state.app
if (CURRENT_VERSION != app.version) {
  localStorage.clear()
  store.commit(
    'app/SET_VERSION',
    CURRENT_VERSION,
    { root: true }
  )
}
const appInstance = createApp(App)

appInstance.config.productionTip = false
if (self.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
  if (process.env.VUE_APP_NODE_ENV === 'production' || process.env.VUE_APP_NODE_ENV === 'staging') {
    appInstance.config.devtools = false
    self.__VUE_DEVTOOLS_GLOBAL_HOOK__.enabled = false
    self.__VUE_DEVTOOLS_GLOBAL_HOOK__.off()
  }
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app: appInstance,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    trackComponents: true,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],

    tracesSampleRate: 1.0,

    tracePropagationTargets: ['localhost', 'gaiastaging.carbonbase.co', 'gaia.carbonbase.co'],

    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  })
}

appInstance.use(store).use(router).use(ElementPlus).mount('#app')

if (window.Cypress) {
  window.app = store
}
