<template>
  <div>
    <div class="px-[10%]">
      <div class="flex justify-between pt-4 pb-8">
        <h2
          data-cy="total_data_api_key"
          class="text-3xl font-bold"
        >
          ApiKey ({{ totalData == -1 ? 0 : totalData }})
        </h2>
        <el-button
          size="large"
          data-cy="create_key_button"
          @click="onCreateApiKey"
        >
          Create Key
        </el-button>
      </div>

      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="border-b">
                  <tr>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Regenerate
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in apiKeys"
                    :key="item.id"
                    class="border-b"
                  >
                    <td
                      class="text-sm text-gray-900 font-light px-6 py-4"
                      :data-cy="'apikey_name_' + index"
                    >
                      {{ item.name }}
                    </td>
                    <td class="text-sm text-gray-900 font-light px-6 py-4">
                      <el-button
                        size="large"
                        type="primary"
                        link
                        :data-cy="'show_apikey_button' + index"
                        @click="IS_DIALOG_OPEN_FOR_API_KEY(true), reGenerateApiKey(item.id)"
                      >
                        Regenerate
                      </el-button>
                    </td>
                    <td class="text-sm text-gray-900 font-light px-6 py-4 break-words">
                      <el-button
                        circle
                        size="small"
                        type="warning"
                        :icon="Delete"
                        :data-cy="'delete_apikey_button' + index"
                        @click="deleteApiKey({ id: item.id, index })"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p
                v-if="(totalData !== -1) & !allDataIsLoaded || isLoadingForGetapiKey"
                class="text-center my-3"
              >
                Loading...
              </p>
              <div
                v-if="totalData === 0 && !isLoadingForGetapiKey"
                class="text-center text-xl mt-5"
                data-cy="no_data_fount_apikey"
              >
                No data found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DialogGenerateApiKey />
    <DialogDisplayApiKey />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import { emitter } from '@/utils/eventBus'
import { moduleTypes } from '@/store/modules/type'
import { Delete, View } from '@element-plus/icons-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import DialogGenerateApiKey from '@/components/AdminCenter/ApiKey/DialogGenerateApiKey'
import DialogDisplayApiKey from '@/components/AdminCenter/ApiKey/DialogDisplayApiKey'
import { ApiActionTypes, ApiMutationTypes } from '@/store/modules/admin/apikey/type'

export default {
  name: 'ApiKeyPage',
  components: {
    DialogDisplayApiKey,
    DialogGenerateApiKey
  },
  props: {
    loadMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allDataIsLoaded: false,
      View: shallowRef(View),
      Delete: shallowRef(Delete)
    }
  },
  computed: {
    ...mapState(moduleTypes.APIKEY, ['page', 'totalData', 'apiKeys', 'isLoadingForGetapiKey'])
  },
  watch: {
    loadMore: function () {
      if (this.totalData === this.apiKeys.length) this.allDataIsLoaded = true
      if (!this.isLoadingForGetapiKey && this.totalData > this.apiKeys.length) {
        this.UPDATE_PAGE_FOR_API_KEY(this.page + 1)
        this.getApiKey()
      }
    }
  },
  mounted() {
    emitter.on('update_company', () => {
      this.getApiKeyData()
    })
    this.getApiKeyData()
  },
  unmounted() {
    emitter.off('update_company')
  },
  methods: {
    ...mapActions(moduleTypes.APIKEY, [ApiActionTypes.getApiKey, ApiActionTypes.deleteApiKey, ApiActionTypes.reGenerateApiKey]),
    ...mapMutations(moduleTypes.APIKEY, [
      ApiMutationTypes.UPDATE_FORM,
      ApiMutationTypes.CLEAR_API_KEY,
      ApiMutationTypes.IS_SHOW_ERROR_MESSAGE,
      ApiMutationTypes.UPDATE_PAGE_FOR_API_KEY,
      ApiMutationTypes.IS_DIALOG_OPEN_FOR_CREATE,
      ApiMutationTypes.IS_DIALOG_OPEN_FOR_API_KEY
    ]),
    getApiKeyData() {
      this.CLEAR_API_KEY()
      this.getApiKey().then(() => {
        if (this.totalData === this.apiKeys.length) this.allDataIsLoaded = true
      })
    },
    onCreateApiKey() {
      this.IS_SHOW_ERROR_MESSAGE('')
      this.UPDATE_FORM({ key: 'name', value: '' })
      this.IS_DIALOG_OPEN_FOR_CREATE(true)
    }
  }
}
</script>
