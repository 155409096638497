import { ApiMutationTypes, ApiActionTypes } from './type'
import ApiKeyServices from '@/service/ApiKeyService'
const apiKeyService = new ApiKeyServices()

const state = {
  form: {
    name: '',
    apiKey: ''
  },
  apiKeys: [],
  page: 1,
  totalData: -1,
  isErrorMessage: '',
  isDialogOpenForCreate: false,
  isLoadingForGetapiKey: false,
  isDialogOpenForRevealApiKey: false
}

const mutations = {
  [ApiMutationTypes.UPDATE_FORM]: async (state, { key, value }) => {
    state.form[key] = value
  },
  [ApiMutationTypes.SET_API_KEYS]: (state, apiKeys) => {
    state.apiKeys.push(...apiKeys)
  },
  [ApiMutationTypes.SUBMIT_API_KEYS]: (state, apiKey) => {
    state.apiKeys.push(apiKey)
  },
  [ApiMutationTypes.DELETE_API_KEY]: (state, index) => {
    state.apiKeys.splice(index, 1)
  },
  [ApiMutationTypes.UPDATE_PAGE_FOR_API_KEY]: (state, page) => {
    state.page = page
  },
  [ApiMutationTypes.UPDATE_TOTAL_API_KEY_DATA]: (state, totalData) => {
    state.totalData = totalData
  },
  [ApiMutationTypes.CLEAR_API_KEY]: (state) => {
    state.page = 1
    state.apiKeys = []
    state.totalData = -1
  },
  [ApiMutationTypes.IS_LOADING_FOR_GET_API_KEY]: (state, loading) => {
    state.isLoadingForGetapiKey = loading
  },
  [ApiMutationTypes.IS_DIALOG_OPEN_FOR_CREATE]: (state, visible) => {
    state.isDialogOpenForCreate = visible
  },
  [ApiMutationTypes.IS_DIALOG_OPEN_FOR_API_KEY]: (state, visible) => {
    state.isDialogOpenForRevealApiKey = visible
  },
  [ApiMutationTypes.IS_SHOW_ERROR_MESSAGE]: (state, message) => {
    state.isErrorMessage = message
  }
}

const actions = {
  [ApiActionTypes.getApiKey]: async ({ commit, state }) => {
    commit(ApiMutationTypes.IS_LOADING_FOR_GET_API_KEY, true)
    try {
      const response = await apiKeyService.get(state.page)
      commit(ApiMutationTypes.SET_API_KEYS, response.data)
      commit(ApiMutationTypes.UPDATE_TOTAL_API_KEY_DATA, response.totalData)
    } catch (e) {
      console.log('Error:-', e)
    }
    commit(ApiMutationTypes.IS_LOADING_FOR_GET_API_KEY, false)
  },
  [ApiActionTypes.createApiKey]: async ({ commit, state }) => {
    commit(ApiMutationTypes.IS_LOADING_FOR_GET_API_KEY, true)
    try {
      const res = await apiKeyService.create(state.form.name)
      commit('SUBMIT_API_KEYS', res.data)
      commit(ApiMutationTypes.IS_DIALOG_OPEN_FOR_CREATE, false)
      commit(ApiMutationTypes.UPDATE_TOTAL_API_KEY_DATA, state.totalData + 1)
      commit(ApiMutationTypes.UPDATE_FORM, { key: 'apiKey', value: res.key })
      commit(ApiMutationTypes.IS_DIALOG_OPEN_FOR_API_KEY, true)
    } catch (e) {
      console.log('Error:-', e)
      commit(ApiMutationTypes.IS_SHOW_ERROR_MESSAGE, 'Project name already exists.')
    }
    commit(ApiMutationTypes.IS_LOADING_FOR_GET_API_KEY, false)
  },
  [ApiActionTypes.deleteApiKey]: async ({ commit, state }, { id, index }) => {
    try {
      await apiKeyService.delete(id)
      commit(ApiMutationTypes.DELETE_API_KEY, index)
      commit(ApiMutationTypes.UPDATE_TOTAL_API_KEY_DATA, state.totalData - 1)
    } catch (e) {
      console.log('Error:-', e)
    }
  },
  [ApiActionTypes.reGenerateApiKey]: async ({ commit }, id) => {
    commit(ApiMutationTypes.IS_LOADING_FOR_GET_API_KEY, true)
    try {
      const res = await apiKeyService.regenerate(id)
      commit(ApiMutationTypes.UPDATE_FORM, { key: 'apiKey', value: res.key })
    } catch (e) {
      console.log('Error:-', e)
    }
    commit(ApiMutationTypes.IS_LOADING_FOR_GET_API_KEY, false)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
