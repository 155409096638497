const VerifyUserMutationTypes = {
  RESET_FORM: 'RESET_FORM',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  UPDATE_CONFIRMATION_CODE: 'UPDATE_CONFIRMATION_CODE',
  SET_IS_VERIFY_USER_LOADING: 'SET_IS_VERIFY_USER_LOADING',
  SET_RESEND_CONFIRMATION_CODE_ALERT: 'SET_RESEND_CONFIRMATION_CODE_ALERT',
  SET_IS_RESEND_CONFIRMATION_CODE_LOADING: 'SET_IS_RESEND_CONFIRMATION_CODE_LOADING'
}

const VerifyUserActionTypes = {
  resendConfirmationCode: 'resendConfirmationCode',
  verifyConfirmationCode: 'verifyConfirmationCode',
  confirmSignUp: 'confirmSignUp'
}

module.exports = {
  VerifyUserActionTypes,
  VerifyUserMutationTypes
}
