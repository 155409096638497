export function charAtFirst(str) {
  if (str) {
    return str.charAt(0).toUpperCase()
  }
  return ''
}

export function exportDownload(data, name) {
  const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', `${name}`)
  document.body.appendChild(link)
  link.click()
}
