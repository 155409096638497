import Web3 from 'web3'
import FutureAbi from '@/assets/contracts/futureNFT.json'
import { getFastGas } from '@/utils/gashelper'
import store from '../store'
import { SUPPORTED_CHAINS } from '../constants'

export const payGasFeeForTransfer = async ({ walletAddresses, smartContractAddress, window }) => {
  const web3 = new Web3(window.ethereum)

  const abi = FutureAbi

  const contract = new web3.eth.Contract(abi, smartContractAddress)
  const arrayQuantities = Array(walletAddresses.length).fill(1)

  const selectedChainInMetamask = store.state.core.selectedChainInMetamask
  let txHash = ''
  if (selectedChainInMetamask === SUPPORTED_CHAINS.POLYGON || selectedChainInMetamask === SUPPORTED_CHAINS.POLYGON_MUMBAI) {
    const { maxFeePerGas, maxPriorityFeePerGas } = await getFastGas()
    txHash = contract.methods.airdrop(walletAddresses, arrayQuantities)
      .send({
        from: window.ethereum.selectedAddress,
        maxFeePerGas,
        maxPriorityFeePerGas
      })
  } else {
    txHash = contract.methods.airdrop(walletAddresses, arrayQuantities)
      .send({
        from: window.ethereum.selectedAddress
      })
  }

  console.log('successful', txHash)

  return txHash
}
