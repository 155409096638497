<template>
  <div>
    <input
      type="file"
      data-cy="collection_simple_image"
      accept="image/png, image/jpg, image/jpeg"
      multiple
      @change="
        (event) => {
          UPDATE_FORM({
            key: 'nfts',
            value: event.target.files
          })
        }
      "
    />
    <el-row>
      <el-col
        v-for="(nft, index) in form.nfts"
        :key="index"
        :span="4"
        :offset="1"
        style="margin-top: 30px"
      >
        <el-card :body-style="{ padding: '0px' }">
          <el-image
            :src="nft.image"
            alt="Nft"
            style="width: 100%; height: 150px"
            fit="cover"
          />
          <el-input
            :model-value="nft.title"
            :data-cy="`collection_simple_image_name${index}`"
            placeholder="Enter a title"
            @input="
              (value) => {
                UPDATE_FORM_NFTS_TITLE({
                  value,
                  index
                })
              }
            "
          />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { moduleTypes } from '@/store/modules/type'
import { CollectionMutationTypes } from '@/store/modules/admin/collection/type'
export default {
  name: 'ImageUploadSimpleMode',
  computed: {
    ...mapState(moduleTypes.COLLECTION, ['form'])
  },
  methods: {
    ...mapMutations(moduleTypes.COLLECTION, [
      CollectionMutationTypes.UPDATE_FORM,
      CollectionMutationTypes.UPDATE_FORM_NFTS_TITLE
    ])
  }
}
</script>
