import { API } from 'aws-amplify'
import serviceName from './index'

const route = `${process.env.VUE_APP_NODE_ENV !== 'development' ? '/private' : ''}/company`
export default class CompanyService {
  async get(page) {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}?page=${page}&size=20`)
    } catch (e) {
      console.log(e)
    }
  }

  async create(name, email) {
    try {
      return await API.post(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}`, {
        body: {
          name,
          email
        }
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  async update(id, email) {
    try {
      return await API.put(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}`, {
        body: {
          id,
          email
        }
      })
    } catch (e) {
      console.log('Error:-', e)
    }
  }
}
