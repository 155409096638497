const SUPPORTED_CHAINS = {
  POLYGON: 137,
  POLYGON_MUMBAI: 80001,
  ETHEREUM: 1,
  ETHEREUM_SEPOLIA: 11155111
}

const SUPPORTED_CHAIN_NAMES = {
  137: 'Polygon',
  80001: 'Polygon Mumbai',
  1: 'Ethereum',
  11155111: 'Ethereum Sepolia'
}

const MINT_MODE = {
  USING_EMAIL: 'using_email',
  USING_WALLET_ADDRESS: 'using_wallet_address'
}

const COLLECTION_DEPLOYMENT_MODE = {
  REPLICATION: 'replication',
  SIMPLE: 'simple'
}

module.exports = {
  SUPPORTED_CHAINS,
  SUPPORTED_CHAIN_NAMES,
  MINT_MODE,
  COLLECTION_DEPLOYMENT_MODE
}
