<template>
  <div class="relative mobile:hidden">
    <Transition name="fade">
      <img
        :key="imgSrc"
        :style="`height: ${containerHeight}px`"
        :src="require(`@/assets/img/${imgSrc}`)"
      />
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'HomePageBackgroundImage',
  data() {
    return {
      imgSrc: 'landing_bg_1.jpg',
      containerHeight: 0,
      backgroundImg: [
        'landing_bg_1.jpg',
        'landing_bg_2.jpg',
        'landing_bg_3.jpg',
        'landing_bg_4.jpg'
      ],
      interval: null
    }
  },
  unmounted() {
    clearInterval(this.interval)
  },
  mounted() {
    this.$nextTick(() => {
      this.containerHeight = window.innerHeight
    })
    let index = 1
    const numberOfImages = 4
    this.interval = setInterval(() => {
      this.imgSrc = this.backgroundImg[index]
      index = (index + 1) % numberOfImages
    }, 4000)
  }
}

</script>

<style scoped>
.fade-enter-from {
  opacity: 0.8;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter-from {
  opacity: 0.05;
}
.fade-leave-to {
  opacity: 0.8;
}

img {
  width: 100%;
  display: block;
  position: absolute;
  -webkit-transition: all 0s ease;
  transition: all 0s ease;
  object-fit: cover;
}
</style>
