import router from '@/router'
import { Auth } from 'aws-amplify'
import { moduleTypes } from '@/store/modules/type'
import { LoginMutationTypes, LoginActionTypes } from './type'

const state = {
  loginForm: {
    email: '',
    password: ''
  },
  isFromLogin: false,
  errorMessage: '',
  isPasswordVisible: false,
  isLoginLoading: false
}

const mutations = {
  [LoginMutationTypes.UPDATE_FORM]: (state, { key, value }) => {
    state.loginForm[key] = value
  },
  [LoginMutationTypes.SET_IS_SHOW_PASSWORD]: (state, isPasswordVisible) => {
    state.isPasswordVisible = isPasswordVisible
  },
  [LoginMutationTypes.SET_IS_FROM_LOGIN]: (state, isFromLogin) => {
    state.isFromLogin = isFromLogin
  },
  [LoginMutationTypes.SET_IS_LOGIN_LOADING]: (state, isLoginLoading) => {
    state.isLoginLoading = isLoginLoading
  },
  [LoginMutationTypes.SET_ERROR_MESSAGE]: (state, errorMessage) => {
    state.errorMessage = errorMessage
  },
  [LoginMutationTypes.LOGIN_RESET_FORM]: (state) => {
    state.loginForm = {
      email: '',
      password: ''
    }
    state.isFromLogin = false
    state.isPasswordVisible = false
    state.errorMessage = ''
    state.isLoginLoading = false
  }
}

const actions = {
  [LoginActionTypes.signIn]: async ({ commit, dispatch }, { email, password }) => {
    commit(LoginMutationTypes.SET_IS_LOGIN_LOADING, true)
    try {
      await Auth.signIn(email, password)
      await dispatch(`${moduleTypes.APP}/getCurrentUser`, null, { root: true })
      router.push('/')
    } catch (error) {
      commit(LoginMutationTypes.SET_ERROR_MESSAGE, error.message)
      if (error.message === 'User is not confirmed.') {
        commit(LoginMutationTypes.UPDATE_FORM, { key: 'email', value: email })
        commit(LoginMutationTypes.UPDATE_FORM, { key: 'password', value: password })
        commit(LoginMutationTypes.SET_IS_FROM_LOGIN, true)
        router.push({ path: '/verify' })
      }
      console.log('error signing in:-', error.message)
    }
    commit(LoginMutationTypes.SET_IS_LOGIN_LOADING, false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
