import { API } from 'aws-amplify'
import serviceName from './index'

const route = `${process.env.VUE_APP_NODE_ENV !== 'development' ? '/private' : ''}/user/whitelisted`
const nftTransferRoute = `${process.env.VUE_APP_NODE_ENV !== 'development' ? '/private' : ''}/nft/transfer`

export default class WhiteListedService {
  async getWhitelisted (page) {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}?page=${page}&size=20`)
    } catch (e) {
      console.log(e)
    }
  }
  async getWhitelistedUsersFilter (id, page) {
    try {
      return await API.get(serviceName.CONFERENCE_NFTS_RESTAPI, `${route}?page=${page}&size=20&collectionId=${id}`)
    } catch (e) {
      console.log(e)
    }
  }
  async createWhitelistedUser ({ email, collectionId }) {
    try {
      return await API.post(serviceName.CONFERENCE_NFTS_RESTAPI, route, {
        body: { email, collectionId }
      })
    } catch (e) {
      throw new Error(e)
    }
  }
  async createNftTransfer ({ walletAddress, collectionId }) {
    try {
      return await API.post(serviceName.CONFERENCE_NFTS_RESTAPI, nftTransferRoute, {
        body: { walletAddress, collectionId }
      })
    } catch (e) {
      console.log(e)
    }
  }
  async updateNftTransfer ({ txHash, ids }) {
    try {
      return await API.put(serviceName.CONFERENCE_NFTS_RESTAPI, nftTransferRoute, {
        body: { txHash, ids }
      })
    } catch (e) {
      console.log(e)
    }
  }
  async deleteNftTransfer ({ ids }) {
    try {
      return await API.del(serviceName.CONFERENCE_NFTS_RESTAPI, nftTransferRoute, {
        body: { ids }
      })
    } catch (e) {
      console.log(e)
    }
  }
}
