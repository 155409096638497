const POLYGON_ENDPOINT = process.env.VUE_APP_ALCHEMY_POLYGON_ENDPOINT
const MUMBAI_ENDPOINT = process.env.VUE_APP_ALCHEMY_MUMBAI_ENDPOINT
const ETHEREUM_ENDPOINT = process.env.VUE_APP_ALCHEMY_ETHEREUM_ENDPOINT
const ETHEREUM_SEPOLIA_ENDPOINT = process.env.VUE_APP_ALCHEMY_ETHEREUM_SEPOLIA_ENDPOINT
const IPFS_GATEWAY = 'https://carbonbase.mypinata.cloud/ipfs'
import loginImg from '@/assets/img/login-bg.jpg'

export const fetchNFTs = async (owner) => {
  if (owner) {
    let data = []
    try {
      const polygonNfts = await fetch(`${POLYGON_ENDPOINT}/getNFTs?owner=${owner}`).then(data => data.json())
      const mumbaiNfts = await fetch(`${MUMBAI_ENDPOINT}/getNFTs?owner=${owner}`).then(data => data.json())
      const ethereumNfts = await fetch(`${ETHEREUM_ENDPOINT}/getNFTs?owner=${owner}`).then(data => data.json())
      const ethereumSepoliaNfts = await fetch(`${ETHEREUM_SEPOLIA_ENDPOINT}/getNFTs?owner=${owner}`).then(data => data.json())
      data = [...polygonNfts?.ownedNfts, ...mumbaiNfts?.ownedNfts, ...ethereumNfts.ownedNfts, ...ethereumSepoliaNfts?.ownedNfts]
    } catch (e) {
      console.log(e)
      return data
    }

    return getNFTsMetadata(data)
  }
}

const convertIpfsUrl = (url) => {
  for (const prefix of ['ipfs://', 'https://ipfs.io/ipfs/']) {
    if (url.startsWith(prefix)) {
      return `${String(IPFS_GATEWAY)}/${url.slice(prefix.length)}`
    }
  }

  return url
}

const getNFTImageUrl = (nft) => {
  if (nft.media) {
    if (nft.media.length > 0 && nft.media[0].gateway) {
      return convertIpfsUrl(nft.media[0].gateway)
    }
  }

  if (nft.metadata) {
    if (nft.metadata.image) {
      return convertIpfsUrl(nft.metadata.image)
    }

    if (nft.metadata.image_url) {
      return convertIpfsUrl(nft.metadata.image_url)
    }
  }

  return loginImg
}

const getTokenId = (item) => {
  return item.tokenUri.raw?.split('/').pop()
}

const convertNFT = (nft) => ({
  tokenId: getTokenId(nft),
  id: nft.id.tokenId,
  contractAddress: nft.contract.address,
  contractType:
    nft.id.tokenMetadata.tokenType === 'ERC721'
      ? 'ERC721'
      : 'ERC1155',
  error: nft.error,
  title: nft.metadata.name ?? '',
  description: nft.metadata.description ?? '',
  image: getNFTImageUrl(nft),
  metadata: nft.metadata
})

const getNFTsMetadata = async (NFTS) => {
  console.log(NFTS)
  const NFTsMetadata = NFTS.map(convertNFT)
  return NFTsMetadata
}
