<template>
  <div :class="`${$route.name == 'NftDetails' ? 'fixed w-full z-10':''}`">
    <div class="flex justify-between items-center header sm:border sm:border-solid sm:border-[#bdbdbd]">
      <img
        v-if="isShowHeaderLeftArrow"
        class="cursor-pointer"
        src="@/assets/img/left-arrow.png"
        @click="$router.push({ name: 'UserCenter' })"
      />
      <div
        class="logo flex items-center"
        data-cy="is_Admin"
      >
        <router-link to="/">
          <img
            :src="require('@/assets/img/header_logo.png')"
            alt="Carbonbase logo"
            class="h-12"
          />
        </router-link>
        <span
          v-if="isAdmin"
          class="mobile:hidden"
        >Admin user</span>
      </div>
      <div
        v-if="!isLoggedIn"
        class="flex items-middle unlogin"
      >
        <router-link to="/signup">
          <span>Sign up</span>
        </router-link>
        <router-link to="/login"><span>Log in</span></router-link>
      </div>
      <div
        v-else
        class="flex"
      >
        <!-- <router-link to="" class="text-bold text-black" style="margin-right: 126px; margin-left: 100px">FAQ</router-link> -->
        <div
          v-if="isAdmin || isSuperAdmin"
          class="flex items-center space-x-4 mobile:hidden"
        >
          <router-link
            v-if="isSuperAdmin"
            to="/admin/company"
            class="text-black"
            data-cy="company_button"
          >
            Company
          </router-link>
          <router-link
            v-if="isAdmin"
            to="/admin/manage-admin"
            class="text-black"
            data-cy="manage_admin_button"
          >
            Manage Admin
          </router-link>
          <router-link
            to="/admin/apikey"
            class="text-black"
            data-cy="apikey_button"
          >
            Api Key
          </router-link>
          <router-link
            to="/admin/whitelisted"
            class="text-black"
            data-cy="whitelisted_button"
          >
            Whitelisted
          </router-link>
          <router-link
            to="/admin/users"
            class="text-black"
            data-cy="users_button"
          >
            Users
          </router-link>
          <router-link
            to="/admin/collections"
            class="text-black"
            data-cy="collection_button"
          >
            Collections
          </router-link>
        </div>

        <div class="mobile:hidden ml-4 flex items-center">
          <el-dropdown
            v-if="currentUser.email"
            size="large"
            @visible-change="visibleChange"
          >
            <span class="el-dropdown-link">
              {{ currentUser?.email }}
              <span class="user-tag text-center">{{ charAtFirst(currentUser?.email) }}</span>
              <el-icon class="el-icon--right">
                <ArrowDown v-if="!isVisible" />
                <ArrowUp v-if="isVisible" />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu class="w-48">
                <el-dropdown-item @click="signOut">Log out</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>

        <div
          class="flex items-center space-x-3 sm:hidden"
          @click="IS_SHOW_PROFILE_DRAWER(true)"
        >
          <span class="user-tag text-center">{{ charAtFirst(currentUser?.email) }}</span>
          <div>
            <img
              v-if="!isShowProfileDrawer"
              src="@/assets/img/down-arrow.png"
            />
            <img
              v-else
              src="@/assets/img/up-arrow.png"
            />
          </div>
        </div>
      </div>
    </div>
    <ElementDrawer
      @revealPrivateKey="IS_DIALOG_OPEN_FOR_PRIVATE_KEY(true)"
      @signOut="signOut"
    />
  </div>
</template>

<script>
import { charAtFirst } from '@/utils'
import { moduleTypes } from '@/store/modules/type'
import { mapActions, mapState, mapMutations } from 'vuex'
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import ElementDrawer from '@/components/Core/ElementDrawer.vue'
import { DashboardMutationTypes } from '@/store/modules/common/dashboard/type'

export default {
  name: 'HeaderNav',
  components: {
    ArrowUp,
    ArrowDown,
    ElementDrawer
  },
  data() {
    return {
      isVisible: false,
      charAtFirst: charAtFirst
    }
  },
  computed: {
    ...mapState(moduleTypes.APP, ['isLoggedIn', 'currentUser', 'isAdmin', 'isSuperAdmin']),
    ...mapState(moduleTypes.DASHBOARD, ['isShowHeaderLeftArrow', 'isShowProfileDrawer'])
  },
  mounted() {
    this.getCurrentUser()
  },
  methods: {
    ...mapMutations(moduleTypes.DASHBOARD, [
      DashboardMutationTypes.IS_SHOW_PROFILE_DRAWER,
      DashboardMutationTypes.IS_DIALOG_OPEN_FOR_PRIVATE_KEY
    ]),
    ...mapActions(moduleTypes.APP, ['logout', 'getCurrentUser']),
    async signOut() {
      await this.logout()
      this.IS_SHOW_PROFILE_DRAWER(false)
    },
    signUp() {
      this.$emit('signUp')
    },
    login() {
      this.$emit('login')
    },
    visibleChange(val) {
      this.isVisible = val
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  background-color: #fff;
  line-height: 65px;
  height: 65px;
  padding: 0 25px;
  overflow: hidden;

  a:hover {
    color: #3aa845;
  }

  .logo {
    span {
      padding-left: 35px;
      padding-top: 3px;
    }
  }

  .user-tag {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    font-style: normal;
    margin-left: 10px;

    &:hover {
      box-shadow: 0 0 2px #eee;
    }
  }

  .unlogin {
    span {
      font-weight: 500;
      cursor: pointer;
      display: inline-block;

      &:first-child {
        margin-right: 40px;
      }

      &:hover {
        color: #3aa845;
      }
    }
  }
}
</style>
