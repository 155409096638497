<template>
  <div>
    <div
      v-if="isSelectedNftIndex != null && nfts.length !== 0"
      class="pb-6 px-6 p-20"
    >
      <p class="font-bold text-base">NFT details</p>
      <div class="mt-6 w-[-webkit-fill-available]">
        <img
          class="rounded-2xl w-full"
          :src="nfts[isSelectedNftIndex].image"
        />
      </div>

      <div
        :class="`
                flex
                px-5
                h-16
                mt-7
                w-full
                border
                bg-white
                text-black
                items-center
                border-solid
                justify-between
                border-borderColor
                ${isBenefitVisible ? 'rounded-t-2xl	' : 'rounded-2xl'}
                    `
        "
        @click="isBenefitVisible = !isBenefitVisible"
      >
        <span class="font-bold text-base">
          Benefits
        </span>
        <div>
          <img
            v-if="!isBenefitVisible"
            src="@/assets/img/down-arrow.png"
          />
          <img
            v-else
            src="@/assets/img/up-arrow.png"
          />
        </div>
      </div>

      <Transition name="slide-fade">
        <div
          v-if="isBenefitVisible"
          class="
              p-6
              border-x
              border-b
              text-base
              space-y-2
              font-medium
              border-solid
              rounded-b-2xl
              text-[#4A4A4A]
              border-borderColor"
        >
          <p
            v-for="(benefit,index) in nfts[isSelectedNftIndex].collection.benefits"
            :key="index"
          >
            <span>{{ index+1 }}.</span> {{ benefit }}
          </p>
        </div>
      </Transition>

      <div>
        <el-button
          style="background: #75c43c"
          @click="viewOpneSea(nfts[isSelectedNftIndex].collection.blockchain, nfts[isSelectedNftIndex].collection.smartContractAddress, nfts[isSelectedNftIndex].tokenId)"
        >
          <img
            class="mb-1"
            :src="require('@/assets/img/gallery-icon/opensea.svg')"
          />
          <span class="pl-3.5 text-white">View it on OpenSea</span>
        </el-button>
      </div>

      <div class="mt-7">
        <div class="heading border-x border-t rounded-t-2xl border-borderColor">
          <img :src="require('@/assets/img/gallery-icon/ic_outline-collections.svg')" />
          <span class="title">Collection Name</span>
        </div>
        <div class="description">
          <span>{{ nfts[isSelectedNftIndex].collection.name }}</span>
        </div>
        <div class="heading">
          <img :src="require('@/assets/img/gallery-icon/ic_outline-description.svg')" />
          <span class="title"> Description</span>
        </div>
        <div class="description">
          <span>{{ nfts[isSelectedNftIndex].description }}</span>
        </div>
        <div class="heading">
          <img :src="require('@/assets/img/nft-title.svg')" />
          <span class="title"> Title</span>
        </div>
        <div class="description">
          <span>{{ nfts[isSelectedNftIndex].title }}</span>
        </div>
        <div class="heading">
          <img :src="require('@/assets/img/gallery-icon/ic_outline-token.svg')" />
          <span class="title"> Token ID</span>
        </div>
        <div class="description">
          <span>{{ nfts[isSelectedNftIndex].tokenId }}</span>
        </div>
        <div class="heading">
          <img :src="require('@/assets/img/gallery-icon/simple-icons_hive-blockchain.svg')" />
          <span class="title"> Blockchain</span>
        </div>
        <div class="description">
          <span>{{ nfts[isSelectedNftIndex].collection.blockchain }}</span>
        </div>
        <div class="heading">
          <img :src="require('@/assets/img/gallery-icon/Union.svg')" />
          <span class="title"> Source</span>
        </div>
        <div class="description rounded-b-2xl">
          <span>{{ nfts[isSelectedNftIndex].source }}</span>
        </div>
      </div>
    </div>
    <RevealPrivateKey @click="closeDialog" />
  </div>
</template>

<script>
import router from '@/router'
import { SUPPORTED_CHAINS } from '@/constants'
import { moduleTypes } from '@/store/modules/type'
import { mapMutations, mapState, mapActions } from 'vuex'
import RevealPrivateKey from '@/components/RevealPrivateKey'
import { DashboardMutationTypes, DashboardActionTypes } from '@/store/modules/common/dashboard/type'

export default {
  name: 'NftDetails',
  components: { RevealPrivateKey },
  data() {
    return {
      SUPPORTED_CHAINS,
      isBenefitVisible: false,
      isSelectedNftIndex: null,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapState(moduleTypes.DASHBOARD, ['nfts'])
  },
  watch: {
    windowWidth: function () {
      if (this.windowWidth > 578) {
        router.push({ path: '/' })
      }
    }
  },
  mounted() {
    this.IS_SHOW_HEADER_LEFT_ARROW(true)
    this.getOwnerNFTs()
    this.isSelectedNftIndex = this.$route.params.id
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapMutations(moduleTypes.DASHBOARD, [
      DashboardMutationTypes.IS_SHOW_HEADER_LEFT_ARROW,
      DashboardMutationTypes.IS_DIALOG_OPEN_FOR_PRIVATE_KEY
    ]),
    ...mapActions(moduleTypes.DASHBOARD, [DashboardActionTypes.getOwnerNFTs]),
    viewOpneSea(blockchain, smartContractAddr, tokenId) {
      if (blockchain === SUPPORTED_CHAINS.POLYGON) {
        window.open('https://opensea.io/assets/matic' + '/' + smartContractAddr + '/' + tokenId, '_blank')
      } else if (blockchain === SUPPORTED_CHAINS.POLYGON_MUMBAI) {
        window.open('https://testnets.opensea.io/assets/mumbai/' + smartContractAddr + '/' + tokenId, '_blank')
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM_SEPOLIA) {
        window.open('https://testnets.opensea.io/assets/sepolia/' + smartContractAddr + '/' + tokenId, '_blank')
      } else if (blockchain === SUPPORTED_CHAINS.ETHEREUM) {
        window.open('https://opensea.io/assets/ethereum/' + smartContractAddr + '/' + tokenId, '_blank')
      }
    },
    closeDialog() {
      this.IS_DIALOG_OPEN_FOR_PRIVATE_KEY(false)
    },
    onResize() {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="postcss" scoped>
.heading {
  @apply p-6 font-bold flex items-center border-x border-b border-solid border-borderColor
}

.description {
  @apply p-6 text-sm leading-6 border-x border-b border-solid border-borderColor bg-bgSecondary
}

.title {
  @apply pl-4 text-base text-primaryText
}

.el-button {
  width: 100%;
  background: white;
  color: black;
  border: 1px solid #E0E0E0;
  margin-top: 25px;
  height: 65px;
  border-radius: 16px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
