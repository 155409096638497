<template>
  <div class="mobile:hidden">
    <div class="title space-y-3">
      <div class="flex justify-between items-center">
        <img
          class="h-12"
          src="@/assets/img/gaia_logo.png"
        />
        <div class="text-white space-x-12 text-base">
          <router-link to="/signup"><span>Sign up</span></router-link>
          <router-link to="/login"><span>Log in</span></router-link>
        </div>
      </div>
      <p class="pt-2 text-3xl text-white font-bold tracking-tight">GAIA</p>
      <p class="text-2xl font-nova font-semibold text-white tracking-tight">Green Asset Infrastructure Application</p>
      <p class="text-base	font-nova font-medium text-primaryTextColor tracking-tight">
        The easiest way to send and receive carbon neutral NFTs for all occasions.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePageBackground'
}
</script>
