import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'

const routes = [
  // userCenter
  {
    path: '/',
    name: 'UserCenter',
    component: () => import('@/views/dashboard'),
    meta: { headerShow: true, footerShow: true, requiresAuth: true, scrollPos: {
      top: 0,
      left: 0
    }}
  },
  {
    path: '/nft/details/:id',
    name: 'NftDetails',
    component: () => import('@/views/NftDetails'),
    meta: { headerShow: true, footerShow: false, requiresAuth: true }
  },
  {
    path: '/admin',
    name: 'AdminCenter',

    meta: { headerShow: true, footerShow: false, requiresAuth: true },
    children: [
      {
        path: 'users',
        name: 'AdminUsers',
        meta: { headerShow: true },
        component: () => import('@/views/adminCenter/users')
      },
      {
        path: 'apikey',
        name: 'ApiKey',
        meta: { headerShow: true },
        component: () => import('@/views/adminCenter/ApiKey')
      },
      {
        path: 'manage-admin',
        name: 'ManageAdmin',
        meta: { headerShow: true },
        component: () => import('@/views/adminCenter/Company/ManageAdmin')
      },
      {
        path: 'company',
        name: 'Company',
        meta: { headerShow: true },
        component: () => import('@/views/adminCenter/Company')
      },
      {
        path: 'company/:companyId/admin',
        name: 'CompanyAdmin',
        meta: { headerShow: true },
        component: () => import('@/views/adminCenter/Company/ManageAdmin')
      },
      {
        path: 'user/nft/list/:id',
        name: 'AdminUserNftList',
        meta: { headerShow: true },
        component: () => import('@/views/adminCenter/userNftList')
      },
      {
        path: 'whitelisted',
        name: 'AdminWhitelisted',
        meta: { headerShow: true },
        component: () => import('@/views/adminCenter/whitelisted')
      },
      {
        path: 'collections',
        name: 'AdminCollections',
        meta: { headerShow: true },
        component: () => import('@/views/adminCenter/collections')
      },
      {
        path: 'collections/new',
        name: 'AdminNewCollection',
        meta: { headerShow: true },
        component: () => import('@/views/adminCenter/AddCollection')
      }
    ]
  },
  {
    path: '/login',
    name: 'Auth',
    component: () => import('@/views/auth/login'),
    meta: { headerShow: false, footerShow: false }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/views/auth/sign-up'),
    meta: { headerShow: false, footerShow: false }
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import('@/views/auth/VerifyUser'),
    meta: { headerShow: false, footerShow: false }
  },
  {
    path: '/recover',
    name: 'ForgetPassword',
    component: () => import('@/views/auth/forget-password'),
    meta: { headerShow: false, footerShow: false }
  },
  {
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect'),
    meta: { headerShow: false, footerShow: false }
  },
  {
    path: '/*/',
    name: 'PageNotFound',
    component: () => import('@/views/dashboard'),
    meta: { headerShow: true, footerShow: true, requiresAuth: true }
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  return savedPosition ||
      to.meta?.scrollPos ||
      { top: 0, left: 0 }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  from.meta?.scrollPos && (from.meta.scrollPos.top = window.scrollY)
  if (store.state.app.isLoggedIn && (to.path == '/login' || to.path == '/signup')) {
    return next({
      path: '/'
    })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.app.isLoggedIn) return next()
    NProgress.start()
    return next({
      path: '/signup',
      query: {
        redirect: to.fullPath
      }
    })
  }
  return next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
