const CompanyMutationTypes = {
  UPDATE_PAGE: 'UPDATE_PAGE',
  UPDATE_TOTAL_DATA: 'UPDATE_TOTAL_DATA',
  IS_DIALOG_OPEN_FOR_CREATE: 'IS_DIALOG_OPEN_FOR_CREATE',

  SET_COMPANY: 'SET_COMPANY',
  EDIT_COMPANY: 'EDIT_COMPANY',
  CLEAR_COMPANY: 'CLEAR_COMPANY',
  SUBMIT_COMPANY: 'SUBMIT_COMPANY',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  IS_SHOW_ERROR_MESSAGE: 'IS_SHOW_ERROR_MESSAGE',
  UPDATE_FORM_FOR_COMPANY: 'UPDATE_FORM_FOR_COMPANY',
  IS_LOADING_FOR_GET_COMPANY: 'IS_LOADING_FOR_GET_COMPANY',

  IS_LOADING_FOR_GET_ADMIN: 'IS_LOADING_FOR_GET_ADMIN',
  IS_LOADING_FOR_CREATE_ADMIN: 'IS_LOADING_FOR_CREATE_ADMIN',
  UPDATE_FORM_FOR_ADMIN: 'UPDATE_FORM_FOR_ADMIN',
  SET_ADMIN: 'SET_ADMIN',
  CLEAR_ADMIN: 'CLEAR_ADMIN'
}

const CompanyActionTypes = {
  getCompany: 'getCompany',
  createCompany: 'createCompany',

  createAdmin: 'createAdmin',
  getAdminsByCompany: 'getAdminsByCompany',
  removeAdminFromCompany: 'removeAdminFromCompany'
}

module.exports = {
  CompanyActionTypes,
  CompanyMutationTypes
}
